import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
import type { VitalSignsWithTypeDTO } from '@/generated'

const keysWithDecimalValues: VitalSignsKeys[] = [VitalSignsKeys.LAC, VitalSignsKeys.K]

const keysWithTwoDecimalPlaces: VitalSignsKeys[] = [VitalSignsKeys.PH]

export const formatLaboratoryData = (laboratory: VitalSignsWithTypeDTO[]) => {
  return laboratory.map((item) => {
    const key = item.vitalSignType.typeKey
    let decimalPlaces = 0

    if (keysWithTwoDecimalPlaces.includes(key as VitalSignsKeys)) {
      decimalPlaces = 2
    } else if (keysWithDecimalValues.includes(key as VitalSignsKeys)) {
      decimalPlaces = 1
    }

    return {
      ...item,
      items: item.items.map((item) => ({
        ...item,
        numericValue: Number(item.numericValue.toFixed(decimalPlaces))
      }))
    }
  })
}
