<template>
  <VCard :to="{ name: routeNames.PATIENT_DETAILS, params: { caseId } }" class="p-4 flex flex-col">
    <VCardItem class="p-0">
      <VCardTitle class="flex gap-2 mb-2 mt-0">
        <ConsultationTypeChip
          :consultation-type="consultation.urgency"
          data-testid="consultation-type-chip"
        />
        <ConsultationCountdown
          v-if="isUrgencyWithCountdown(consultation.urgency) && !!consultation.nextEscalationAt"
          :urgency="consultation.urgency"
          :next-escalation-at="consultation.nextEscalationAt"
          data-testid="consultation-countdown"
        />
      </VCardTitle>
      <div class="flex items-center gap-4 max-lg:flex-col max-lg:items-start">
        <VCardTitle class="text-h4 text-primary-text mb-2">
          <PatientTitle :patient="consultation.case.patient" data-testid="patient-title" />
        </VCardTitle>
        <VCardSubtitle class="pb-0">
          <PatientDetails :caze="consultation.case" data-testid="patient-details" />
        </VCardSubtitle>
      </div>

      <VCardText class="p-0 py-2">
        <div class="flex flex-col gap-2">
          <section class="flex flex-col gap-2">
            <div class="flex flex-col sm:flex-row gap-2">
              <div v-if="consultation.medicalField" class="flex gap-2 items-baseline">
                <div class="text-subtitle-2">
                  {{ t('To:') }}
                </div>
                <div
                  v-if="consultation.assignedTo && (hasGodModeShift || hasChiefPhysicianShift)"
                  data-testid="assigned-to"
                  class="text-body-2"
                >
                  {{ consultation.assignedTo.title }}
                  {{ consultation.assignedTo.firstName }}
                  {{ consultation.assignedTo.lastName }}{{ ',' }}
                </div>
                <div data-testid="medical-field" class="text-body-2">
                  {{ translateMedicalFieldsDTO(consultation.medicalField, t) }}
                </div>
              </div>

              <div
                v-if="hasGodModeShift || hasChiefPhysicianShift"
                class="flex gap-2 items-baseline"
              >
                <div class="text-subtitle-2">
                  {{ t('From:') }}
                </div>
                <div data-testid="requested-by" class="text-body-2">
                  {{ consultation.requestedBy.title }}
                  {{ consultation.requestedBy.firstName }}
                  {{ consultation.requestedBy.lastName }}
                </div>
              </div>
            </div>
            <DividerLine />
          </section>

          <section v-if="medicalHistory || question" class="flex flex-col gap-2">
            <div v-if="medicalHistory" class="flex flex-col gap-2 sm:flex-row sm:items-start">
              <div class="text-subtitle-2">
                {{ t('Medical history:') }}
              </div>
              <div data-testid="medical-history" class="text-body-2 flex-1">
                <FormattedMedicalText :text="medicalHistory" />
              </div>
            </div>
            <div v-if="question" class="flex flex-col gap-2 sm:flex-row sm:items-start">
              <div class="text-subtitle-2">{{ t('Question:') }}</div>
              <div data-testid="question" class="text-body-2 flex-1">
                <FormattedMedicalText :text="question" />
              </div>
            </div>
          </section>

          <section v-if="diagnosis || therapy" class="flex flex-col gap-2">
            <div v-if="diagnosis" class="flex flex-col gap-2 sm:flex-row sm:items-start">
              <div class="text-subtitle-2">{{ t('Diagnosis:') }}</div>
              <div data-testid="diagnosis" class="text-body-2 flex-1">
                <FormattedMedicalText :text="diagnosis" />
              </div>
            </div>

            <div v-if="therapy" class="flex flex-col gap-2 sm:flex-row sm:items-start">
              <div class="text-subtitle-2">{{ t('Therapy:') }}</div>
              <div data-testid="therapy" class="text-body-2 flex-1">
                <FormattedMedicalText :text="therapy" />
              </div>
            </div>
            <DividerLine />
          </section>
        </div>
      </VCardText>

      <div v-if="showActionButtons" class="mt-2 flex flex-col flex-shrink-1 flex-grow-0">
        <ConsultationCardActions data-testid="action-buttons" :consultation="consultation" />
      </div>
    </VCardItem>
  </VCard>
</template>

<script setup lang="ts">
import { type ConsultationRequestDTO } from '@/generated'
import ConsultationCountdown from '@/components/consultationrequest/ConsultationCountdown.vue'
import { useI18n } from 'vue-i18n'
import PatientDetails from '@/components/patient/components/atoms/PatientDetails.vue'
import PatientTitle from '@/components/patient/components/atoms/PatientTitle.vue'
import ConsultationTypeChip from '@/atoms/ConsultationTypeChip.vue'
import { isUrgencyWithCountdown } from '@/util/UrgencyWithCountdown'
import { routeNames } from '@/router/routes'
import { useConsultationRequest } from '@/composables/useConsultationRequest'
import ConsultationCardActions from '@/components/consultationrequest/ConsultationCardActions.vue'
import DividerLine from '@/atoms/DividerLine.vue'
import { translateMedicalFieldsDTO } from '@/util/translations'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/stores/user'
import FormattedMedicalText from '@/atoms/FormattedMedicalText.vue'

const { t } = useI18n()

const { consultation } = withDefaults(
  defineProps<{
    consultation: ConsultationRequestDTO
    showActionButtons?: boolean
  }>(),
  {
    showActionButtons: true
  }
)

const { hasGodModeShift, hasChiefPhysicianShift } = storeToRefs(useUserStore())

const { caseId, medicalHistory, diagnosis, therapy, question } =
  useConsultationRequest(consultation)
</script>
