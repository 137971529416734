<template>
  <VProgressCircular
    v-if="props.type === 'circular'"
    :size="props.size"
    color="primary"
    indeterminate
  ></VProgressCircular>
  <VProgressLinear
    v-else-if="props.type === 'linear'"
    :size="props.size"
    color="primary"
    indeterminate
  ></VProgressLinear>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'

const props = defineProps({
  type: {
    type: String as PropType<'circular' | 'linear'>,
    required: true
  },
  size: {
    type: String as PropType<'small' | 'medium' | 'large'>,
    required: true
  }
})
</script>
