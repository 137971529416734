import type { GetVitalSignsResponse } from '@/generated'
import { VitalSignsApi } from '@/generated'
import { globalApiConfig } from '@/services/telehealthApi/APIConfig'
import { formatVitalSigns } from '@/util/formatVitalSigns'
import { formatLaboratoryData } from '@/util/formatLaboratoryData'

export class VitalSignsService {
  static readonly vitalSignsApi = new VitalSignsApi(globalApiConfig)

  static async getVitalSigns(caseId: string): Promise<GetVitalSignsResponse> {
    const response = await VitalSignsService.vitalSignsApi.getVitalSigns(caseId)

    return {
      vitalSigns: formatVitalSigns(response.data.vitalSigns),
      laboratory: formatLaboratoryData(response.data.laboratory)
    }
  }
}
