import { defineStore } from 'pinia'
import { computed, ref, type Ref } from 'vue'
import { useUserStore } from '@/stores/user'
import { FiltersService } from '@/services/telehealthApi/FiltersService'
import { useCaseStore, type CaseWithVitalSigns } from '@/stores/case'
import type { VitalSignFilters } from '@/stores/models/VitalSignsFilter'
import {
  type GetVitalSignsResponse,
  type HospitalDTO,
  type VitalSignsWithTypeDTO
} from '@/generated'
import { remove } from 'lodash'
import type { VitalSignsSortBy } from './models/VitalSignsSortBy'
import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
import { VitalSignsService } from '@/services/telehealthApi/VitalSignsService'
import { compareCases } from '@/util/compareCases'
import { NoticeType } from './models/Notice'
import { useNoticeStore } from './notice'
import type { VitalSignsDisplayMode } from './models/VitalSignsDisplayMode'
import { formatVitalSigns } from '@/util/formatVitalSigns'
import { formatLaboratoryData } from '@/util/formatLaboratoryData'

export const useVitalSignsFilterStore = defineStore('vitalSignsFilter', () => {
  const caseStore = useCaseStore()
  const noticeStore = useNoticeStore()
  const userStore = useUserStore()

  const vitalSignsLoading = ref(false)
  const casesWithVitalSigns: Ref<Record<string, CaseWithVitalSigns>> = ref({})

  const defaultVitalSignsFilter: VitalSignFilters = {
    temperatureRange: [],
    lactateRange: [],
    urineOutputRange: [],
    pHRange: [],
    potassiumRange: [],
    hemoglobinRange: [],
    heartRateRange: [],
    oxygenSaturationRange: [],
    vasopressors: false,
    dialysis: false,
    ventilation: false,
    ecmo: false,
    impella: false,
    hospitals: [],
    departments: [],
    wards: []
  }

  const defaultVitalSignsDisplayMode: VitalSignsDisplayMode = {
    display: 'compact'
  }

  const defaultVitalSignsSortBy: VitalSignsSortBy = {
    sortBy: VitalSignsKeys.HEART_RATE,
    direction: 'DESC'
  }

  const vitalSignsFilters = ref<VitalSignFilters>({ ...defaultVitalSignsFilter })
  const vitalSignsFilterInput = ref<VitalSignFilters>({ ...defaultVitalSignsFilter })

  const vitalSignsSortBy = ref<VitalSignsSortBy>({ ...defaultVitalSignsSortBy })
  const vitalSignsSortByInput = ref<VitalSignsSortBy>({ ...defaultVitalSignsSortBy })

  const vitalSignsDisplayMode: Ref<VitalSignsDisplayMode> = ref({ ...defaultVitalSignsDisplayMode })

  async function commitVitalSignsFilterInput() {
    const updatedVitalSignsFilter = { ...vitalSignsFilterInput.value }

    vitalSignsFilters.value = updatedVitalSignsFilter

    await FiltersService.postVitalSignsFilters(userStore.user?.userName || '', {
      vitalSigns: [
        {
          ...updatedVitalSignsFilter,
          ...vitalSignsDisplayMode.value,
          sortBy: { ...vitalSignsSortBy.value }
        }
      ]
    })
  }

  async function commitVitalSignsDisplayModeInput() {
    const updatedVitalSignsDisplayMode = { ...vitalSignsDisplayMode.value }

    await FiltersService.postVitalSignsFilters(userStore.user?.userName || '', {
      vitalSigns: [
        {
          ...vitalSignsFilters.value,
          ...updatedVitalSignsDisplayMode,
          sortBy: { ...vitalSignsSortBy.value }
        }
      ]
    })
  }

  async function commitVitalSignsSortByInput() {
    const updatedVitalSignsSortBy = { ...vitalSignsSortByInput.value }

    vitalSignsSortBy.value = updatedVitalSignsSortBy

    await FiltersService.postVitalSignsFilters(userStore.user?.userName || '', {
      vitalSigns: [
        {
          ...vitalSignsFilters.value,
          ...vitalSignsDisplayMode.value,
          sortBy: { ...updatedVitalSignsSortBy }
        }
      ]
    })
  }

  async function initVitalSignsDisplayOptions() {
    const username = userStore.user?.userName
    if (!username) return

    const response = await FiltersService.getVitalSignsFilters(username)
    const vitalSigns = response?.vitalSigns || []

    if (!vitalSigns.length) return

    const filterData = vitalSigns[0] as Record<string, any>

    const initialFilter = { ...defaultVitalSignsFilter, ...filterData }
    vitalSignsFilters.value = initialFilter
    vitalSignsFilterInput.value = initialFilter

    const initialSortBy = filterData.sortBy
      ? { ...filterData.sortBy }
      : { ...defaultVitalSignsSortBy }
    vitalSignsSortBy.value = initialSortBy
    vitalSignsSortByInput.value = initialSortBy

    const initialDisplayMode = filterData.display
      ? { display: filterData.display }
      : { ...defaultVitalSignsDisplayMode }
    vitalSignsDisplayMode.value = initialDisplayMode
  }

  function filterVitalSignsCases(caze: CaseWithVitalSigns, useCommitedFilters = true): boolean {
    const filterFunctions = [
      filterByHospital,
      filterByWard,
      filterByDepartment,
      filterByVitalSigns,
      filterByOrganSupport
    ]
    return filterFunctions.every((filter) => filter(caze, useCommitedFilters))
  }

  function filterByHospital(caze: CaseWithVitalSigns, useCommitedFilters = true): boolean {
    const filter = useCommitedFilters ? vitalSignsFilters : vitalSignsFilterInput
    return (
      !filter.value.hospitals?.length ||
      filter.value.hospitals.some((h) => h.id === caze.hospital.id)
    )
  }

  function filterByWard(caze: CaseWithVitalSigns, useCommitedFilters = true): boolean {
    const filter = useCommitedFilters ? vitalSignsFilters : vitalSignsFilterInput
    return !filter.value.wards?.length || filter.value.wards.some((w) => w === caze.ward.name)
  }

  function filterByDepartment(caze: CaseWithVitalSigns, useCommitedFilters = true): boolean {
    const filter = useCommitedFilters ? vitalSignsFilters : vitalSignsFilterInput
    return (
      !filter.value.departments?.length ||
      filter.value.departments.some((d) => d === caze.department.name)
    )
  }

  function filterByVitalSigns(caze: CaseWithVitalSigns, useCommitedFilters = true): boolean {
    const filter = useCommitedFilters ? vitalSignsFilters : vitalSignsFilterInput
    if (!caze.vitalSigns) return true

    const vitalSignFilters = [
      { range: filter.value.temperatureRange, key: 'TEMP' },
      { range: filter.value.lactateRange, key: 'LACTATE' },
      { range: filter.value.urineOutputRange, key: 'URINE_OUTPUT' },
      { range: filter.value.pHRange, key: 'PH' },
      { range: filter.value.potassiumRange, key: 'POTASSIUM' },
      { range: filter.value.hemoglobinRange, key: 'HEMOGLOBIN' },
      { range: filter.value.heartRateRange, key: 'HEART_RATE' },
      { range: filter.value.oxygenSaturationRange, key: 'OXYGEN_SATURATION' }
    ]

    return vitalSignFilters.every(({ range, key }) => {
      const value =
        caze.vitalSigns.find((v) => v.vitalSignType.typeKey === key)?.items[0]?.numericValue || 0
      if (!range || range.length !== 2 || typeof value !== 'number') return true
      return value >= Number(range[0]) && value <= Number(range[1])
    })
  }

  function filterByOrganSupport(caze: CaseWithVitalSigns, useCommitedFilters = true): boolean {
    const filter = useCommitedFilters ? vitalSignsFilters : vitalSignsFilterInput
    if (!caze.vitalSigns) return true

    const organSupportFilters = [
      { enabled: filter.value.dialysis, key: 'DIALYSIS' },
      { enabled: filter.value.ventilation, key: 'VENTILATION' },
      { enabled: filter.value.ecmo, key: 'ECMO' },
      { enabled: filter.value.impella, key: 'IMP' }
    ]

    return organSupportFilters.every(({ enabled, key }) => {
      const value = caze.vitalSigns.find((v) => v.vitalSignType.typeKey === key)?.items[0]
        ?.numericValue
      if (!enabled || typeof value !== 'boolean') return true
      return value === true
    })
  }

  function sortVitalSignsMeasurementDates(vitalSigns: VitalSignsWithTypeDTO[]) {
    return vitalSigns.map((v) => ({
      ...v,
      items: v.items.sort(
        (a, b) => new Date(b.measurementDate).getTime() - new Date(a.measurementDate).getTime()
      )
    }))
  }

  function sortVitalSignsNumericValues(a: CaseWithVitalSigns, b: CaseWithVitalSigns): number {
    const getVitalSignValue = (caseData: CaseWithVitalSigns): number | null => {
      const vitalSign = caseData.vitalSigns.find(
        (v) => v.vitalSignType.typeKey === vitalSignsSortBy.value.sortBy
      )
      return vitalSign?.items[0]?.numericValue ?? null
    }

    const aValue = getVitalSignValue(a)
    const bValue = getVitalSignValue(b)

    if (aValue === null && bValue === null) return 0
    if (aValue === null) return 1
    if (bValue === null) return -1

    const sortDirection = vitalSignsSortBy.value.direction === 'ASC' ? 1 : -1
    return (aValue - bValue) * sortDirection
  }

  const sortedCasesWithVitalSigns = computed<CaseWithVitalSigns[]>(() => {
    return Object.values(casesWithVitalSigns.value)
      .map((caseWithVitalSigns) => ({
        ...caseWithVitalSigns,
        vitalSigns: sortVitalSignsMeasurementDates(caseWithVitalSigns.vitalSigns)
      }))
      .sort((a, b) => {
        if (vitalSignsSortBy.value.sortBy === VitalSignsKeys.PATIENT_NAME) {
          return compareCases(a, b, vitalSignsSortBy.value.direction)
        }
        return sortVitalSignsNumericValues(a, b)
      })
  })

  async function getVitalSigns() {
    try {
      await caseStore.refreshCases()
      vitalSignsLoading.value = true

      const responses = await Promise.all(
        caseStore.caseList.map((caze) => getVitalSignsByCaseId(caze.id))
      )

      casesWithVitalSigns.value = Object.fromEntries(
        responses.map((response, index) => {
          const caze = caseStore.caseList[index]
          return [
            caze.id,
            {
              ...caze,
              vitalSigns: formatVitalSigns(response.vitalSigns),
              laboratory: formatLaboratoryData(response.laboratory)
            }
          ]
        })
      )
    } catch (error) {
      console.error(error)
      casesWithVitalSigns.value = {}
      noticeStore.add({ type: NoticeType.ERROR, message: 'Failed to load vital signs' })
    } finally {
      vitalSignsLoading.value = false
    }
  }

  async function getVitalSignsByCaseId(caseId: string): Promise<GetVitalSignsResponse> {
    return await VitalSignsService.getVitalSigns(caseId)
  }

  function removeHospitalFilter(hospitalId: string) {
    remove(vitalSignsFilters.value.hospitals, (h: HospitalDTO) => h.id === hospitalId)
    remove(vitalSignsFilterInput.value.hospitals, (h: HospitalDTO) => h.id === hospitalId)

    cleanupVitalSignsFilter(vitalSignsFilters.value)
    commitVitalSignsFilterInput()
  }

  function removeDepartmentFilter(departmentName: string) {
    remove(vitalSignsFilters.value.departments, (d) => d === departmentName)
    remove(vitalSignsFilterInput.value.departments, (d) => d === departmentName)
    commitVitalSignsFilterInput()
  }

  function removeWardFilter(wardName: string) {
    remove(vitalSignsFilters.value.wards, (w) => w === wardName)
    remove(vitalSignsFilterInput.value.wards, (w) => w === wardName)
    commitVitalSignsFilterInput()
  }

  function removeTemperatureRangeFilter() {
    vitalSignsFilters.value.temperatureRange = []
    vitalSignsFilterInput.value.temperatureRange = []
    commitVitalSignsFilterInput()
  }

  function removeLactateRangeFilter() {
    vitalSignsFilters.value.lactateRange = []
    vitalSignsFilterInput.value.lactateRange = []
    commitVitalSignsFilterInput()
  }

  function removeUrineOutputRangeFilter() {
    vitalSignsFilters.value.urineOutputRange = []
    vitalSignsFilterInput.value.urineOutputRange = []
    commitVitalSignsFilterInput()
  }

  function removepHRangeFilter() {
    vitalSignsFilters.value.pHRange = []
    vitalSignsFilterInput.value.pHRange = []
    commitVitalSignsFilterInput()
  }

  function removePotassiumRangeFilter() {
    vitalSignsFilters.value.potassiumRange = []
    vitalSignsFilterInput.value.potassiumRange = []
    commitVitalSignsFilterInput()
  }

  function removeHemoglobinRangeFilter() {
    vitalSignsFilters.value.hemoglobinRange = []
    vitalSignsFilterInput.value.hemoglobinRange = []
    commitVitalSignsFilterInput()
  }

  function removeHeartRateRangeFilter() {
    vitalSignsFilters.value.heartRateRange = []
    vitalSignsFilterInput.value.heartRateRange = []
    commitVitalSignsFilterInput()
  }

  function removeOxygenSaturationRangeFilter() {
    vitalSignsFilters.value.oxygenSaturationRange = []
    vitalSignsFilterInput.value.oxygenSaturationRange = []
    commitVitalSignsFilterInput()
  }

  function removeVasopressorsFilter() {
    vitalSignsFilters.value.vasopressors = false
    vitalSignsFilterInput.value.vasopressors = false
    commitVitalSignsFilterInput()
  }

  function removeDialysisFilter() {
    vitalSignsFilters.value.dialysis = false
    vitalSignsFilterInput.value.dialysis = false
    commitVitalSignsFilterInput()
  }

  function removeVentilationFilter() {
    vitalSignsFilters.value.ventilation = false
    vitalSignsFilterInput.value.ventilation = false
    commitVitalSignsFilterInput()
  }

  function removeEcmoFilter() {
    vitalSignsFilters.value.ecmo = false
    vitalSignsFilterInput.value.ecmo = false
    commitVitalSignsFilterInput()
  }

  function removeImpellaFilter() {
    vitalSignsFilters.value.impella = false
    vitalSignsFilterInput.value.impella = false
    commitVitalSignsFilterInput()
  }

  function cleanupVitalSignsFilter(filter: VitalSignFilters) {
    const filteredCases = Object.values(casesWithVitalSigns.value).filter((caze) =>
      filter.hospitals.some((h) => h.id === caze.hospital.id)
    )

    filter.wards = filter.wards.filter((ward) => filteredCases.some((c) => c.ward.name === ward))

    filter.departments = filter.departments.filter((department) =>
      filteredCases.some((c) => c.department.name === department)
    )
  }

  return {
    vitalSignsFilterInput,
    vitalSignsFilters,
    commitVitalSignsFilterInput,
    initVitalSignsFilters: initVitalSignsDisplayOptions,
    filterVitalSignsCases,
    removeHospitalFilter,
    removeDepartmentFilter,
    removeWardFilter,
    vitalSignsLoading,
    getVitalSigns,
    sortedCasesWithVitalSigns,
    getVitalSignsByCaseId,
    vitalSignsSortBy,
    vitalSignsSortByInput,
    commitVitalSignsSortByInput,
    removeTemperatureRangeFilter,
    removeLactateRangeFilter,
    removeUrineOutputRangeFilter,
    removepHRangeFilter,
    removePotassiumRangeFilter,
    removeHemoglobinRangeFilter,
    removeHeartRateRangeFilter,
    removeOxygenSaturationRangeFilter,
    removeVasopressorsFilter,
    removeDialysisFilter,
    removeVentilationFilter,
    removeEcmoFilter,
    removeImpellaFilter,
    vitalSignsDisplayMode,
    commitVitalSignsDisplayModeInput
  }
})
