import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
import type { VitalSignsWithTypeDTO } from '@/generated'

const keysWithIntegerValues: VitalSignsKeys[] = [
  VitalSignsKeys.HEART_RATE,
  VitalSignsKeys.INV_S_BP,
  VitalSignsKeys.INV_D_BP,
  VitalSignsKeys.NON_INV_S_BP,
  VitalSignsKeys.NON_INV_D_BP
]

const keysWithDecimalValues: VitalSignsKeys[] = [
  VitalSignsKeys.RR,
  VitalSignsKeys.SPO2,
  VitalSignsKeys.TEMP
]

export const formatVitalSigns = (vitalSigns: VitalSignsWithTypeDTO[]): VitalSignsWithTypeDTO[] => {
  return vitalSigns.map((vitalSign) => {
    const key = vitalSign.vitalSignType.typeKey

    if (keysWithIntegerValues.includes(key as VitalSignsKeys)) {
      return {
        ...vitalSign,
        items: vitalSign.items.map((item) => ({
          ...item,
          numericValue: Math.round(item.numericValue)
        }))
      }
    }

    if (keysWithDecimalValues.includes(key as VitalSignsKeys)) {
      return {
        ...vitalSign,
        items: vitalSign.items.map((item) => ({
          ...item,
          numericValue: Number(item.numericValue.toFixed(1))
        }))
      }
    }

    return {
      ...vitalSign,
      items: vitalSign.items.map((item) => ({
        ...item,
        numericValue: Number(item.numericValue.toFixed(1))
      }))
    }
  })
}
