<template>
  <PatientFilterChip
    v-for="hospital in vitalSignsFilters.hospitals"
    :key="hospital.id"
    :text="hospital.name"
    color="primary"
    :data-testid="`hospital-chip-${hospital.id}`"
    @click:close="removeHospitalFilter(hospital.id)"
  />
  <PatientFilterChip
    v-for="department in vitalSignsFilters.departments"
    :key="department"
    :text="t(department)"
    color="secondary"
    :data-testid="`department-chip-${department}`"
    @click:close="removeDepartmentFilter(department)"
  />
  <PatientFilterChip
    v-for="ward in vitalSignsFilters.wards"
    :key="ward"
    :text="ward"
    color="secondary"
    :data-testid="`ward-chip-${ward}`"
    @click:close="removeWardFilter(ward)"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.temperatureRange.length"
    :text="`${t('Temperature')}: ${formatRange(vitalSignsFilters.temperatureRange)}`"
    color="ternary"
    data-testid="temperature-range-chip"
    @click:close="removeTemperatureRangeFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.lactateRange.length"
    :text="`${t('Lactate')}: ${formatRange(vitalSignsFilters.lactateRange)}`"
    color="ternary"
    data-testid="lactate-range-chip"
    @click:close="removeLactateRangeFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.urineOutputRange.length"
    :text="`${t('Urine Output')}: ${formatRange(vitalSignsFilters.urineOutputRange)}`"
    color="ternary"
    data-testid="urine-output-range-chip"
    @click:close="removeUrineOutputRangeFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.pHRange.length"
    :text="`${t('pH')}: ${formatRange(vitalSignsFilters.pHRange)}`"
    color="ternary"
    data-testid="pH-range-chip"
    @click:close="removepHRangeFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.potassiumRange.length"
    :text="`${t('Potassium')}: ${formatRange(vitalSignsFilters.potassiumRange)}`"
    color="ternary"
    data-testid="potassium-range-chip"
    @click:close="removePotassiumRangeFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.hemoglobinRange.length"
    :text="`${t('Hemoglobin')}: ${formatRange(vitalSignsFilters.hemoglobinRange)}`"
    color="ternary"
    data-testid="hemoglobin-range-chip"
    @click:close="removeHemoglobinRangeFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.heartRateRange.length"
    :text="`${t('Heart Rate')}: ${formatRange(vitalSignsFilters.heartRateRange)}`"
    color="ternary"
    data-testid="heart-rate-range-chip"
    @click:close="removeHeartRateRangeFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.oxygenSaturationRange.length"
    :text="`${t('Oxygen Saturation')}: ${formatRange(vitalSignsFilters.oxygenSaturationRange)}`"
    color="ternary"
    data-testid="oxygen-saturation-range-chip"
    @click:close="removeOxygenSaturationRangeFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.vasopressors"
    :text="t('Vasopressors')"
    color="ternary"
    data-testid="vasopressors-chip"
    @click:close="removeVasopressorsFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.dialysis"
    :text="t('Dialysis')"
    color="ternary"
    data-testid="dialysis-chip"
    @click:close="removeDialysisFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.ventilation"
    :text="t('Ventilation')"
    color="ternary"
    data-testid="ventilation-chip"
    @click:close="removeVentilationFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.ecmo"
    :text="t('ECMO')"
    color="ternary"
    data-testid="ecmo-chip"
    @click:close="removeEcmoFilter"
  />
  <PatientFilterChip
    v-if="vitalSignsFilters.impella"
    :text="t('Impella')"
    color="ternary"
    data-testid="impella-chip"
    @click:close="removeImpellaFilter"
  />
</template>

<script setup lang="ts">
import PatientFilterChip from '@/components/patient/filter/PatientFilterChip.vue'
import { storeToRefs } from 'pinia'
import { useVitalSignsFilterStore } from '@/stores/vitalSigns'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const vitalSignsFilterStore = useVitalSignsFilterStore()
const { vitalSignsFilters } = storeToRefs(vitalSignsFilterStore)

const {
  removeDepartmentFilter,
  removeWardFilter,
  removeHospitalFilter,
  removeTemperatureRangeFilter,
  removeLactateRangeFilter,
  removeVasopressorsFilter,
  removeDialysisFilter,
  removeVentilationFilter,
  removeEcmoFilter,
  removeImpellaFilter,
  removeUrineOutputRangeFilter,
  removepHRangeFilter,
  removePotassiumRangeFilter,
  removeHemoglobinRangeFilter,
  removeHeartRateRangeFilter,
  removeOxygenSaturationRangeFilter
} = vitalSignsFilterStore

function formatRange(range: readonly (string | number)[]): string {
  return `${range[0]} - ${range[1]}`
}
</script>
