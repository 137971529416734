<template>
  <div class="flex flex-wrap max-sm:gap-2">
    <span
      v-for="(displayProp, index) in displayProperties"
      :key="`${id}-${index}`"
      :class="['flex', 'items-center', `mr-${itemGapNumber}`]"
    >
      <VIcon
        :icon="displayProp.icon"
        :class="['mr-1', !previewMode ? 'opacity-40 text-disabled' : 'opacity-100 text-[#206683]']"
        size="16"
      />
      <span :class="[!previewMode ? 'text-secondary-text' : 'text-[#206683]']">
        {{ displayProp.value.value }}
      </span>
    </span>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { type CaseDTO, type PatientDetailsDTO } from '@/generated'
import { computed } from 'vue'
import { parse } from 'date-fns'
import { UTCDate } from '@date-fns/utc'

export interface PatientDetailsProps {
  caze: CaseDTO
  patientDetails?: PatientDetailsDTO
  itemGap?: 'small' | 'large'
  previewMode?: boolean
}

const props = withDefaults(defineProps<PatientDetailsProps>(), {
  patientDetails: undefined,
  itemGap: 'small',
  previewMode: false
})

const { d } = useI18n()

const { id } = props.caze

const externalCaseId = computed<string>(() => props.caze.externalId)

const externalPatientId = computed<string | undefined>(() => props.patientDetails?.externalId)

const dateOfBirth = computed<string>(() => {
  if (!props.caze.patient.dateOfBirth) {
    return ''
  }

  const dob = parse(props.caze.patient.dateOfBirth, 'yyyy-MM-dd', new UTCDate())

  return d(dob, 'dateOfBirth')
})

const hospital = computed<string>(() => props.caze.hospital?.name ?? '?')

const department = computed<string>(() => props.caze.department?.name ?? '?')

const ward = computed<string>(() => props.caze.ward?.name ?? '?')

const displayProperties = computed(() =>
  (!props.previewMode
    ? [
        {
          // Case ID
          icon: 'folder',
          value: externalCaseId
        },
        {
          // Patient ID
          icon: 'person',
          value: externalPatientId
        },
        {
          // Date of Birth
          icon: 'cake',
          value: dateOfBirth
        },
        {
          // Hospital
          icon: 'business',
          value: hospital
        },
        {
          // Department
          icon: 'location_pin',
          value: department
        },
        {
          // Ward
          icon: 'help',
          value: ward
        }
      ]
    : [
        {
          // Hospital
          icon: 'business',
          value: hospital
        },
        {
          // Department
          icon: 'location_pin',
          value: department
        }
      ]
  ).filter((item) => item.value.value !== undefined)
)

const itemGapNumber = props.itemGap === 'large' ? 4 : 2
</script>
