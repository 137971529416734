<template>
  <VContainer>
    <div class="flex flex-col flex-1">
      <div class="flex flex-row justify-between w-full mb-2">
        <h2 class="text-primary-text text-h2">
          {{ h2text }}
        </h2>

        <div class="flex items-center">
          <VBtn
            color="primary"
            :active="false"
            class="button-medium"
            variant="outlined"
            size="medium"
            @click="toggleVitalSignsDisplayMode"
          >
            <VIcon
              v-if="vitalSignsDisplayMode.display === 'compact'"
              icon="table_rows"
              size="24"
              class="text-[#206683]"
            />
            <VIcon v-else icon="custom:tableRows" size="24" class="text-[#206683]" />
          </VBtn>
        </div>
      </div>

      <div class="flex flex-col gap-2 items-start mb-6">
        <div class="flex flex-row gap-2 flex-wrap">
          <VBtn
            color="primary"
            :active="false"
            class="button-medium"
            variant="outlined"
            size="medium"
            prepend-icon="sort"
            @click.prevent
          >
            {{ getSortByChosenOptionLabel }}
            <VitalSignsSortingDialog />
          </VBtn>

          <VitalSignsPatientFilterButton />
          <VitalSignsFilterChips />
        </div>
      </div>
    </div>

    <div
      v-if="initialVitalSignsLoading || (noCasesAfterFiltering && vitalSignsLoading)"
      class="my-2"
    >
      <VProgressLinear indeterminate />
    </div>

    <div v-else>
      <VCard
        v-if="vitalSignsDisplayMode.display === 'compact'"
        class="grid bg-white rounded-md px-4 py-2 overflow-x-auto gap-0"
        style="grid-template-columns: 200px 140px repeat(10, auto)"
      >
        <span
          class="table-header justify-self-start px-2 py-4 cursor-pointer hover:bg-gray-50 flex items-center w-full"
          :class="{ sorted: vitalSignsSortBy.sortBy === VitalSignsKeys.PATIENT_NAME }"
          @click="handleSort(VitalSignsKeys.PATIENT_NAME)"
        >
          {{ t('Name').toLocaleUpperCase() }}
          <VIcon
            v-if="vitalSignsSortBy.sortBy === VitalSignsKeys.PATIENT_NAME"
            :icon="sortIcon"
            size="18"
            class="ml-1"
          />
        </span>
        <span
          class="table-header justify-self-center px-2 py-4 cursor-pointer hover:bg-gray-50 flex items-center w-full"
          :class="{ sorted: vitalSignsSortBy.sortBy === VitalSignsKeys.NON_INV_S_BP }"
          @click="handleSort(VitalSignsKeys.NON_INV_S_BP)"
        >
          {{ t('RR non-inv').toLocaleUpperCase() }}
          <VIcon
            v-if="vitalSignsSortBy.sortBy === VitalSignsKeys.NON_INV_S_BP"
            :icon="sortIcon"
            size="18"
            class="ml-1"
          />
        </span>
        <span
          class="table-header justify-self-center px-2 py-4 cursor-pointer hover:bg-gray-50 flex items-center w-full"
          :class="{ sorted: vitalSignsSortBy.sortBy === VitalSignsKeys.HEART_RATE }"
          @click="handleSort(VitalSignsKeys.HEART_RATE)"
        >
          {{ t('HR [1/min]').toLocaleUpperCase() }}
          <VIcon
            v-if="vitalSignsSortBy.sortBy === VitalSignsKeys.HEART_RATE"
            :icon="sortIcon"
            size="18"
            class="ml-1"
          />
        </span>
        <span
          class="table-header justify-self-center px-2 py-4 cursor-pointer hover:bg-gray-50 flex items-center w-full"
          :class="{ sorted: vitalSignsSortBy.sortBy === VitalSignsKeys.SPO2 }"
          @click="handleSort(VitalSignsKeys.SPO2)"
        >
          {{ t('SP02 [%]').toLocaleUpperCase() }}
          <VIcon
            v-if="vitalSignsSortBy.sortBy === VitalSignsKeys.SPO2"
            :icon="sortIcon"
            size="18"
            class="ml-1"
          />
        </span>
        <span
          class="table-header justify-self-center px-2 py-4 cursor-pointer hover:bg-gray-50 flex items-center w-full"
          :class="{ sorted: vitalSignsSortBy.sortBy === VitalSignsKeys.RR }"
          @click="handleSort(VitalSignsKeys.RR)"
        >
          {{ t('RR [1/min]').toLocaleUpperCase() }}
          <VIcon
            v-if="vitalSignsSortBy.sortBy === VitalSignsKeys.RR"
            :icon="sortIcon"
            size="18"
            class="ml-1"
          />
        </span>
        <span
          class="table-header justify-self-center px-2 py-4 cursor-pointer hover:bg-gray-50 flex items-center w-full"
          :class="{ sorted: vitalSignsSortBy.sortBy === VitalSignsKeys.TEMP }"
          @click="handleSort(VitalSignsKeys.TEMP)"
        >
          {{ t('TEMP [°C]').toLocaleUpperCase() }}
          <VIcon
            v-if="vitalSignsSortBy.sortBy === VitalSignsKeys.TEMP"
            :icon="sortIcon"
            size="18"
            class="ml-1"
          />
        </span>
        <span class="table-header justify-self-center px-2 py-4">
          {{ t('VP').toLocaleUpperCase() }}
        </span>
        <span class="table-header justify-self-center px-2 py-4">
          {{ t('Ventilation').toLocaleUpperCase() }}
        </span>
        <span class="table-header justify-self-center px-2 py-4">
          {{ t('Dialysis').toLocaleUpperCase() }}
        </span>
        <span class="table-header justify-self-center px-2 py-4">
          {{ t('ECMO').toLocaleUpperCase() }}
        </span>
        <span class="table-header justify-self-center px-2 py-4">
          {{ t('Impella').toLocaleUpperCase() }}
        </span>
        <span></span>

        <CompactPatientCard
          v-for="patientCase in filteredCases"
          :key="patientCase.id"
          :caze="patientCase"
          :vital-signs="patientCase.vitalSigns"
          :laboratory="patientCase.laboratory"
        />
      </VCard>

      <div v-else class="flex flex-col gap-6">
        <ExpandedPatientCard
          v-for="patientCase in filteredCases"
          :key="patientCase.id"
          :caze="patientCase"
          :vital-signs="patientCase.vitalSigns"
          :laboratory="patientCase.laboratory"
        />
      </div>
    </div>

    <div v-if="!initialVitalSignsLoading && !vitalSignsLoading" class="flex justify-center py-4">
      <span
        v-if="noCasesAfterFiltering"
        data-testid="no-cases-after-filtering-message"
        class="text-body-2 text-secondary-text"
      >
        {{ t("No results. Please ensure that you haven't applied too many filters.") }}
      </span>
      <span
        v-else-if="noMoreCasesAvailable"
        data-testid="all-patients-shown-message"
        class="text-body-2 text-secondary-text"
      >
        {{ t('All patients are shown') }}
      </span>
      <VBtn
        v-else
        data-testid="show-more-button"
        class="button-large px-3"
        size="large"
        variant="text"
        color="primary"
        @click="onShowMore()"
      >
        {{ t('Show more') }}
      </VBtn>
    </div>
  </VContainer>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { type CaseWithVitalSigns } from '@/stores/case'
import { usePatientListStore } from '@/stores/patientList'
import { storeToRefs } from 'pinia'
import VitalSignsPatientFilterButton from '@/components/patient/filter/VitalSignsPatientFilterButton.vue'
import VitalSignsFilterChips from '@/components/patient/filter/VitalSignFilterChips.vue'
import { useRepeatWhileMounted } from '@/composables/repeatWhileMounted'
import { getVitalSignsSortByOptions } from '@/components/patient/models/VitalSignsSortByOptions'
import VitalSignsSortingDialog from '@/components/patient/components/molecules/VitalSignsSortingDialog.vue'
import { getRefreshIntervalVitalSigns } from '@/global/environmentGetters'
import { useVitalSignsFilterStore } from '@/stores/vitalSigns'
import CompactPatientCard from '../molecules/CompactPatientCard.vue'
import ExpandedPatientCard from '../molecules/ExpandedPatientCard.vue'
import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'

const { t } = useI18n()
const patientListStore = usePatientListStore()
const vitalSignsFilterStore = useVitalSignsFilterStore()

const initialVitalSignsLoading = ref(true)

const {
  vitalSignsLoading,
  sortedCasesWithVitalSigns,
  vitalSignsDisplayMode,
  vitalSignsSortBy,
  vitalSignsSortByInput
} = storeToRefs(vitalSignsFilterStore)

onMounted(() => {
  vitalSignsFilterStore.initVitalSignsFilters()
})

useRepeatWhileMounted(async () => {
  initialVitalSignsLoading.value = true
  await vitalSignsFilterStore.getVitalSigns()
  initialVitalSignsLoading.value = false
}, getRefreshIntervalVitalSigns())

const { numberOfItemsToShow } = storeToRefs(patientListStore)
const { increaseItemsToShowByTen } = patientListStore

const getSortByChosenOptionLabel = computed<string>(() => {
  return (
    getVitalSignsSortByOptions(t).find(
      (el) =>
        el.value.direction === vitalSignsFilterStore.vitalSignsSortBy.direction &&
        el.value.sortBy === vitalSignsFilterStore.vitalSignsSortBy.sortBy
    )?.label || t('Sort by...')
  )
})

const numberOfCasesReceived = computed<number>(() => {
  return sortedCasesWithVitalSigns.value.length
})

const numberOfCasesAfterFiltering = computed<number>(() => {
  return filteredCases.value.length
})

const filteredCases = computed<CaseWithVitalSigns[]>(() => {
  return sortedCasesWithVitalSigns.value.filter((caze) =>
    vitalSignsFilterStore.filterVitalSignsCases(caze, true)
  )
})

const noMoreCasesAvailable = computed<boolean>(() => {
  return filteredCases.value.length <= numberOfItemsToShow.value
})

const noCasesAfterFiltering = computed<boolean>(() => {
  return numberOfCasesAfterFiltering.value === 0
})

const h2text = computed<string>(() => {
  if (numberOfCasesReceived.value === numberOfCasesAfterFiltering.value) {
    return t('All Patients ({count})', { count: numberOfCasesReceived.value })
  }
  return t('All Patients ({filtered}/{received})', {
    filtered: numberOfCasesAfterFiltering.value,
    received: numberOfCasesReceived.value
  })
})

function onShowMore() {
  increaseItemsToShowByTen()
}

const sortIcon = computed(() => {
  return vitalSignsSortBy.value.direction === 'ASC' ? 'arrow_upward' : 'arrow_downward'
})

function handleSort(key: VitalSignsKeys) {
  if (vitalSignsSortBy.value.sortBy === key) {
    vitalSignsSortByInput.value = {
      sortBy: key,
      direction: vitalSignsSortBy.value.direction === 'ASC' ? 'DESC' : 'ASC'
    }
  } else {
    vitalSignsSortByInput.value = {
      sortBy: key,
      direction: 'ASC'
    }
  }
  vitalSignsFilterStore.commitVitalSignsSortByInput()
}

function toggleVitalSignsDisplayMode() {
  vitalSignsDisplayMode.value = {
    display: vitalSignsDisplayMode.value.display === 'compact' ? 'expanded' : 'compact'
  }
  vitalSignsFilterStore.commitVitalSignsDisplayModeInput()
}
</script>

<style scoped>
.table-header {
  font-size: 11px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #1e21348a;
}

.table-header.sorted {
  background-color: #ededef;
  min-width: 80px;
}
</style>
