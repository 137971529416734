import type { CaseDTO, ConsultationRequestDTO } from '@/generated'
import type { PatientFilter } from '@/stores/models/PatientFilter'

export function getPatientCountForWardName(
  caseList: CaseDTO[],
  filterInput: PatientFilter,
  wardName: string
): number {
  return caseList.filter(
    (c) =>
      filterInput.hospitals.map((h) => h.id).includes(c.hospital.id) && c.ward.name === wardName
  ).length
}

export function getPatientCountForDepartmentName(
  caseList: CaseDTO[],
  filterInput: PatientFilter,
  departmentName: string
): number {
  return caseList.filter((c) => {
    if (filterInput.wards.length !== 0) {
      return (
        filterInput.hospitals.map((h) => h.id).includes(c.hospital.id) &&
        filterInput.wards.includes(c.ward.name) &&
        c.department.name === departmentName
      )
    }

    return (
      filterInput.hospitals.map((h) => h.id).includes(c.hospital.id) &&
      c.department.name === departmentName
    )
  }).length
}

export function getTotalPatientCountForWardName(caseList: CaseDTO[], wardName: string): number {
  return caseList.filter((c) => c.ward.name === wardName).length
}

export function getTotalPatientCountForDepartmentName(
  caseList: CaseDTO[],
  departmentName: string
): number {
  return caseList.filter((c) => c.department.name === departmentName).length
}

export function getTotalPatientCountForMedicalField(
  consultationRequests: ConsultationRequestDTO[],
  medicalField: string
): number {
  return consultationRequests.filter((c) => c.medicalField === medicalField).length
}
