<template>
  <VDialog
    v-model="showDialog"
    activator="parent"
    max-width="600px"
    :transition="false"
    attach="#app"
  >
    <template #default>
      <VCard class="p-4">
        <VCardTitle class="flex justify-space-between items-center p-0">
          <div class="text-h4 p-4 pb-2">{{ t('Sort order') }}</div>
          <VBtn
            icon="close"
            variant="plain"
            class="button-small mr-2"
            size="small"
            :ripple="false"
            data-testid="btn-close"
            @click="closeDialog"
          />
        </VCardTitle>
        <div class="overflow-y-auto">
          <VCardItem class="p-2">
            <VSelect
              v-model="vitalSignsSortByInput.direction"
              :items="[
                { title: t('Ascending'), value: 'ASC' },
                { title: t('Descending'), value: 'DESC' }
              ]"
              :label="t('Sort direction')"
              class="my-4"
              hide-details
              base-color="secondary-text"
            />

            <VRadioGroup v-model="vitalSignsSortByInput.sortBy" class="mb-4" hide-details>
              <VRadio
                :label="t('Patient name')"
                :value="VitalSignsKeys.PATIENT_NAME"
                :ripple="false"
                base-color="secondary-text"
              />
              <VRadio
                :label="t('RR non-inv')"
                :value="VitalSignsKeys.NON_INV_S_BP"
                :ripple="false"
                base-color="secondary-text"
              />
              <VRadio
                :label="t('Heart rate')"
                :value="VitalSignsKeys.HEART_RATE"
                :ripple="false"
                base-color="secondary-text"
              />
              <VRadio
                :label="t('SP02')"
                :value="VitalSignsKeys.SPO2"
                :ripple="false"
                base-color="secondary-text"
              />
              <VRadio
                :label="t('Respiratory rate')"
                :value="VitalSignsKeys.RR"
                :ripple="false"
                base-color="secondary-text"
              />
              <VRadio
                :label="t('Body temperature')"
                :value="VitalSignsKeys.TEMP"
                :ripple="false"
                base-color="secondary-text"
              />
            </VRadioGroup>
          </VCardItem>
        </div>

        <template #actions>
          <VCardActions>
            <div class="pt-2">
              <VBtn
                variant="text"
                color="primary"
                data-testid="btn-cancel"
                class="ml-auto button-large"
                size="large"
                :text="t('Cancel')"
                @click="closeDialog"
              />
              <VBtn
                variant="flat"
                color="primary"
                class="ml-4 button-large"
                size="large"
                :text="t('Apply')"
                data-testid="btn-confirm"
                @click="onSubmit"
              />
            </div>
          </VCardActions>
        </template>
      </VCard>
    </template>
  </VDialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useVitalSignsFilterStore } from '@/stores/vitalSigns'

import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
const { t } = useI18n()
const showDialog = ref<boolean>(false)

const vitalSignsFilterStore = useVitalSignsFilterStore()
const { commitVitalSignsSortByInput } = vitalSignsFilterStore
const { vitalSignsSortByInput } = storeToRefs(vitalSignsFilterStore)

function onSubmit() {
  commitVitalSignsSortByInput()
  closeDialog()
}

function closeDialog() {
  showDialog.value = false
}
</script>
