import { acceptHMRUpdate, defineStore } from 'pinia'
import { computed, type Ref, ref, watch } from 'vue'
import type { CaseDTO, GetVitalSignsResponse, HospitalDTO } from '@/generated'
import { APIService } from '@/services/telehealthApi/APIService'
import { arrayToRecord } from '@/util/convertArrayToRecord'
import { useNoticeStore } from '@/stores/notice'
import { NoticeType } from '@/stores/models/Notice'
import type { PatientFilter } from '@/stores/models/PatientFilter'
import { remove } from 'lodash'
import { useLocalStorage } from '@vueuse/core'

export type CaseWithVitalSigns = CaseDTO & GetVitalSignsResponse

export const useCaseStore = defineStore('case', () => {
  const casesLoading = ref(false)

  const cases: Ref<Record<string, CaseDTO>> = ref({})

  const patientFilter: Ref<PatientFilter> = useLocalStorage('patientFilter', {
    hospitals: [],
    departments: [],
    wards: []
  })

  const patientFilterInput: Ref<PatientFilter> = ref(patientFilter.value)

  const caseList = computed<CaseDTO[]>(() => Object.values(cases.value))

  async function refreshCases() {
    try {
      casesLoading.value = true
      cases.value = arrayToRecord((await APIService.getCases()).cases)
    } catch (error) {
      cases.value = {}
      useNoticeStore().add({ type: NoticeType.ERROR, message: 'Failed to load patient data' })
    } finally {
      casesLoading.value = false
    }
  }

  function getCaseById(id: string): CaseDTO | undefined {
    return cases.value[id]
  }

  function commitPatientFilterInput() {
    patientFilter.value = { ...patientFilterInput.value }
  }

  function resetPatientFilterInput() {
    patientFilterInput.value = { ...patientFilter.value }
  }

  function cleanupPatientFilter() {
    cleanupFilter(patientFilter.value)
  }

  function cleanupPatientFilterInput() {
    cleanupFilter(patientFilterInput.value)
  }

  function cleanupFilter(filter: PatientFilter) {
    const caseListFilteredByHospitals = caseList.value.filter((caze) =>
      filter.hospitals.some((hospital) => hospital.id === caze.hospital.id)
    )

    filter.wards = filter.wards.filter((wardName) => {
      return caseListFilteredByHospitals.some((caze) => caze.ward.name === wardName)
    })

    filter.departments = filter.departments.filter((departmentName) => {
      return caseListFilteredByHospitals.some((caze) => caze.department.name === departmentName)
    })
  }

  function removeHospitalFilter(hospitalId: string) {
    remove(patientFilter.value.hospitals, (h: HospitalDTO) => h.id === hospitalId)
    cleanupPatientFilter()
  }

  function removeDepartmentFilter(department: string) {
    remove(patientFilter.value.departments, (d: string) => d === department)
  }

  function removeWardFilter(ward: string) {
    remove(patientFilter.value.wards, (w: string) => w === ward)
  }

  watch(
    () => patientFilterInput.value.hospitals,
    () => {
      cleanupPatientFilterInput()
    }
  )

  return {
    casesLoading,
    cases,
    caseList,
    patientFilter,
    patientFilterInput,
    commitPatientFilterInput,
    resetPatientFilterInput,
    refreshCases,
    getCaseById,
    removeHospitalFilter,
    removeDepartmentFilter,
    removeWardFilter
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCaseStore, import.meta.hot))
}
