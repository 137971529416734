import type { CaseDTO } from '@/generated'

export function compareCases(a: CaseDTO, b: CaseDTO, sortDirection: 'ASC' | 'DESC' = 'ASC') {
  const sortDirectionValue = sortDirection === 'ASC' ? 1 : -1
  const aLastName = a.patient?.lastName?.toLowerCase() || ''
  const bLastName = b.patient?.lastName?.toLowerCase() || ''
  const aFirstName = a.patient?.firstName?.toLowerCase() || ''
  const bFirstName = b.patient?.firstName?.toLowerCase() || ''

  if (aLastName !== bLastName) {
    return sortDirectionValue * aLastName.localeCompare(bLastName, 'de')
  } else if (aFirstName !== bFirstName) {
    return sortDirectionValue * aFirstName.localeCompare(bFirstName, 'de')
  } else {
    if (a.externalId < b.externalId) return -1 * sortDirectionValue
    if (a.externalId > b.externalId) return 1 * sortDirectionValue
    return 0
  }
}
