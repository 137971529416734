<template>
  <VBtn
    v-if="!isPostCall"
    color="primary"
    :loading="startCallInProgress"
    @click.prevent="startCall"
  >
    <template #prepend>
      <VIcon icon="videocam" class="mr-1" />
    </template>
    {{ buttonText }}
  </VBtn>
  <VBtn
    v-else
    color="primary"
    variant="outlined"
    :loading="startCallInProgress"
    @click.prevent="startCall"
  >
    <template #prepend>
      <VIcon icon="videocam" class="mr-1" />
    </template>
    {{ buttonText }}
  </VBtn>
</template>

<script setup lang="ts">
import { useCallStore } from '@/stores/call'
import { computed, ref } from 'vue'
import { ButtonVariant, type ButtonVariant as ButtonVariantType } from './ButtonVariant'
import { translateButtonVariant } from '@/util/translations'
import { useI18n } from 'vue-i18n'

const props = withDefaults(
  defineProps<{
    consultationRequestId: string
    variant?: ButtonVariantType
  }>(),
  {
    variant: ButtonVariant.ANSWER
  }
)

const { t } = useI18n()
const startCallInProgress = ref<boolean>(false)

const buttonText = computed<string>(() => translateButtonVariant(props.variant, t))
const isPostCall = computed<boolean>(() => props.variant === ButtonVariant.POST_CALL)

async function startCall() {
  startCallInProgress.value = true
  await useCallStore().startCall(props.consultationRequestId)
  startCallInProgress.value = false
}
</script>
