<template>
  <ConsultationFilterChip
    v-for="hospital in consultationFilter.hospitals"
    :key="hospital.id"
    :text="hospital.name"
    color="primary"
    @close="removeHospitalFilter(hospital.id)"
  />
  <ConsultationFilterChip
    v-for="medicalField in consultationFilter.targetMedicalFields"
    :key="medicalField"
    :text="translateMedicalFieldsDTO(medicalField, t)"
    color="primary"
    @close="removeTargetMedicalFieldFilter(medicalField)"
  />
  <ConsultationFilterChip
    v-for="department in consultationFilter.departments"
    :key="department"
    :text="department"
    color="primary"
    @close="removeDepartmentFilter(department)"
  />
  <ConsultationFilterChip
    v-for="urgency in consultationFilter.urgencies"
    :key="urgency"
    :text="translateUrgencyDTO(urgency, t)"
    color="primary"
    @close="removeUrgencyFilter(urgency)"
  />
  <ConsultationFilterChip
    v-for="assignee in consultationFilter.assignees"
    :key="assignee.userName"
    :text="userDTOtoDisplayName(assignee)"
    color="primary"
    @close="removeAssigneeFilter(assignee.userName)"
  />
  <ConsultationFilterChip
    v-for="requester in consultationFilter.requesters"
    :key="requester.userName"
    :text="userDTOtoDisplayName(requester)"
    color="primary"
    @close="removeRequesterFilter(requester.userName)"
  />
</template>

<script setup lang="ts">
import { useConsultationRequestStore } from '@/stores/consultationRequest'
import { storeToRefs } from 'pinia'
import ConsultationFilterChip from './ConsultationFilterChip.vue'
import { userDTOtoDisplayName } from '@/util/userDTOtoDisplayName'
import { translateMedicalFieldsDTO, translateUrgencyDTO } from '@/util/translations'
import { useI18n } from 'vue-i18n'

const { consultationFilter } = storeToRefs(useConsultationRequestStore())
const {
  removeHospitalFilter,
  removeTargetMedicalFieldFilter,
  removeDepartmentFilter,
  removeUrgencyFilter,
  removeAssigneeFilter,
  removeRequesterFilter
} = useConsultationRequestStore()

const { t } = useI18n()
</script>
