import { h } from 'vue'
import type { IconSet, IconProps } from 'vuetify'
import radioCheckedIcon from '@/atoms/RadioCheckedIcon.vue'
import clear20 from '@/atoms/Clear20Icon.vue'
import upload from '@/atoms/UploadIcon.vue'
import patientList from '@/atoms/PatientListIcon.vue'
import tableRows from '@/atoms/TableRows.vue'

const customIconsToComponent: any = {
  radioCheckedIcon,
  clear20,
  upload,
  patientList,
  tableRows
}

const customIcons: IconSet = {
  component: (props: IconProps) => h(customIconsToComponent[props.icon as string])
}

export { customIcons }
