<template>
  <VDialog
    v-model="showDialog"
    activator="parent"
    max-width="600px"
    :transition="false"
    attach="#app"
  >
    <VCard class="p-4">
      <ConsultationFilterContent @close-dialog="closeDialog" />
      <template #actions>
        <VCardActions>
          <VBtn
            variant="text"
            color="primary"
            data-testid="btn-cancel"
            class="ml-auto button-large"
            size="large"
            :text="t('Close')"
            @click="closeDialog"
          />
          <VBtn
            variant="flat"
            color="primary"
            class="ml-4 button-large"
            size="large"
            :text="t('Apply')"
            data-testid="btn-confirm"
            @click="onSubmit"
          />
        </VCardActions>
      </template>
    </VCard>
  </VDialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import ConsultationFilterContent from './ConsultationFilterContent.vue'
import { useConsultationRequestStore } from '@/stores/consultationRequest'

const { t } = useI18n()
const showDialog = ref(false)

function onSubmit() {
  useConsultationRequestStore().commitConsultationRequestFilterInput()
  closeDialog()
}

function closeDialog() {
  showDialog.value = false
}
</script>
