<template>
  <VSnackbar
    v-model="isShown"
    timeout="3000"
    min-width="320"
    content-class="elevation-8"
    :attach="true"
  >
    <div class="text-body-1 flex gap-3">
      <VIcon
        :icon="mapNoticeTypeToIconName[getCurrentNotice?.type]"
        :color="mapNoticeTypeToColorName[getCurrentNotice?.type]"
      />
      <span data-testid="snackbar-message">
        {{ t(getCurrentNotice?.message ?? '', getCurrentNotice?.messageParameters ?? {}) }}
      </span>
    </div>
  </VSnackbar>
</template>

<script setup lang="ts">
import { useNoticeStore } from '@/stores/notice'
import { ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { NoticeType } from '@/stores/models/Notice'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const isShown = ref(false)

const { getCurrentNotice } = storeToRefs(useNoticeStore())
const { removeCurrentNotice } = useNoticeStore()

watch(getCurrentNotice, (newNotice) => {
  isShown.value = !!newNotice
})

watch(isShown, (newIsShown) => {
  if (!newIsShown) {
    setTimeout(() => {
      removeCurrentNotice()
    }, 200)
  }
})

const mapNoticeTypeToIconName = {
  [NoticeType.ERROR]: 'error',
  [NoticeType.WARNING]: 'warning',
  [NoticeType.INFO]: 'info',
  [NoticeType.SUCCESS]: 'check_circle'
}

const mapNoticeTypeToColorName = {
  [NoticeType.ERROR]: 'error',
  [NoticeType.WARNING]: 'warning',
  [NoticeType.INFO]: 'primary',
  [NoticeType.SUCCESS]: 'success'
}
</script>
