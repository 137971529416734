<template>
  <VCardTitle class="flex justify-space-between items-center p-0 border-b border-divider">
    <div class="text-h4 p-8">{{ t('Filter patients') }}</div>
    <VBtn
      icon="close"
      variant="plain"
      class="button-small mr-2"
      size="small"
      :ripple="false"
      data-testid="btn-close"
      @click="emits('close-dialog')"
    />
  </VCardTitle>

  <VCardItem class="px-8 py-0 pt-4">
    <span class="text-subtitle-1">
      {{ t('Hospital') }}
    </span>
    <VAutocomplete
      v-model="hospital"
      clear-icon="custom:clear20"
      :items="hospitalData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>

  <VCardItem class="px-8 py-0">
    <span class="text-subtitle-1">
      {{ t('Wards') }}
    </span>
    <VAutocomplete
      v-model="wards"
      clear-icon="custom:clear20"
      :items="wardDataWithPatientCount"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>

  <VCardItem class="px-8 py-0">
    <span class="text-subtitle-1">
      {{ t('Departments') }}
    </span>
    <VAutocomplete
      v-model="departments"
      clear-icon="custom:clear20"
      :items="departmentDataWithPatientCount"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>

  <VCardItem class="px-8 py-0">
    <span class="text-subtitle-1">
      {{ t('Vital Signs') }}
    </span>

    <div class="flex gap-2 py-2 items-center flex-wrap">
      <VChip
        v-if="hasRangeFilter(temperatureRange)"
        closable
        @click:close="clearRange('temperatureRange')"
      >
        {{ t('Temperature °C') }}: {{ formatRange(temperatureRange) }}
      </VChip>

      <VChip v-if="hasRangeFilter(lactateRange)" closable @click:close="clearRange('lactateRange')">
        {{ t('Laktat (mmol/l)') }}: {{ formatRange(lactateRange) }}
      </VChip>

      <VChip
        v-if="hasRangeFilter(urineOutputRange)"
        closable
        @click:close="clearRange('urineOutputRange')"
      >
        {{ t('Urine Output (ml/day)') }}: {{ formatRange(urineOutputRange) }}
      </VChip>

      <VChip v-if="hasRangeFilter(pHRange)" closable @click:close="clearRange('pHRange')">
        {{ t('pH') }}: {{ formatRange(pHRange) }}
      </VChip>

      <VChip
        v-if="hasRangeFilter(potassiumRange)"
        closable
        @click:close="clearRange('potassiumRange')"
      >
        {{ t('Potassium (mmol/L)') }}: {{ formatRange(potassiumRange) }}
      </VChip>

      <VChip
        v-if="hasRangeFilter(hemoglobinRange)"
        closable
        @click:close="clearRange('hemoglobinRange')"
      >
        {{ t('Hemoglobin (g/dL)') }}: {{ formatRange(hemoglobinRange) }}
      </VChip>

      <VChip
        v-if="hasRangeFilter(heartRateRange)"
        closable
        @click:close="clearRange('heartRateRange')"
      >
        {{ t('Heart Rate (bpm)') }}: {{ formatRange(heartRateRange) }}
      </VChip>

      <VChip
        v-if="hasRangeFilter(oxygenSaturationRange)"
        closable
        @click:close="clearRange('oxygenSaturationRange')"
      >
        {{ t('Oxygen Saturation (%)') }}: {{ formatRange(oxygenSaturationRange) }}
      </VChip>
    </div>

    <VExpansionPanels>
      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Temperature °C') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="temperatureRange"
            :min="32"
            :max="42"
            :step="0.1"
            :custom-ticks="[32, 42]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Laktat (mmol/l)') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="lactateRange"
            :min="0.5"
            :max="15"
            :step="0.5"
            :custom-ticks="[0.5, 15]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Urine Output (ml/day)') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="urineOutputRange"
            :min="0"
            :max="6000"
            :step="10"
            :custom-ticks="[0, 6000]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('pH') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="pHRange"
            :min="6.8"
            :max="8.2"
            :step="0.1"
            :custom-ticks="[6.8, 8.2]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Potassium (mmol/L)') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="potassiumRange"
            :min="1.5"
            :max="8"
            :step="0.1"
            :custom-ticks="[1.5, 8]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Hemoglobin (g/dL)') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="hemoglobinRange"
            :min="3"
            :max="18"
            :step="0.5"
            :custom-ticks="[3, 18]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Heart Rate (bpm)') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="heartRateRange"
            :min="20"
            :max="180"
            :step="1"
            :custom-ticks="[20, 180]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Oxygen Saturation (%)') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <MortalityRangeSlider
            v-model="oxygenSaturationRange"
            :min="70"
            :max="100"
            :step="1"
            :custom-ticks="[70, 100]"
          />
        </VExpansionPanelText>
      </VExpansionPanel>

      <VExpansionPanel>
        <VExpansionPanelTitle>{{ t('Organ Support') }}</VExpansionPanelTitle>
        <VExpansionPanelText>
          <div class="flex flex-row flex-wrap gap-2 items-center text-body-1">
            <VCheckbox class="m-0" v-model="vasopressors" :label="t('Vasopressors')" />
            <VCheckbox v-model="dialysis" :label="t('Dialysis')" />
            <VCheckbox v-model="ventilation" :label="t('Ventilation')" />
            <VCheckbox v-model="ecmo" :label="t('ECMO')" />
            <VCheckbox v-model="impella" :label="t('Impella')" />
          </div>
        </VExpansionPanelText>
      </VExpansionPanel>
    </VExpansionPanels>
  </VCardItem>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCaseStore } from '@/stores/case'
import { computed } from 'vue'
import type { SelectOption } from '@/util/SelectOption'
import type { CaseDTO, HospitalDTO } from '@/generated'
import MortalityRangeSlider from './MortalityRangeSlider.vue'
import { useVitalSignsFilterStore } from '@/stores/vitalSigns'
import {
  getPatientCountForDepartmentName,
  getPatientCountForWardName
} from '@/util/getPatientCounts'

const emits = defineEmits(['close-dialog', 'apply-filter'])

const { t } = useI18n()
const caseStore = useCaseStore()
const { caseList } = storeToRefs(caseStore)
const vitalSignsFilterStore = useVitalSignsFilterStore()
const { vitalSignsFilterInput } = storeToRefs(vitalSignsFilterStore)

const hospital = computed({
  get: () => vitalSignsFilterInput.value.hospitals || [],
  set: (value) => {
    vitalSignsFilterInput.value.hospitals = value
  }
})

const wards = computed({
  get: () => vitalSignsFilterInput.value.wards || [],
  set: (value) => {
    vitalSignsFilterInput.value.wards = value
  }
})

const departments = computed({
  get: () => vitalSignsFilterInput.value.departments || [],
  set: (value) => {
    vitalSignsFilterInput.value.departments = value
  }
})

const temperatureRange = computed({
  get: () => vitalSignsFilterInput.value.temperatureRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.temperatureRange = value
  }
})

const lactateRange = computed({
  get: () => vitalSignsFilterInput.value.lactateRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.lactateRange = value
  }
})

const urineOutputRange = computed({
  get: () => vitalSignsFilterInput.value.urineOutputRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.urineOutputRange = value
  }
})

const pHRange = computed({
  get: () => vitalSignsFilterInput.value.pHRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.pHRange = value
  }
})

const potassiumRange = computed({
  get: () => vitalSignsFilterInput.value.potassiumRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.potassiumRange = value
  }
})

const hemoglobinRange = computed({
  get: () => vitalSignsFilterInput.value.hemoglobinRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.hemoglobinRange = value
  }
})

const heartRateRange = computed({
  get: () => vitalSignsFilterInput.value.heartRateRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.heartRateRange = value
  }
})

const oxygenSaturationRange = computed({
  get: () => vitalSignsFilterInput.value.oxygenSaturationRange?.map(Number) || [],
  set: (value) => {
    vitalSignsFilterInput.value.oxygenSaturationRange = value
  }
})

const vasopressors = computed({
  get: () => vitalSignsFilterInput.value.vasopressors || false,
  set: (value) => {
    vitalSignsFilterInput.value.vasopressors = value
  }
})

const dialysis = computed({
  get: () => vitalSignsFilterInput.value.dialysis || false,
  set: (value) => {
    vitalSignsFilterInput.value.dialysis = value
  }
})

const ventilation = computed({
  get: () => vitalSignsFilterInput.value.ventilation || false,
  set: (value) => {
    vitalSignsFilterInput.value.ventilation = value
  }
})

const ecmo = computed({
  get: () => vitalSignsFilterInput.value.ecmo || false,
  set: (value) => {
    vitalSignsFilterInput.value.ecmo = value
  }
})

const impella = computed({
  get: () => vitalSignsFilterInput.value.impella || false,
  set: (value) => {
    vitalSignsFilterInput.value.impella = value
  }
})

const hospitalData = computed<SelectOption<HospitalDTO>[]>(() => {
  const uniqueHospitals: HospitalDTO[] = [
    ...new Map(
      caseList.value
        .map((caze: CaseDTO) => caze.hospital)
        .map((hospital) => [hospital.id, hospital])
    ).values()
  ]

  return uniqueHospitals.map((hospital: HospitalDTO) => {
    return {
      value: hospital,
      title: hospital.name
    }
  })
})

const wardDataWithPatientCount = computed(() => {
  const uniqueWards = [...new Set(caseList.value.map((c) => c.ward.name))]

  return uniqueWards.map((ward) => {
    const wardPatientCount = getPatientCountForWardName(
      caseList.value,
      vitalSignsFilterInput.value,
      ward
    )
    return {
      value: ward,
      title: `${ward} (${wardPatientCount})`,
      props: {
        disabled: wardPatientCount === 0
      }
    }
  })
})

const departmentDataWithPatientCount = computed(() => {
  const uniqueDepartments = [...new Set(caseList.value.map((c) => c.department.name))]

  return uniqueDepartments.map((department) => {
    const departmentPatientCount = getPatientCountForDepartmentName(
      caseList.value,
      vitalSignsFilterInput.value,
      department
    )
    return {
      value: department,
      title: `${t(department)} (${departmentPatientCount})`,
      props: {
        disabled: departmentPatientCount === 0
      }
    }
  })
})

function hasRangeFilter(range: readonly (string | number)[] | undefined): boolean {
  return Array.isArray(range) && range.length === 2 && range.every((value) => value !== undefined)
}

function formatRange(range: readonly (string | number)[] | undefined): string {
  if (!range || !hasRangeFilter(range)) return ''
  return `${range[0]} - ${range[1]}`
}

function clearRange(
  rangeKey:
    | 'temperatureRange'
    | 'lactateRange'
    | 'urineOutputRange'
    | 'pHRange'
    | 'potassiumRange'
    | 'hemoglobinRange'
    | 'heartRateRange'
    | 'oxygenSaturationRange'
) {
  if (vitalSignsFilterInput.value) {
    switch (rangeKey) {
      case 'temperatureRange':
        vitalSignsFilterInput.value.temperatureRange = []
        break
      case 'lactateRange':
        vitalSignsFilterInput.value.lactateRange = []
        break
      case 'urineOutputRange':
        vitalSignsFilterInput.value.urineOutputRange = []
        break
      case 'pHRange':
        vitalSignsFilterInput.value.pHRange = []
        break
      case 'potassiumRange':
        vitalSignsFilterInput.value.potassiumRange = []
        break
      case 'hemoglobinRange':
        vitalSignsFilterInput.value.hemoglobinRange = []
        break
      case 'heartRateRange':
        vitalSignsFilterInput.value.heartRateRange = []
        break
      case 'oxygenSaturationRange':
        vitalSignsFilterInput.value.oxygenSaturationRange = []
        break
    }
  }
}
</script>
