<template>
  <VCard class="bg-[#FFFFFF] p-4 shadow-none">
    <VCard
      class="bg-[#FAFAFA] shadow-none flex flex-col items-center justify-center gap-4 p-4 min-h-[500px]"
    >
      <span class="text-body-1 text-primary-text p-0">
        {{ t('No consultations found') }}
      </span>

      <VBtn
        :to="{ name: routeNames.CREATE_CONSULTATION }"
        :active="false"
        :disabled="disableConsultationButton"
        class="button-large max-xl:gap-0"
        color="primary"
        prepend-icon="add"
      >
        <span class="max-xl:hidden">
          {{ t('Create consultation') }}
        </span>
      </VBtn>
    </VCard>
  </VCard>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { routeNames } from '@/router/routes'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

const { t } = useI18n()
const { hasGodModeShift } = storeToRefs(useUserStore())
const disableConsultationButton = computed(() => hasGodModeShift.value)
</script>
