<template>
  <VContainer class="flex flex-col gap-2">
    <div class="flex justify-between items-center">
      <h2 data-testid="consultation-panel-heading" class="text-h2 max-w-[60%]">
        {{ displayHeading }}
      </h2>

      <VBtn
        data-testid="menu-request-button"
        :to="{ name: routeNames.CREATE_CONSULTATION }"
        :active="false"
        :disabled="disableConsultationButton"
        class="button-large max-xl:gap-0"
        color="primary"
        prepend-icon="add"
      >
        <span class="max-xl:hidden">
          {{ t('New consultation') }}
        </span>
      </VBtn>
    </div>

    <div class="flex gap-2 items-center">
      <ConsultationFilterButton />
      <ConsultationFilterChips />
    </div>

    <SentAndReceivedConsultationTabs
      :received-consultation-requests="filteredConsultationRequests"
      :sent-consultation-requests="filteredOutgoingConsultationRequests"
    />
  </VContainer>
</template>

<script setup lang="ts">
import SentAndReceivedConsultationTabs from '@/components/consultationrequest/SentAndReceivedConsultationTabs.vue'
import type { ConsultationRequestDTO } from '@/generated'
import { useConsultationRequestStore } from '@/stores/consultationRequest'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { routeNames } from '@/router/routes'
import ConsultationFilterButton from './filter/ConsultationFilterButton.vue'
import ConsultationFilterChips from './filter/ConsultationFilterChips.vue'
const { t } = useI18n()

const { hasChiefPhysicianShift, hasGodModeShift } = storeToRefs(useUserStore())
const consultationRequestStore = useConsultationRequestStore()
const { incomingConsultationRequests, outgoingConsultationRequests, consultationFilter } =
  storeToRefs(consultationRequestStore)
const disableConsultationButton = computed(() => hasGodModeShift.value)

const displayHeading = computed<string>(() => {
  return `${t('Consultation Requests')} (${incomingConsultationRequests.value.length + outgoingConsultationRequests.value.length})`
})

const filteredConsultationRequests = computed<ConsultationRequestDTO[]>(() => {
  return incomingConsultationRequests.value.filter(filterConsultationRequests)
})

const filteredOutgoingConsultationRequests = computed<ConsultationRequestDTO[]>(() => {
  return outgoingConsultationRequests.value.filter(filterConsultationRequests)
})

function filterConsultationRequests(consultation: ConsultationRequestDTO): boolean {
  return (
    hasChiefPhysicianShift &&
    [
      filterByHospital,
      filterByMedicalField,
      filterByPatientDepartment,
      filterByUrgency,
      filterByAssignee,
      filterByRequester
    ].every((filter) => filter(consultation))
  )
}

function filterByHospital(consultation: ConsultationRequestDTO): boolean {
  const { hospitals } = consultationFilter.value

  return !hospitals?.length || hospitals?.some((h) => h.id === consultation.case.hospital.id)
}

function filterByMedicalField(consultation: ConsultationRequestDTO): boolean {
  const { targetMedicalFields } = consultationFilter.value

  return (
    !targetMedicalFields?.length ||
    targetMedicalFields?.some((f) => f === consultation.medicalField)
  )
}

function filterByPatientDepartment(consultation: ConsultationRequestDTO): boolean {
  const { departments } = consultationFilter.value

  return !departments?.length || departments?.some((d) => d === consultation.case.department.name)
}

function filterByUrgency(consultation: ConsultationRequestDTO): boolean {
  const { urgencies } = consultationFilter.value

  return !urgencies?.length || urgencies?.some((u) => u === consultation.urgency)
}

function filterByAssignee(consultation: ConsultationRequestDTO): boolean {
  const { assignees } = consultationFilter.value

  return (
    !assignees?.length || assignees?.some((a) => a.userName === consultation.assignedTo?.userName)
  )
}

function filterByRequester(consultation: ConsultationRequestDTO): boolean {
  const { requesters } = consultationFilter.value

  return (
    !requesters?.length || requesters?.some((r) => r.userName === consultation.requestedBy.userName)
  )
}
</script>

<style scoped>
.v-expansion-panel-title :hover {
  background-color: transparent !important;
}

:deep(.v-expansion-panel-text__wrapper) {
  padding: 0 0 0;
}
</style>
