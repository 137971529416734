import { type ConsultationRequestDTO, ConsultationRequestStatusDTO, UrgencyDTO } from '@/generated'
import { ConsultationRequestService } from '@/services/telehealthApi/ConsultationRequestService'
import { NoticeType } from '@/stores/models/Notice'
import { useNoticeStore } from '@/stores/notice'
import { useLocalStorage } from '@vueuse/core'
import { remove } from 'lodash'
import { defineStore } from 'pinia'
import { computed, type Ref, ref } from 'vue'
import type { ConsultationRequestFilter } from './models/ConsultationRequestFilter'

export const useConsultationRequestStore = defineStore('consultationRequest', () => {
  const incomingConsultationRequests: Ref<ConsultationRequestDTO[]> = ref([])
  const outgoingConsultationRequests: Ref<ConsultationRequestDTO[]> = ref([])

  const consultationRequestFilter: Ref<ConsultationRequestFilter> = useLocalStorage(
    'consultationRequestFilter',
    {
      hospitals: [],
      targetMedicalFields: [],
      departments: [],
      wards: [],
      urgencies: [],
      assignees: [],
      requesters: []
    }
  )
  const consultationRequestFilterInput: Ref<ConsultationRequestFilter> = ref({
    hospitals: [],
    targetMedicalFields: [],
    departments: [],
    wards: [],
    urgencies: [],
    assignees: [],
    requesters: []
  })

  const areReportsPending = computed<boolean>(
    () =>
      incomingConsultationRequests.value.filter(
        (request) => request.status === ConsultationRequestStatusDTO.REPORT_PENDING
      ).length > 0
  )

  const outgoingPendingStatuses = [
    ConsultationRequestStatusDTO.CREATED,
    ConsultationRequestStatusDTO.ACCEPTED,
    ConsultationRequestStatusDTO.IN_PROGRESS,
    ConsultationRequestStatusDTO.REPORT_PENDING
  ] as ConsultationRequestStatusDTO[]

  const areOutgoingRequestsPending = computed<boolean>(() =>
    outgoingConsultationRequests.value.some((request) =>
      outgoingPendingStatuses.includes(request.status)
    )
  )

  const getConsultationRequestsWithActiveCall = computed<ConsultationRequestDTO[]>(() =>
    [...incomingConsultationRequests.value, ...outgoingConsultationRequests.value].filter(
      (cr) =>
        cr.status === ConsultationRequestStatusDTO.IN_PROGRESS ||
        cr.status === ConsultationRequestStatusDTO.REPORT_PENDING
    )
  )

  const noticeStore = useNoticeStore()

  async function refreshIncomingConsultationRequestList(): Promise<void> {
    try {
      incomingConsultationRequests.value = mergeById(
        incomingConsultationRequests.value,
        (await ConsultationRequestService.getIncomingConsultationRequests()).consultationRequests
      )
    } catch (err) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Loading the consultation requests failed'
      })
      incomingConsultationRequests.value = []
    }
  }

  async function refreshOutgoingConsultationRequestList(): Promise<void> {
    try {
      outgoingConsultationRequests.value = mergeById(
        outgoingConsultationRequests.value,
        (await ConsultationRequestService.getOutgoingConsultationRequests()).consultationRequests
      )
    } catch (err) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Loading the consultation requests failed'
      })
      outgoingConsultationRequests.value = []
    }
  }

  async function deleteOutgoingConsultationRequestById(id: string): Promise<void> {
    try {
      await ConsultationRequestService.deleteConsultationRequest(id)
    } catch (error) {
      noticeStore.add({
        type: NoticeType.ERROR,
        message: 'Deleting the consultation request {id} failed',
        messageParameters: { id }
      })
    }
    const indexToDelete = outgoingConsultationRequests.value.findIndex(
      (request: ConsultationRequestDTO) => request.id === id
    )
    outgoingConsultationRequests.value.splice(indexToDelete, 1)
  }

  function getIncomingById(id: string): ConsultationRequestDTO | undefined {
    return incomingConsultationRequests.value.find((request) => request.id === id)
  }

  function mergeById<T extends { id: any }>(target: T[], source: T[]): T[] {
    return source.map((sourceElem) => {
      const targetElem = target.find(
        (potentialTargetElem) => potentialTargetElem.id === sourceElem.id
      )
      return targetElem ? Object.assign(targetElem, sourceElem) : sourceElem
    })
  }

  function commitConsultationRequestFilterInput() {
    consultationRequestFilter.value = { ...consultationRequestFilterInput.value }
  }

  function resetConsultationRequestFilterInput() {
    consultationRequestFilterInput.value = { ...consultationRequestFilter.value }
  }

  function removeHospitalFilter(hospitalId: string) {
    remove(consultationRequestFilter.value.hospitals, (h) => h.id === hospitalId)
  }

  function removeTargetMedicalFieldFilter(medicalField: string) {
    remove(consultationRequestFilter.value.targetMedicalFields, (f) => f === medicalField)
  }

  function removeDepartmentFilter(departmentName: string) {
    remove(consultationRequestFilter.value.departments, (d) => d === departmentName)
  }

  function removeUrgencyFilter(urgency: UrgencyDTO) {
    remove(consultationRequestFilter.value.urgencies, (u) => u === urgency)
  }

  function removeAssigneeFilter(assigneeUserName: string) {
    remove(consultationRequestFilter.value.assignees, (a) => a.userName === assigneeUserName)
  }

  function removeRequesterFilter(requesterUserName: string) {
    remove(consultationRequestFilter.value.requesters, (r) => r.userName === requesterUserName)
  }

  return {
    incomingConsultationRequests,
    outgoingConsultationRequests,
    consultationFilter: consultationRequestFilter,
    consultationFilterInput: consultationRequestFilterInput,
    commitConsultationRequestFilterInput,
    resetConsultationRequestFilterInput,
    removeHospitalFilter,
    removeTargetMedicalFieldFilter,
    removeDepartmentFilter,
    removeUrgencyFilter,
    removeAssigneeFilter,
    removeRequesterFilter,
    areReportsPending,
    areOutgoingRequestsPending,
    getConsultationRequestsWithActiveCall,
    refreshIncomingConsultationRequestList,
    refreshOutgoingConsultationRequestList,
    deleteOutgoingConsultationRequestById,
    getIncomingById
  }
})
