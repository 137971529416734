<template>
  <div v-if="text" class="formatted-text" data-testid="formatted-medical-text" :class="customClass">
    <p v-for="(line, index) in formattedLines" :key="index" class="mb-1">
      <template v-if="line">{{ line }}</template>
      <span v-else>&nbsp;</span>
    </p>
  </div>
  <div v-else class="text-body-2 text-disabled">{{ emptyText }}</div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    text?: string
    emptyText?: string
    customClass?: string
  }>(),
  {
    text: '',
    emptyText: '-',
    customClass: ''
  }
)

const formattedLines = computed(() => {
  if (!props.text) return []

  let processedText = props.text
    .replace(/\\r\\n/g, '\r\n')
    .replace(/\\n/g, '\n')
    .replace(/\\r\\/g, '\r')

  processedText = processedText.replace(/\r\n/g, '\n').replace(/\r/g, '\n')

  return processedText.split('\n').map((line) => line.trim())
})
</script>

<style scoped>
.formatted-text p {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}

.formatted-text p:last-child {
  margin-bottom: 0;
}
</style>
