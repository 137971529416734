<template>
  <VDialog
    v-model="showDialog"
    activator="parent"
    max-width="980px"
    :transition="false"
    attach="#app"
  >
    <template #default>
      <VCard class="flex flex-col gap-4 px-4 pb-4 sm:px-8 sm:pb-8">
        <VCardTitle class="sticky z-10 top-0 -mx-8 bg-white">
          <div class="flex justify-between items-center">
            <h3 class="text-h3 p-6">
              <PatientTitle :patient="caze.patient" />
            </h3>
            <VBtn
              icon="close"
              variant="plain"
              class="button-small mr-2"
              size="small"
              :ripple="false"
              data-testid="btn-close"
              @click="closeDialog"
            />
          </div>
          <DividerLine class="-mx-8" />
        </VCardTitle>

        <div class="card">
          <h4 class="text-h4 mb-4 font-bold">{{ t('Vital Signs') }}</h4>
          <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
            <ResultItem
              v-for="(vital, index) in vitalSigns"
              :key="index"
              :label="vital.label"
              :value="vital.value"
              :timestamp="vital.timestamp"
            />
          </div>
        </div>

        <div class="card flex flex-col">
          <h4 class="text-h4 mb-4 font-bold">{{ t('Organ Support') }}</h4>

          <div class="flex flex-wrap gap-4">
            <div
              v-for="support in organSupport"
              :key="support.key"
              class="bg-[#F3F4F7] flex flex-1 basis-1/6 flex-col items-center justify-center rounded-md p-2 min-w-[160px]"
            >
              <span class="text-caption text-[#206683]">{{ t(support.label) }}</span>
              <span class="text-subtitle-2 font-bold text-[#17485D]">
                <VIcon
                  size="16"
                  :icon="support.value ? 'check' : 'close'"
                  :class="support.value ? 'text-success' : 'text-error'"
                />
              </span>
            </div>
          </div>
        </div>

        <div class="card">
          <h4 class="text-h4 mb-4 font-bold">{{ t('Lab Values') }}</h4>

          <div class="grid grid-cols-1 gap-4 md:grid-cols-3">
            <ResultItem
              v-for="(vital, index) in labValues"
              :key="index"
              :label="vital.label"
              :value="vital.value"
              :timestamp="vital.timestamp"
            />
          </div>
        </div>

        <DividerLine class="-mx-8" />

        <div class="mt-2 flex flex-wrap justify-end gap-4 flex-col sm:flex-row">
          <VBtn
            variant="outlined"
            color="primary"
            class="button-large min-w-[150px]"
            size="large"
            :text="t('Patient Details')"
            @click="handleDisplayPatientDetails"
            append-icon="chevron_right"
          />

          <VBtn
            variant="flat"
            color="primary"
            data-testid="btn-cancel"
            class="button-large min-w-[150px]"
            size="large"
            :text="t('Close')"
            @click="closeDialog"
          />
        </div>
      </VCard>
    </template>
  </VDialog>
</template>

<script setup lang="ts">
import type { CaseDTO, VitalSignsWithTypeDTO } from '@/generated'
import { computed, ref } from 'vue'
import PatientTitle from '@/components/patient/components/atoms/PatientTitle.vue'
import { VitalSignsKeys } from '../../models/VitalSignsKeys'
import { findLatestTimeValueByKey, findLatestValueByKey } from './VitalInfoTable.utils'
import type { OrganSupport } from './ExpandedPatientCard.vue'
import { useI18n } from 'vue-i18n'
import ResultItem from '@/components/patient/components/atoms/ResultItem.vue'
import { useRouter } from 'vue-router'
import DividerLine from '@/atoms/DividerLine.vue'
interface Props {
  show: boolean
  caze: CaseDTO
  vitalSigns: VitalSignsWithTypeDTO[]
  laboratory: VitalSignsWithTypeDTO[]
  organSupport: OrganSupport[]
}

const showDialog = ref<boolean>(false)

function closeDialog() {
  showDialog.value = false
}

const { t } = useI18n()
const router = useRouter()

const props = defineProps<Props>()

const NON_INV_BP = computed(() => {
  const sbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_S_BP).value
  const dbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_D_BP).value
  const mbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_M_BP).value
  return `${sbp} | ${dbp} | ${mbp}`
})

const INV_BP = computed(() => {
  const sbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.INV_S_BP).value
  const dbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.INV_D_BP).value
  const mbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.INV_M_BP).value
  return `${sbp} | ${dbp} | ${mbp}`
})

const HEART_RATE = computed(
  () => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.HEART_RATE).value
)

const SPO2 = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.SPO2).value)
const RR = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.RR).value)
const TEMP = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.TEMP).value)

const vitalSigns = computed(() => [
  {
    label: 'SAP | DAP | MAP  (mmHg)',
    value: String(INV_BP.value),
    timestamp: findLatestTimeValueByKey(props.vitalSigns, VitalSignsKeys.INV_S_BP)
  },
  {
    label: 'SBP | DBP | MAP  (mmHg)',
    value: String(NON_INV_BP.value),
    timestamp: findLatestTimeValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_S_BP)
  },
  {
    label: 'HR (1/min)',
    value: String(HEART_RATE.value),
    timestamp: findLatestTimeValueByKey(props.vitalSigns, VitalSignsKeys.HEART_RATE)
  },
  {
    label: 'SpO2 (%)',
    value: String(SPO2.value),
    timestamp: findLatestTimeValueByKey(props.vitalSigns, VitalSignsKeys.SPO2)
  },
  {
    label: 'RR (1/min)',
    value: String(RR.value),
    timestamp: findLatestTimeValueByKey(props.vitalSigns, VitalSignsKeys.RR)
  },
  {
    label: 'Temp (°C)',
    value: String(TEMP.value),
    timestamp: findLatestTimeValueByKey(props.vitalSigns, VitalSignsKeys.TEMP)
  }
])

const labValues = computed(() => [
  {
    label: t('Lactate (mmol/L)'),
    value: findLatestValueByKey(props.laboratory, VitalSignsKeys.LAC).value.toString(),
    timestamp: findLatestTimeValueByKey(props.laboratory, VitalSignsKeys.LAC)
  },
  {
    label: t('PH'),
    value: findLatestValueByKey(props.laboratory, VitalSignsKeys.PH).value.toString(),
    timestamp: findLatestTimeValueByKey(props.laboratory, VitalSignsKeys.PH)
  },
  {
    // TODO: change to urine output when possible
    label: t('Urine Output (ml)'),
    value: findLatestValueByKey(props.laboratory, VitalSignsKeys.GFR).value.toString(),
    timestamp: findLatestTimeValueByKey(props.laboratory, VitalSignsKeys.GFR)
  },
  {
    label: t('eGFR (ml/min)'),
    value: findLatestValueByKey(props.laboratory, VitalSignsKeys.GFR).value.toString(),
    timestamp: findLatestTimeValueByKey(props.laboratory, VitalSignsKeys.GFR)
  }
])

const handleDisplayPatientDetails = (): void => {
  router.push(`/patient-details/${props.caze.id}`)
}
</script>

<style scoped>
.section-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #101828;
}

.card {
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.score-date {
  font-size: 11px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #206683;
}

.score-value {
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #17485d;
}
</style>
