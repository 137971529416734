<template>
  <VCardTitle class="d-flex justify-space-between items-center p-0">
    <div class="text-h4 p-4 pb-2">
      {{ t('Filter consultation requests') }}
    </div>
    <VBtn
      icon="close"
      variant="plain"
      class="button-small mr-2"
      size="small"
      :ripple="false"
      data-testid="btn-close"
      @click="emits('close-dialog')"
    />
  </VCardTitle>
  <VCardItem class="p-4 pb-0">
    <span class="text-subtitle-1">
      {{ t('Hospital') }}
    </span>
    <VAutocomplete
      v-model="consultationRequestStore.consultationFilterInput.hospitals"
      clear-icon="custom:clear20"
      :items="hospitalData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>
  <VCardItem class="p-4 pb-0">
    <span class="text-subtitle-1">
      {{ t('Required specialty') }}
    </span>
    <VAutocomplete
      v-model="consultationRequestStore.consultationFilterInput.targetMedicalFields"
      clear-icon="custom:clear20"
      :items="targetMedicalFieldData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>
  <VCardItem class="p-4 pb-0">
    <span class="text-subtitle-1">
      {{ t('Patient department') }}
    </span>
    <VAutocomplete
      v-model="consultationRequestStore.consultationFilterInput.departments"
      clear-icon="custom:clear20"
      :items="patientDepartmentData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>
  <VCardItem class="p-4 pb-0">
    <span class="text-subtitle-1">
      {{ t('Urgency') }}
    </span>
    <VAutocomplete
      v-model="consultationRequestStore.consultationFilterInput.urgencies"
      clear-icon="custom:clear20"
      :items="urgencyData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>
  <VCardItem class="p-4 pb-0">
    <span class="text-subtitle-1">
      {{ t('Assignee name') }}
    </span>
    <VAutocomplete
      v-model="consultationRequestStore.consultationFilterInput.assignees"
      clear-icon="custom:clear20"
      :items="assigneeData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>
  <VCardItem class="p-4 pb-0">
    <span class="text-subtitle-1">
      {{ t('Requester name') }}
    </span>
    <VAutocomplete
      v-model="consultationRequestStore.consultationFilterInput.requesters"
      clear-icon="custom:clear20"
      :items="requesterData"
      chips
      closable-chips
      multiple
      :placeholder="t('Please select...')"
    />
  </VCardItem>
</template>

<script setup lang="ts">
import type { HospitalDTO, UrgencyDTO, UserDTO } from '@/generated'
import { useConsultationRequestStore } from '@/stores/consultationRequest'
import {
  getTotalPatientCountForDepartmentName,
  getTotalPatientCountForMedicalField
} from '@/util/getPatientCounts'
import type { SelectOption } from '@/util/SelectOption'
import { translateMedicalFieldsDTO, translateUrgencyDTO } from '@/util/translations'
import { userDTOtoDisplayName } from '@/util/userDTOtoDisplayName'
import { storeToRefs } from 'pinia'
import { computed, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'

const emits = defineEmits(['close-dialog'])

const { t } = useI18n()
const consultationRequestStore = useConsultationRequestStore()
const { incomingConsultationRequests, outgoingConsultationRequests } =
  storeToRefs(consultationRequestStore)

const consultationRequests = computed(() => [
  ...incomingConsultationRequests.value,
  ...outgoingConsultationRequests.value
])

onMounted(() => {
  consultationRequestStore.resetConsultationRequestFilterInput()
})

const hospitalData = computed<SelectOption<HospitalDTO>[]>(() => {
  const uniqueHospitals: HospitalDTO[] = [
    ...new Map(
      consultationRequests.value
        .map((consultation) => consultation.case.hospital)
        .map((hospital) => [hospital.id, hospital])
    ).values()
  ]

  return uniqueHospitals.map((hospital) => ({ value: hospital, title: hospital.name }))
})

const targetMedicalFieldData = computed<SelectOption<string>[]>(() => {
  const uniqueMedicalFields = [
    ...new Set(consultationRequests.value.map((consultation) => consultation.medicalField))
  ]

  return uniqueMedicalFields.map((medicalField: string) => ({
    value: medicalField,
    title: `${translateMedicalFieldsDTO(medicalField, t)} (${getTotalPatientCountForMedicalField(
      consultationRequests.value,
      medicalField
    )})`
  }))
})

const patientDepartmentData = computed<SelectOption<string>[]>(() => {
  const caseList = consultationRequests.value.map((consultation) => consultation.case)
  const uniqueDepartmentNames = [...new Set(caseList.map((caze) => caze.department.name))]

  return uniqueDepartmentNames.map((departmentName) => {
    const count = getTotalPatientCountForDepartmentName(caseList, departmentName)

    return {
      value: departmentName,
      title: `${departmentName} (${count})`
    }
  })
})

const urgencyData = computed<SelectOption<UrgencyDTO>[]>(() => {
  const uniqueUrgencies: UrgencyDTO[] = [
    ...new Set(consultationRequests.value.map((consultation) => consultation.urgency))
  ]

  return uniqueUrgencies.map((urgency) => ({
    value: urgency,
    title: translateUrgencyDTO(urgency, t)
  }))
})

const assigneeData = computed<SelectOption<UserDTO>[]>(() => {
  const uniqueAssignees: UserDTO[] = [
    ...new Map(
      consultationRequests.value
        .map((consultation) => consultation.assignedTo)
        .filter((assignee) => !!assignee)
        .map((assignee) => [assignee.userName, assignee])
    ).values()
  ]

  return uniqueAssignees.map((assignee) => ({
    value: assignee,
    title: userDTOtoDisplayName(assignee)
  }))
})

const requesterData = computed<SelectOption<UserDTO>[]>(() => {
  const uniqueRequesters: UserDTO[] = [
    ...new Map(
      consultationRequests.value
        .map((consultation) => consultation.requestedBy)
        .map((requester) => [requester.userName, requester])
    ).values()
  ]

  return uniqueRequesters.map((requester) => ({
    value: requester,
    title: userDTOtoDisplayName(requester)
  }))
})
</script>
