/* tslint:disable */
/* eslint-disable */
/**
 * e:lmo core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.2.3
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common'
import type { RequestArgs } from './base'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base'

/**
 *
 * @export
 * @interface AssignDoctorToConsultationRequestDTO
 */
export interface AssignDoctorToConsultationRequestDTO {
  /**
   *
   * @type {string}
   * @memberof AssignDoctorToConsultationRequestDTO
   */
  userName?: string
}
/**
 *
 * @export
 * @interface CallAccessDTO
 */
export interface CallAccessDTO {
  /**
   *
   * @type {string}
   * @memberof CallAccessDTO
   */
  consultationRequestId: string
  /**
   *
   * @type {number}
   * @memberof CallAccessDTO
   */
  roomId: number
  /**
   *
   * @type {string}
   * @memberof CallAccessDTO
   */
  accessToken: string
}
/**
 *
 * @export
 * @interface CaseConsultationRequestDTO
 */
export interface CaseConsultationRequestDTO {
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  question: string
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  diagnosis: string
  /**
   *
   * @type {UrgencyDTO}
   * @memberof CaseConsultationRequestDTO
   */
  urgency: UrgencyDTO
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  id: string
  /**
   *
   * @type {UserDTO}
   * @memberof CaseConsultationRequestDTO
   */
  requestedBy: UserDTO
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  createdAt: string
  /**
   *
   * @type {ConsultationRequestStatusDTO}
   * @memberof CaseConsultationRequestDTO
   */
  status: ConsultationRequestStatusDTO
  /**
   *
   * @type {ConsultationRequestUserRelationDTO}
   * @memberof CaseConsultationRequestDTO
   */
  relationToUser: ConsultationRequestUserRelationDTO
  /**
   *
   * @type {EscalationLevelDTO}
   * @memberof CaseConsultationRequestDTO
   */
  escalationLevel: EscalationLevelDTO
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  medicalField: string
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  medicalHistory?: string
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  therapy?: string
  /**
   *
   * @type {UserDTO}
   * @memberof CaseConsultationRequestDTO
   */
  assignedTo?: UserDTO
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  assignedAt?: string
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  deletedAt?: string
  /**
   *
   * @type {string}
   * @memberof CaseConsultationRequestDTO
   */
  nextEscalationAt?: string
}

/**
 *
 * @export
 * @interface CaseCreateDTO
 */
export interface CaseCreateDTO {
  /**
   *
   * @type {string}
   * @memberof CaseCreateDTO
   */
  patientIdExt: string
  /**
   *
   * @type {string}
   * @memberof CaseCreateDTO
   */
  caseIdExt: string
  /**
   *
   * @type {WardDTO}
   * @memberof CaseCreateDTO
   */
  ward: WardDTO
  /**
   *
   * @type {DepartmentDTO}
   * @memberof CaseCreateDTO
   */
  department: DepartmentDTO
  /**
   *
   * @type {HospitalDTO}
   * @memberof CaseCreateDTO
   */
  hospital: HospitalDTO
  /**
   *
   * @type {string}
   * @memberof CaseCreateDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof CaseCreateDTO
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof CaseCreateDTO
   */
  dateOfBirth?: string
  /**
   *
   * @type {GenderDTO}
   * @memberof CaseCreateDTO
   */
  gender: GenderDTO
  /**
   *
   * @type {string}
   * @memberof CaseCreateDTO
   */
  diagnosis?: string
  /**
   *
   * @type {string}
   * @memberof CaseCreateDTO
   */
  admissionDiagnosis?: string
  /**
   *
   * @type {string}
   * @memberof CaseCreateDTO
   */
  preExistingCondition?: string
}

/**
 *
 * @export
 * @interface CaseDTO
 */
export interface CaseDTO {
  /**
   *
   * @type {string}
   * @memberof CaseDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof CaseDTO
   */
  externalId: string
  /**
   *
   * @type {PatientDTO}
   * @memberof CaseDTO
   */
  patient: PatientDTO
  /**
   *
   * @type {HospitalDTO}
   * @memberof CaseDTO
   */
  hospital: HospitalDTO
  /**
   *
   * @type {DepartmentDTO}
   * @memberof CaseDTO
   */
  department: DepartmentDTO
  /**
   *
   * @type {WardDTO}
   * @memberof CaseDTO
   */
  ward: WardDTO
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof CaseDTO
   */
  escalationDurations?: { [key: string]: string }
  /**
   *
   * @type {boolean}
   * @memberof CaseDTO
   */
  emergencyUnavailable?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CaseDTO
   */
  createdViaApi?: boolean
}
/**
 *
 * @export
 * @interface CaseUpdateDTO
 */
export interface CaseUpdateDTO {
  /**
   *
   * @type {string}
   * @memberof CaseUpdateDTO
   */
  caseId: string
  /**
   *
   * @type {WardDTO}
   * @memberof CaseUpdateDTO
   */
  ward: WardDTO
  /**
   *
   * @type {DepartmentDTO}
   * @memberof CaseUpdateDTO
   */
  department: DepartmentDTO
  /**
   *
   * @type {HospitalDTO}
   * @memberof CaseUpdateDTO
   */
  hospital: HospitalDTO
  /**
   *
   * @type {string}
   * @memberof CaseUpdateDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof CaseUpdateDTO
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof CaseUpdateDTO
   */
  dateOfBirth?: string
  /**
   *
   * @type {GenderDTO}
   * @memberof CaseUpdateDTO
   */
  gender: GenderDTO
  /**
   *
   * @type {string}
   * @memberof CaseUpdateDTO
   */
  diagnosis?: string
  /**
   *
   * @type {string}
   * @memberof CaseUpdateDTO
   */
  admissionDiagnosis?: string
  /**
   *
   * @type {string}
   * @memberof CaseUpdateDTO
   */
  preExistingCondition?: string
}

/**
 *
 * @export
 * @interface ConsultationReportPayloadDTO
 */
export interface ConsultationReportPayloadDTO {
  /**
   *
   * @type {string}
   * @memberof ConsultationReportPayloadDTO
   */
  consultationRequestId: string
  /**
   *
   * @type {string}
   * @memberof ConsultationReportPayloadDTO
   */
  medicalFieldId: string
  /**
   *
   * @type {string}
   * @memberof ConsultationReportPayloadDTO
   */
  question: string
  /**
   *
   * @type {string}
   * @memberof ConsultationReportPayloadDTO
   */
  recommendation: string
  /**
   *
   * @type {CaseDTO}
   * @memberof ConsultationReportPayloadDTO
   */
  case: CaseDTO
}
/**
 *
 * @export
 * @interface ConsultationRequestCreationDTO
 */
export interface ConsultationRequestCreationDTO {
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestCreationDTO
   */
  question: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestCreationDTO
   */
  diagnosis: string
  /**
   *
   * @type {UrgencyDTO}
   * @memberof ConsultationRequestCreationDTO
   */
  urgency: UrgencyDTO
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestCreationDTO
   */
  caseId: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestCreationDTO
   */
  medicalFieldId: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestCreationDTO
   */
  medicalHistory?: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestCreationDTO
   */
  therapy?: string
}

/**
 *
 * @export
 * @interface ConsultationRequestDTO
 */
export interface ConsultationRequestDTO {
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestDTO
   */
  question: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestDTO
   */
  diagnosis: string
  /**
   *
   * @type {UrgencyDTO}
   * @memberof ConsultationRequestDTO
   */
  urgency: UrgencyDTO
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestDTO
   */
  id: string
  /**
   *
   * @type {CaseDTO}
   * @memberof ConsultationRequestDTO
   */
  case: CaseDTO
  /**
   *
   * @type {UserDTO}
   * @memberof ConsultationRequestDTO
   */
  requestedBy: UserDTO
  /**
   *
   * @type {ConsultationRequestStatusDTO}
   * @memberof ConsultationRequestDTO
   */
  status: ConsultationRequestStatusDTO
  /**
   *
   * @type {ConsultationRequestUserRelationDTO}
   * @memberof ConsultationRequestDTO
   */
  relationToUser: ConsultationRequestUserRelationDTO
  /**
   *
   * @type {EscalationLevelDTO}
   * @memberof ConsultationRequestDTO
   */
  escalationLevel: EscalationLevelDTO
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestDTO
   */
  medicalField: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestDTO
   */
  medicalHistory?: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestDTO
   */
  therapy?: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestDTO
   */
  assignedAt?: string
  /**
   *
   * @type {UserDTO}
   * @memberof ConsultationRequestDTO
   */
  assignedTo?: UserDTO
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestDTO
   */
  nextEscalationAt?: string
}

/**
 *
 * @export
 * @interface ConsultationRequestReportDTO
 */
export interface ConsultationRequestReportDTO {
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestReportDTO
   */
  medicalHistory: string
  /**
   *
   * @type {string}
   * @memberof ConsultationRequestReportDTO
   */
  recommendation: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const ConsultationRequestStatusDTO = {
  CREATED: 'created',
  ACCEPTED: 'accepted',
  IN_PROGRESS: 'in_progress',
  REPORT_PENDING: 'report_pending',
  COMPLETED: 'completed',
  DELETED: 'deleted'
} as const

export type ConsultationRequestStatusDTO =
  (typeof ConsultationRequestStatusDTO)[keyof typeof ConsultationRequestStatusDTO]

/**
 *
 * @export
 * @enum {string}
 */

export const ConsultationRequestUserRelationDTO = {
  INCOMING: 'incoming',
  OUTGOING: 'outgoing',
  NONE: 'none'
} as const

export type ConsultationRequestUserRelationDTO =
  (typeof ConsultationRequestUserRelationDTO)[keyof typeof ConsultationRequestUserRelationDTO]

/**
 *
 * @export
 * @interface DepartmentDTO
 */
export interface DepartmentDTO {
  /**
   *
   * @type {string}
   * @memberof DepartmentDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof DepartmentDTO
   */
  name: string
}
/**
 *
 * @export
 * @interface DiagnosisDTO
 */
export interface DiagnosisDTO {
  /**
   *
   * @type {string}
   * @memberof DiagnosisDTO
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof DiagnosisDTO
   */
  description: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const Discharge = {
  DEATH: 'DEATH',
  HOME: 'HOME',
  NORMAL_WARD: 'NORMAL_WARD',
  OTHER_ICU: 'OTHER_ICU',
  INTERMEDIATE_CARE: 'INTERMEDIATE_CARE'
} as const

export type Discharge = (typeof Discharge)[keyof typeof Discharge]

/**
 *
 * @export
 * @interface DownloadFileResponse
 */
export interface DownloadFileResponse {
  /**
   *
   * @type {string}
   * @memberof DownloadFileResponse
   */
  downloadUrl: string
}
/**
 *
 * @export
 * @interface EscalationExtensionDTO
 */
export interface EscalationExtensionDTO {
  /**
   *
   * @type {string}
   * @memberof EscalationExtensionDTO
   */
  urgentDurationExtension?: string
  /**
   *
   * @type {string}
   * @memberof EscalationExtensionDTO
   */
  routineDurationExtension?: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const EscalationLevelDTO = {
  CREATED: 'created',
  FIRST_SHIFT: 'first_shift',
  BACKUP_SHIFT: 'backup_shift',
  ALL_IN_HOSPITAL: 'all_in_hospital',
  TCC: 'tcc',
  SENIOR_PHYSICIAN: 'senior_physician',
  EMERGENCY_TCC: 'emergency_tcc',
  EMERGENCY_HOSPITAL: 'emergency_hospital',
  EMERGENCY_SENIOR_PHYSICIAN: 'emergency_senior_physician'
} as const

export type EscalationLevelDTO = (typeof EscalationLevelDTO)[keyof typeof EscalationLevelDTO]

/**
 *
 * @export
 * @interface EscalationStepDTO
 */
export interface EscalationStepDTO {
  /**
   *
   * @type {EscalationLevelDTO}
   * @memberof EscalationStepDTO
   */
  escalationLevel: EscalationLevelDTO
  /**
   *
   * @type {string}
   * @memberof EscalationStepDTO
   */
  escalationDuration?: string
}

/**
 *
 * @export
 * @interface FileDTO
 */
export interface FileDTO {
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  name: string
  /**
   *
   * @type {FileKindEnum}
   * @memberof FileDTO
   */
  kind: FileKindEnum
  /**
   *
   * @type {string}
   * @memberof FileDTO
   */
  uploadedAt: string
  /**
   *
   * @type {UserDTO}
   * @memberof FileDTO
   */
  uploadedBy: UserDTO
}

/**
 *
 * @export
 * @enum {string}
 */

export const FileKindEnum = {
  MEDICAL_REPORT: 'MEDICAL_REPORT',
  CONSULTATION_REPORT: 'CONSULTATION_REPORT',
  DOCUMENTS: 'DOCUMENTS'
} as const

export type FileKindEnum = (typeof FileKindEnum)[keyof typeof FileKindEnum]

/**
 *
 * @export
 * @interface FileUploadRequestDTO
 */
export interface FileUploadRequestDTO {
  /**
   *
   * @type {string}
   * @memberof FileUploadRequestDTO
   */
  name: string
  /**
   *
   * @type {FileKindEnum}
   * @memberof FileUploadRequestDTO
   */
  kind: FileKindEnum
}

/**
 *
 * @export
 * @enum {string}
 */

export const GenderDTO = {
  MALE: 'male',
  FEMALE: 'female',
  VARIOUS: 'various',
  NOT_DEFINED: 'not_defined'
} as const

export type GenderDTO = (typeof GenderDTO)[keyof typeof GenderDTO]

/**
 *
 * @export
 * @interface GetAssignedHospitalsResponse
 */
export interface GetAssignedHospitalsResponse {
  /**
   *
   * @type {Array<HospitalDTO>}
   * @memberof GetAssignedHospitalsResponse
   */
  hospitals?: Array<HospitalDTO>
  /**
   *
   * @type {Array<WardDTO>}
   * @memberof GetAssignedHospitalsResponse
   */
  wards?: Array<WardDTO>
}
/**
 *
 * @export
 * @interface GetCaseResponse
 */
export interface GetCaseResponse {
  /**
   *
   * @type {CaseDTO}
   * @memberof GetCaseResponse
   */
  case: CaseDTO
  /**
   *
   * @type {PatientDetailsDTO}
   * @memberof GetCaseResponse
   */
  patientDetails: PatientDetailsDTO
  /**
   *
   * @type {Array<CaseConsultationRequestDTO>}
   * @memberof GetCaseResponse
   */
  consultationRequests: Array<CaseConsultationRequestDTO>
  /**
   *
   * @type {Array<FileDTO>}
   * @memberof GetCaseResponse
   */
  files: Array<FileDTO>
  /**
   *
   * @type {DiagnosisDTO}
   * @memberof GetCaseResponse
   */
  diagnosis?: DiagnosisDTO
  /**
   *
   * @type {DiagnosisDTO}
   * @memberof GetCaseResponse
   */
  admissionDiagnosis?: DiagnosisDTO
  /**
   *
   * @type {Array<TherapyLimitationDTO>}
   * @memberof GetCaseResponse
   */
  therapyLimitations: Array<TherapyLimitationDTO>
  /**
   *
   * @type {Array<string>}
   * @memberof GetCaseResponse
   */
  preExistingConditions: Array<string>
}
/**
 *
 * @export
 * @interface GetCasesResponse
 */
export interface GetCasesResponse {
  /**
   *
   * @type {Array<CaseDTO>}
   * @memberof GetCasesResponse
   */
  cases: Array<CaseDTO>
}
/**
 *
 * @export
 * @interface GetConsultationRequestReportResponse
 */
export interface GetConsultationRequestReportResponse {
  /**
   *
   * @type {ConsultationRequestReportDTO}
   * @memberof GetConsultationRequestReportResponse
   */
  report?: ConsultationRequestReportDTO
}
/**
 *
 * @export
 * @interface GetConsultationRequestShareCodeResponse
 */
export interface GetConsultationRequestShareCodeResponse {
  /**
   *
   * @type {string}
   * @memberof GetConsultationRequestShareCodeResponse
   */
  consultationShareCode: string
}
/**
 *
 * @export
 * @interface GetConsultationRequestsResponse
 */
export interface GetConsultationRequestsResponse {
  /**
   *
   * @type {Array<ConsultationRequestDTO>}
   * @memberof GetConsultationRequestsResponse
   */
  consultationRequests: Array<ConsultationRequestDTO>
}
/**
 *
 * @export
 * @interface GetMedicalFieldsResponse
 */
export interface GetMedicalFieldsResponse {
  /**
   *
   * @type {Array<MedicalFieldDTO>}
   * @memberof GetMedicalFieldsResponse
   */
  medicalFields: Array<MedicalFieldDTO>
}
/**
 *
 * @export
 * @interface GetNotificationsResponseDTO
 */
export interface GetNotificationsResponseDTO {
  /**
   *
   * @type {Array<NotificationDTO>}
   * @memberof GetNotificationsResponseDTO
   */
  notifications: Array<NotificationDTO>
}
/**
 *
 * @export
 * @interface GetUserSettingsResponse
 */
export interface GetUserSettingsResponse {
  /**
   *
   * @type {UserSettingsDTO}
   * @memberof GetUserSettingsResponse
   */
  userSettings: UserSettingsDTO
  /**
   *
   * @type {SystemSettingsDTO}
   * @memberof GetUserSettingsResponse
   */
  systemSettings: SystemSettingsDTO
}
/**
 *
 * @export
 * @interface GetVitalSignsResponse
 */
export interface GetVitalSignsResponse {
  /**
   *
   * @type {Array<VitalSignsWithTypeDTO>}
   * @memberof GetVitalSignsResponse
   */
  vitalSigns: Array<VitalSignsWithTypeDTO>
  /**
   *
   * @type {any}
   * @memberof GetVitalSignsResponse
   */
  laboratory: any
}
/**
 *
 * @export
 * @interface HospitalDTO
 */
export interface HospitalDTO {
  /**
   *
   * @type {string}
   * @memberof HospitalDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof HospitalDTO
   */
  name: string
}
/**
 *
 * @export
 * @interface HospitalIdOrNull
 */
export interface HospitalIdOrNull {
  /**
   *
   * @type {string}
   * @memberof HospitalIdOrNull
   */
  value?: string
}
/**
 *
 * @export
 * @interface ListUsersResponse
 */
export interface ListUsersResponse {
  /**
   *
   * @type {Array<UserDetailsDTO>}
   * @memberof ListUsersResponse
   */
  users: Array<UserDetailsDTO>
}
/**
 *
 * @export
 * @interface LoginDTO
 */
export interface LoginDTO {
  /**
   *
   * @type {string}
   * @memberof LoginDTO
   */
  notificationToken?: string
}
/**
 *
 * @export
 * @interface LogoutDTO
 */
export interface LogoutDTO {
  /**
   *
   * @type {string}
   * @memberof LogoutDTO
   */
  notificationToken?: string
}
/**
 *
 * @export
 * @interface MedicalFieldDTO
 */
export interface MedicalFieldDTO {
  /**
   *
   * @type {string}
   * @memberof MedicalFieldDTO
   */
  id: string
}
/**
 *
 * @export
 * @interface NotificationDTO
 */
export interface NotificationDTO {
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  userName: string
  /**
   *
   * @type {string}
   * @memberof NotificationDTO
   */
  createdAt: string
  /**
   *
   * @type {NotificationTypeDTO}
   * @memberof NotificationDTO
   */
  type: NotificationTypeDTO
  /**
   *
   * @type {NotificationStatusDTO}
   * @memberof NotificationDTO
   */
  status: NotificationStatusDTO
  /**
   *
   * @type {ConsultationReportPayloadDTO}
   * @memberof NotificationDTO
   */
  consultationReportPayload?: ConsultationReportPayloadDTO
}

/**
 *
 * @export
 * @interface NotificationSettingsDTO
 */
export interface NotificationSettingsDTO {
  /**
   *
   * @type {string}
   * @memberof NotificationSettingsDTO
   */
  apiKey: string
  /**
   *
   * @type {string}
   * @memberof NotificationSettingsDTO
   */
  authDomain: string
  /**
   *
   * @type {string}
   * @memberof NotificationSettingsDTO
   */
  projectId: string
  /**
   *
   * @type {string}
   * @memberof NotificationSettingsDTO
   */
  messagingSenderId: string
  /**
   *
   * @type {string}
   * @memberof NotificationSettingsDTO
   */
  appId: string
  /**
   *
   * @type {string}
   * @memberof NotificationSettingsDTO
   */
  vapidKey: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const NotificationStatusDTO = {
  UNREAD: 'unread',
  READ: 'read'
} as const

export type NotificationStatusDTO =
  (typeof NotificationStatusDTO)[keyof typeof NotificationStatusDTO]

/**
 *
 * @export
 * @enum {string}
 */

export const NotificationTypeDTO = {
  CONSULTATION_REPORT_CREATED: 'consultation_report_created'
} as const

export type NotificationTypeDTO = (typeof NotificationTypeDTO)[keyof typeof NotificationTypeDTO]

/**
 *
 * @export
 * @interface PatientDTO
 */
export interface PatientDTO {
  /**
   *
   * @type {string}
   * @memberof PatientDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof PatientDTO
   */
  lastName: string
  /**
   *
   * @type {string}
   * @memberof PatientDTO
   */
  dateOfBirth?: string
  /**
   *
   * @type {GenderDTO}
   * @memberof PatientDTO
   */
  gender: GenderDTO
}

/**
 *
 * @export
 * @interface PatientDetailsDTO
 */
export interface PatientDetailsDTO {
  /**
   *
   * @type {string}
   * @memberof PatientDetailsDTO
   */
  externalId: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const SampleLocationDTO = {
  ARTERIAL: 'arterial',
  VENOUS: 'venous',
  CAPILLARY: 'capillary'
} as const

export type SampleLocationDTO = (typeof SampleLocationDTO)[keyof typeof SampleLocationDTO]

/**
 *
 * @export
 * @enum {string}
 */

export const SearchUsersFilterDTO = {
  HANDOVER: 'handover'
} as const

export type SearchUsersFilterDTO = (typeof SearchUsersFilterDTO)[keyof typeof SearchUsersFilterDTO]

/**
 *
 * @export
 * @interface SearchUsersResponse
 */
export interface SearchUsersResponse {
  /**
   *
   * @type {Array<UserDTO>}
   * @memberof SearchUsersResponse
   */
  users: Array<UserDTO>
}
/**
 *
 * @export
 * @interface Shift
 */
export interface Shift {
  /**
   *
   * @type {UserName}
   * @memberof Shift
   */
  userName: UserName
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  type: ShiftTypeEnum
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  begin?: string
  /**
   *
   * @type {string}
   * @memberof Shift
   */
  end?: string
}

export const ShiftTypeEnum = {
  FIRST_SHIFT: 'FIRST_SHIFT',
  BACKUP_SHIFT: 'BACKUP_SHIFT',
  NO_SHIFT: 'NO_SHIFT',
  GOD_MODE: 'GOD_MODE',
  CHIEF_PHYSICIAN: 'CHIEF_PHYSICIAN'
} as const

export type ShiftTypeEnum = (typeof ShiftTypeEnum)[keyof typeof ShiftTypeEnum]

/**
 *
 * @export
 * @interface ShiftDTO
 */
export interface ShiftDTO {
  /**
   *
   * @type {string}
   * @memberof ShiftDTO
   */
  userName: string
  /**
   *
   * @type {ShiftTypeDTO}
   * @memberof ShiftDTO
   */
  type: ShiftTypeDTO
  /**
   *
   * @type {string}
   * @memberof ShiftDTO
   */
  end?: string
}

/**
 *
 * @export
 * @interface ShiftExtendDTO
 */
export interface ShiftExtendDTO {
  /**
   *
   * @type {string}
   * @memberof ShiftExtendDTO
   */
  endTime: string
}
/**
 *
 * @export
 * @interface ShiftHandoverDTO
 */
export interface ShiftHandoverDTO {
  /**
   *
   * @type {string}
   * @memberof ShiftHandoverDTO
   */
  to?: string
  /**
   *
   * @type {Array<string>}
   * @memberof ShiftHandoverDTO
   */
  handoverConsultationRequests?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof ShiftHandoverDTO
   */
  deleteConsultationRequests?: Array<string>
}
/**
 *
 * @export
 * @enum {string}
 */

export const ShiftTypeDTO = {
  FIRST_SHIFT: 'firstShift',
  BACKUP_SHIFT: 'backupShift',
  NO_SHIFT: 'noShift',
  GOD_MODE: 'godMode',
  CHIEF_PHYSICIAN: 'chiefPhysician'
} as const

export type ShiftTypeDTO = (typeof ShiftTypeDTO)[keyof typeof ShiftTypeDTO]

/**
 *
 * @export
 * @interface SseEmitter
 */
export interface SseEmitter {
  /**
   *
   * @type {number}
   * @memberof SseEmitter
   */
  timeout?: number
}
/**
 *
 * @export
 * @interface StringArrayValueDTO
 */
export interface StringArrayValueDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof StringArrayValueDTO
   */
  value?: Array<string>
}
/**
 *
 * @export
 * @interface StringValueDTO
 */
export interface StringValueDTO {
  /**
   *
   * @type {string}
   * @memberof StringValueDTO
   */
  value?: string
}
/**
 *
 * @export
 * @interface SystemSettingsDTO
 */
export interface SystemSettingsDTO {
  /**
   *
   * @type {NotificationSettingsDTO}
   * @memberof SystemSettingsDTO
   */
  notification: NotificationSettingsDTO
}
/**
 *
 * @export
 * @interface TherapyLimitationDTO
 */
export interface TherapyLimitationDTO {
  /**
   *
   * @type {string}
   * @memberof TherapyLimitationDTO
   */
  measurementDate: string
  /**
   *
   * @type {string}
   * @memberof TherapyLimitationDTO
   */
  limitation: string
}
/**
 *
 * @export
 * @interface UUIDArrayValueDTO
 */
export interface UUIDArrayValueDTO {
  /**
   *
   * @type {Array<string>}
   * @memberof UUIDArrayValueDTO
   */
  value?: Array<string>
}
/**
 *
 * @export
 * @interface UUIDValueDTO
 */
export interface UUIDValueDTO {
  /**
   *
   * @type {string}
   * @memberof UUIDValueDTO
   */
  value?: string
}
/**
 *
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
  /**
   *
   * @type {string}
   * @memberof UploadFileResponse
   */
  uploadUrl: string
  /**
   *
   * @type {string}
   * @memberof UploadFileResponse
   */
  fileId: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const UrgencyDTO = {
  EMERGENCY: 'emergency',
  URGENT: 'urgent',
  ROUTINE: 'routine',
  FOLLOW_UP: 'followUp'
} as const

export type UrgencyDTO = (typeof UrgencyDTO)[keyof typeof UrgencyDTO]

/**
 *
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  userName: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  title?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  firstName?: string
  /**
   *
   * @type {string}
   * @memberof UserDTO
   */
  lastName?: string
}
/**
 *
 * @export
 * @interface UserDetailsDTO
 */
export interface UserDetailsDTO {
  /**
   *
   * @type {UserDTO}
   * @memberof UserDetailsDTO
   */
  user: UserDTO
  /**
   *
   * @type {Array<DepartmentDTO>}
   * @memberof UserDetailsDTO
   */
  departments: Array<DepartmentDTO>
  /**
   *
   * @type {Array<HospitalDTO>}
   * @memberof UserDetailsDTO
   */
  hospitals: Array<HospitalDTO>
  /**
   *
   * @type {Array<string>}
   * @memberof UserDetailsDTO
   */
  userRoles: Array<string>
  /**
   *
   * @type {Array<WardDTO>}
   * @memberof UserDetailsDTO
   */
  wards: Array<WardDTO>
  /**
   *
   * @type {string}
   * @memberof UserDetailsDTO
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof UserDetailsDTO
   */
  mobile?: string
  /**
   *
   * @type {string}
   * @memberof UserDetailsDTO
   */
  phone?: string
  /**
   *
   * @type {string}
   * @memberof UserDetailsDTO
   */
  password?: string
}
/**
 *
 * @export
 * @interface UserName
 */
export interface UserName {
  /**
   *
   * @type {string}
   * @memberof UserName
   */
  value: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const UserRightsEnum = {
  HOSPITAL_IT: 'hospital-it',
  HOSPITAL_PHYSICIAN: 'hospital-physician',
  TCC_IT: 'tcc-it',
  TCC_PHYSICIAN: 'tcc-physician',
  GOD_MODE: 'god-mode',
  CHIEF_PHYSICIAN: 'chief-physician'
} as const

export type UserRightsEnum = (typeof UserRightsEnum)[keyof typeof UserRightsEnum]

/**
 *
 * @export
 * @interface UserSettings
 */
export interface UserSettings {
  /**
   *
   * @type {UserName}
   * @memberof UserSettings
   */
  userName: UserName
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  language?: string
  /**
   *
   * @type {Shift}
   * @memberof UserSettings
   */
  shift?: Shift
  /**
   *
   * @type {Array<string>}
   * @memberof UserSettings
   */
  hospitals?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UserSettings
   */
  wards?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof UserSettings
   */
  medicalFields?: Array<string>
  /**
   *
   * @type {string}
   * @memberof UserSettings
   */
  lastAssignmentAt?: string
  /**
   *
   * @type {HospitalIdOrNull}
   * @memberof UserSettings
   */
  seniorPhysicianAtHospital?: HospitalIdOrNull
  /**
   *
   * @type {boolean}
   * @memberof UserSettings
   */
  isEnabled: boolean
  /**
   *
   * @type {Array<UserRightsEnum>}
   * @memberof UserSettings
   */
  userRights: Array<UserRightsEnum>
  /**
   *
   * @type {boolean}
   * @memberof UserSettings
   */
  enabled?: boolean
  /**
   *
   * @type {Array<string>}
   * @memberof UserSettings
   */
  allHospitals: Array<string>
}
/**
 *
 * @export
 * @interface UserSettingsDTO
 */
export interface UserSettingsDTO {
  /**
   *
   * @type {string}
   * @memberof UserSettingsDTO
   */
  userName: string
  /**
   *
   * @type {string}
   * @memberof UserSettingsDTO
   */
  language?: string
  /**
   *
   * @type {ShiftDTO}
   * @memberof UserSettingsDTO
   */
  shift?: ShiftDTO
  /**
   *
   * @type {Array<string>}
   * @memberof UserSettingsDTO
   */
  medicalFields?: Array<string>
  /**
   *
   * @type {Array<UserRightsEnum>}
   * @memberof UserSettingsDTO
   */
  userRights?: Array<UserRightsEnum>
}
/**
 *
 * @export
 * @interface UserSettingsUpdateDTO
 */
export interface UserSettingsUpdateDTO {
  /**
   *
   * @type {StringValueDTO}
   * @memberof UserSettingsUpdateDTO
   */
  language?: StringValueDTO
  /**
   *
   * @type {UUIDArrayValueDTO}
   * @memberof UserSettingsUpdateDTO
   */
  hospitals?: UUIDArrayValueDTO
  /**
   *
   * @type {UUIDArrayValueDTO}
   * @memberof UserSettingsUpdateDTO
   */
  wards?: UUIDArrayValueDTO
  /**
   *
   * @type {StringArrayValueDTO}
   * @memberof UserSettingsUpdateDTO
   */
  medicalFields?: StringArrayValueDTO
  /**
   *
   * @type {UUIDValueDTO}
   * @memberof UserSettingsUpdateDTO
   */
  seniorPhysicianAtHospital?: UUIDValueDTO
}
/**
 *
 * @export
 * @interface VitalSignItemDTO
 */
export interface VitalSignItemDTO {
  /**
   *
   * @type {string}
   * @memberof VitalSignItemDTO
   */
  measurementDate: string
  /**
   *
   * @type {number}
   * @memberof VitalSignItemDTO
   */
  numericValue: number
  /**
   *
   * @type {string}
   * @memberof VitalSignItemDTO
   */
  unit?: string
  /**
   *
   * @type {SampleLocationDTO}
   * @memberof VitalSignItemDTO
   */
  sampleLocation?: SampleLocationDTO
}

/**
 *
 * @export
 * @interface VitalSignTypeDTO
 */
export interface VitalSignTypeDTO {
  /**
   *
   * @type {string}
   * @memberof VitalSignTypeDTO
   */
  typeKey: string
  /**
   *
   * @type {string}
   * @memberof VitalSignTypeDTO
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof VitalSignTypeDTO
   */
  unit?: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const VitalSignTypeKey = {
  LAC: 'LAC',
  PH: 'PH'
} as const

export type VitalSignTypeKey = (typeof VitalSignTypeKey)[keyof typeof VitalSignTypeKey]

/**
 *
 * @export
 * @interface VitalSignsFiltersDTO
 */
export interface VitalSignsFiltersDTO {
  /**
   *
   * @type {Array<object>}
   * @memberof VitalSignsFiltersDTO
   */
  vitalSigns?: Array<object>
}
/**
 *
 * @export
 * @enum {string}
 */

export const VitalSignsHistoricalPeriod = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  ALL_TIME: 'ALL_TIME'
} as const

export type VitalSignsHistoricalPeriod =
  (typeof VitalSignsHistoricalPeriod)[keyof typeof VitalSignsHistoricalPeriod]

/**
 *
 * @export
 * @interface VitalSignsWithTypeDTO
 */
export interface VitalSignsWithTypeDTO {
  /**
   *
   * @type {VitalSignTypeDTO}
   * @memberof VitalSignsWithTypeDTO
   */
  vitalSignType: VitalSignTypeDTO
  /**
   *
   * @type {Array<VitalSignItemDTO>}
   * @memberof VitalSignsWithTypeDTO
   */
  items: Array<VitalSignItemDTO>
}
/**
 *
 * @export
 * @interface WardDTO
 */
export interface WardDTO {
  /**
   *
   * @type {string}
   * @memberof WardDTO
   */
  id: string
  /**
   *
   * @type {string}
   * @memberof WardDTO
   */
  name: string
}

/**
 * AdminCaseApi - axios parameter creator
 * @export
 */
export const AdminCaseApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Archives a Case object
     * @param {string} caseId
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveCase: async (
      caseId: string,
      body: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'caseId' is not null or undefined
      assertParamExists('archiveCase', 'caseId', caseId)
      // verify required parameter 'body' is not null or undefined
      assertParamExists('archiveCase', 'body', body)
      const localVarPath = `/admin/case/{caseId}/archive`.replace(
        `{${'caseId'}}`,
        encodeURIComponent(String(caseId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Creates a Case object
     * @param {CaseCreateDTO} caseCreateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCase: async (
      caseCreateDTO: CaseCreateDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'caseCreateDTO' is not null or undefined
      assertParamExists('createCase', 'caseCreateDTO', caseCreateDTO)
      const localVarPath = `/admin/case`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        caseCreateDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Creates multiple Case objects with payload provided in a CSV file
     * @param {File} [file] CSV file with cases data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importCasesFromCSV: async (
      file?: File,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/case/import`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (file !== undefined) {
        localVarFormParams.append('file', file as any)
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Updates a Case object
     * @param {string} caseId
     * @param {CaseUpdateDTO} caseUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCase: async (
      caseId: string,
      caseUpdateDTO: CaseUpdateDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'caseId' is not null or undefined
      assertParamExists('updateCase', 'caseId', caseId)
      // verify required parameter 'caseUpdateDTO' is not null or undefined
      assertParamExists('updateCase', 'caseUpdateDTO', caseUpdateDTO)
      const localVarPath = `/admin/case/{caseId}`.replace(
        `{${'caseId'}}`,
        encodeURIComponent(String(caseId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        caseUpdateDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AdminCaseApi - functional programming interface
 * @export
 */
export const AdminCaseApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminCaseApiAxiosParamCreator(configuration)
  return {
    /**
     * Archives a Case object
     * @param {string} caseId
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveCase(
      caseId: string,
      body: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archiveCase(caseId, body, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminCaseApi.archiveCase']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Creates a Case object
     * @param {CaseCreateDTO} caseCreateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCase(
      caseCreateDTO: CaseCreateDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCase(caseCreateDTO, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminCaseApi.createCase']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Creates multiple Case objects with payload provided in a CSV file
     * @param {File} [file] CSV file with cases data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importCasesFromCSV(
      file?: File,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importCasesFromCSV(file, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminCaseApi.importCasesFromCSV']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Updates a Case object
     * @param {string} caseId
     * @param {CaseUpdateDTO} caseUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateCase(
      caseId: string,
      caseUpdateDTO: CaseUpdateDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateCase(
        caseId,
        caseUpdateDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminCaseApi.updateCase']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * AdminCaseApi - factory interface
 * @export
 */
export const AdminCaseApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminCaseApiFp(configuration)
  return {
    /**
     * Archives a Case object
     * @param {string} caseId
     * @param {string} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveCase(caseId: string, body: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .archiveCase(caseId, body, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Creates a Case object
     * @param {CaseCreateDTO} caseCreateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCase(caseCreateDTO: CaseCreateDTO, options?: any): AxiosPromise<string> {
      return localVarFp
        .createCase(caseCreateDTO, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Creates multiple Case objects with payload provided in a CSV file
     * @param {File} [file] CSV file with cases data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importCasesFromCSV(file?: File, options?: any): AxiosPromise<void> {
      return localVarFp
        .importCasesFromCSV(file, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Updates a Case object
     * @param {string} caseId
     * @param {CaseUpdateDTO} caseUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateCase(caseId: string, caseUpdateDTO: CaseUpdateDTO, options?: any): AxiosPromise<void> {
      return localVarFp
        .updateCase(caseId, caseUpdateDTO, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * AdminCaseApi - object-oriented interface
 * @export
 * @class AdminCaseApi
 * @extends {BaseAPI}
 */
export class AdminCaseApi extends BaseAPI {
  /**
   * Archives a Case object
   * @param {string} caseId
   * @param {string} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCaseApi
   */
  public archiveCase(caseId: string, body: string, options?: RawAxiosRequestConfig) {
    return AdminCaseApiFp(this.configuration)
      .archiveCase(caseId, body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Creates a Case object
   * @param {CaseCreateDTO} caseCreateDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCaseApi
   */
  public createCase(caseCreateDTO: CaseCreateDTO, options?: RawAxiosRequestConfig) {
    return AdminCaseApiFp(this.configuration)
      .createCase(caseCreateDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Creates multiple Case objects with payload provided in a CSV file
   * @param {File} [file] CSV file with cases data
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCaseApi
   */
  public importCasesFromCSV(file?: File, options?: RawAxiosRequestConfig) {
    return AdminCaseApiFp(this.configuration)
      .importCasesFromCSV(file, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Updates a Case object
   * @param {string} caseId
   * @param {CaseUpdateDTO} caseUpdateDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminCaseApi
   */
  public updateCase(caseId: string, caseUpdateDTO: CaseUpdateDTO, options?: RawAxiosRequestConfig) {
    return AdminCaseApiFp(this.configuration)
      .updateCase(caseId, caseUpdateDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * AdminUserApi - axios parameter creator
 * @export
 */
export const AdminUserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Archives a User object
     * @param {string} encodedUserName Base64 encoded username of the user that should be archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveUser: async (
      encodedUserName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('archiveUser', 'encodedUserName', encodedUserName)
      const localVarPath = `/admin/user/{encodedUserName}/archive`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Creates a User object
     * @param {UserDetailsDTO} userDetailsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser: async (
      userDetailsDTO: UserDetailsDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userDetailsDTO' is not null or undefined
      assertParamExists('createUser', 'userDetailsDTO', userDetailsDTO)
      const localVarPath = `/admin/user`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userDetailsDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a list of all User objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/admin/user`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Enables or disables a User
     * @param {string} encodedUserName Base64 encoded username of the user that should be enabled/disabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toggleUserEnabled: async (
      encodedUserName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('toggleUserEnabled', 'encodedUserName', encodedUserName)
      const localVarPath = `/admin/user/{encodedUserName}/toggle-enabled`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Updates a User object
     * @param {string} encodedUserName Base64 encoded username of the user that should be updated
     * @param {UserDetailsDTO} userDetailsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser: async (
      encodedUserName: string,
      userDetailsDTO: UserDetailsDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('updateUser', 'encodedUserName', encodedUserName)
      // verify required parameter 'userDetailsDTO' is not null or undefined
      assertParamExists('updateUser', 'userDetailsDTO', userDetailsDTO)
      const localVarPath = `/admin/user/{encodedUserName}`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userDetailsDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * AdminUserApi - functional programming interface
 * @export
 */
export const AdminUserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdminUserApiAxiosParamCreator(configuration)
  return {
    /**
     * Archives a User object
     * @param {string} encodedUserName Base64 encoded username of the user that should be archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async archiveUser(
      encodedUserName: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.archiveUser(
        encodedUserName,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminUserApi.archiveUser']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Creates a User object
     * @param {UserDetailsDTO} userDetailsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createUser(
      userDetailsDTO: UserDetailsDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(userDetailsDTO, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminUserApi.createUser']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a list of all User objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listUsers(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListUsersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listUsers(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminUserApi.listUsers']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Enables or disables a User
     * @param {string} encodedUserName Base64 encoded username of the user that should be enabled/disabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async toggleUserEnabled(
      encodedUserName: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.toggleUserEnabled(
        encodedUserName,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminUserApi.toggleUserEnabled']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Updates a User object
     * @param {string} encodedUserName Base64 encoded username of the user that should be updated
     * @param {UserDetailsDTO} userDetailsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateUser(
      encodedUserName: string,
      userDetailsDTO: UserDetailsDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(
        encodedUserName,
        userDetailsDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['AdminUserApi.updateUser']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * AdminUserApi - factory interface
 * @export
 */
export const AdminUserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AdminUserApiFp(configuration)
  return {
    /**
     * Archives a User object
     * @param {string} encodedUserName Base64 encoded username of the user that should be archived
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    archiveUser(encodedUserName: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .archiveUser(encodedUserName, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Creates a User object
     * @param {UserDetailsDTO} userDetailsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createUser(userDetailsDTO: UserDetailsDTO, options?: any): AxiosPromise<void> {
      return localVarFp
        .createUser(userDetailsDTO, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a list of all User objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listUsers(options?: any): AxiosPromise<ListUsersResponse> {
      return localVarFp.listUsers(options).then((request) => request(axios, basePath))
    },
    /**
     * Enables or disables a User
     * @param {string} encodedUserName Base64 encoded username of the user that should be enabled/disabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    toggleUserEnabled(encodedUserName: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .toggleUserEnabled(encodedUserName, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Updates a User object
     * @param {string} encodedUserName Base64 encoded username of the user that should be updated
     * @param {UserDetailsDTO} userDetailsDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateUser(
      encodedUserName: string,
      userDetailsDTO: UserDetailsDTO,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateUser(encodedUserName, userDetailsDTO, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * AdminUserApi - object-oriented interface
 * @export
 * @class AdminUserApi
 * @extends {BaseAPI}
 */
export class AdminUserApi extends BaseAPI {
  /**
   * Archives a User object
   * @param {string} encodedUserName Base64 encoded username of the user that should be archived
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminUserApi
   */
  public archiveUser(encodedUserName: string, options?: RawAxiosRequestConfig) {
    return AdminUserApiFp(this.configuration)
      .archiveUser(encodedUserName, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Creates a User object
   * @param {UserDetailsDTO} userDetailsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminUserApi
   */
  public createUser(userDetailsDTO: UserDetailsDTO, options?: RawAxiosRequestConfig) {
    return AdminUserApiFp(this.configuration)
      .createUser(userDetailsDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a list of all User objects
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminUserApi
   */
  public listUsers(options?: RawAxiosRequestConfig) {
    return AdminUserApiFp(this.configuration)
      .listUsers(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Enables or disables a User
   * @param {string} encodedUserName Base64 encoded username of the user that should be enabled/disabled
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminUserApi
   */
  public toggleUserEnabled(encodedUserName: string, options?: RawAxiosRequestConfig) {
    return AdminUserApiFp(this.configuration)
      .toggleUserEnabled(encodedUserName, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Updates a User object
   * @param {string} encodedUserName Base64 encoded username of the user that should be updated
   * @param {UserDetailsDTO} userDetailsDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdminUserApi
   */
  public updateUser(
    encodedUserName: string,
    userDetailsDTO: UserDetailsDTO,
    options?: RawAxiosRequestConfig
  ) {
    return AdminUserApiFp(this.configuration)
      .updateUser(encodedUserName, userDetailsDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * CaseApi - axios parameter creator
 * @export
 */
export const CaseApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates an emergency for a Case.
     * @param {string} caseId ID of the case where the emergency occurred
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEmergency: async (
      caseId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'caseId' is not null or undefined
      assertParamExists('createEmergency', 'caseId', caseId)
      const localVarPath = `/case/{caseId}/emergency`.replace(
        `{${'caseId'}}`,
        encodeURIComponent(String(caseId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a Case object.
     * @param {string} caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCase: async (caseId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'caseId' is not null or undefined
      assertParamExists('getCase', 'caseId', caseId)
      const localVarPath = `/case/{caseId}`.replace(
        `{${'caseId'}}`,
        encodeURIComponent(String(caseId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves as list of Case objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCases: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/case`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * CaseApi - functional programming interface
 * @export
 */
export const CaseApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CaseApiAxiosParamCreator(configuration)
  return {
    /**
     * Creates an emergency for a Case.
     * @param {string} caseId ID of the case where the emergency occurred
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createEmergency(
      caseId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createEmergency(caseId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['CaseApi.createEmergency']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a Case object.
     * @param {string} caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCase(
      caseId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCaseResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCase(caseId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['CaseApi.getCase']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves as list of Case objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCases(
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCasesResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCases(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['CaseApi.getCases']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * CaseApi - factory interface
 * @export
 */
export const CaseApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CaseApiFp(configuration)
  return {
    /**
     * Creates an emergency for a Case.
     * @param {string} caseId ID of the case where the emergency occurred
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createEmergency(caseId: string, options?: any): AxiosPromise<void> {
      return localVarFp.createEmergency(caseId, options).then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a Case object.
     * @param {string} caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCase(caseId: string, options?: any): AxiosPromise<GetCaseResponse> {
      return localVarFp.getCase(caseId, options).then((request) => request(axios, basePath))
    },
    /**
     * Retrieves as list of Case objects.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCases(options?: any): AxiosPromise<GetCasesResponse> {
      return localVarFp.getCases(options).then((request) => request(axios, basePath))
    }
  }
}

/**
 * CaseApi - object-oriented interface
 * @export
 * @class CaseApi
 * @extends {BaseAPI}
 */
export class CaseApi extends BaseAPI {
  /**
   * Creates an emergency for a Case.
   * @param {string} caseId ID of the case where the emergency occurred
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CaseApi
   */
  public createEmergency(caseId: string, options?: RawAxiosRequestConfig) {
    return CaseApiFp(this.configuration)
      .createEmergency(caseId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a Case object.
   * @param {string} caseId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CaseApi
   */
  public getCase(caseId: string, options?: RawAxiosRequestConfig) {
    return CaseApiFp(this.configuration)
      .getCase(caseId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves as list of Case objects.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CaseApi
   */
  public getCases(options?: RawAxiosRequestConfig) {
    return CaseApiFp(this.configuration)
      .getCases(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ConsultationRequestApi - axios parameter creator
 * @export
 */
export const ConsultationRequestApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Accepts a Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptConsultationRequest: async (
      consultationRequestId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists('acceptConsultationRequest', 'consultationRequestId', consultationRequestId)
      const localVarPath = `/consultation-request/{consultationRequestId}/accept`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Creates a Consultation Request object
     * @param {ConsultationRequestCreationDTO} consultationRequestCreationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConsultationRequest: async (
      consultationRequestCreationDTO: ConsultationRequestCreationDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestCreationDTO' is not null or undefined
      assertParamExists(
        'createConsultationRequest',
        'consultationRequestCreationDTO',
        consultationRequestCreationDTO
      )
      const localVarPath = `/consultation-request`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        consultationRequestCreationDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Declines a consultation request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    declineConsultationRequest: async (
      consultationRequestId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists(
        'declineConsultationRequest',
        'consultationRequestId',
        consultationRequestId
      )
      const localVarPath = `/consultation-request/{consultationRequestId}/decline`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Deletes a Consultation Request object
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteConsultationRequest: async (
      consultationRequestId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists('deleteConsultationRequest', 'consultationRequestId', consultationRequestId)
      const localVarPath = `/consultation-request/{consultationRequestId}`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Ends a Call for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endCall: async (
      consultationRequestId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists('endCall', 'consultationRequestId', consultationRequestId)
      const localVarPath = `/consultation-request/{consultationRequestId}/call`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a report for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsultationRequestReport: async (
      consultationRequestId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists(
        'getConsultationRequestReport',
        'consultationRequestId',
        consultationRequestId
      )
      const localVarPath = `/consultation-request/{consultationRequestId}/report`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a share code for the Consultation Request.
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsultationRequestShareCode: async (
      consultationRequestId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists(
        'getConsultationRequestShareCode',
        'consultationRequestId',
        consultationRequestId
      )
      const localVarPath = `/consultation-request/{consultationRequestId}/share-code`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a list of consultation requests incoming to the authenticated user
     * @param {Array<UrgencyDTO>} urgency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIncomingConsultationRequests: async (
      urgency: Array<UrgencyDTO>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'urgency' is not null or undefined
      assertParamExists('getIncomingConsultationRequests', 'urgency', urgency)
      const localVarPath = `/incoming-consultation-request`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (urgency) {
        localVarQueryParameter['urgency'] = urgency
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a list of consultation requests going out from the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOutgoingConsultationRequests: async (
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/outgoing-consultation-request`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Starts a call or retrieves call access for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinCall: async (
      consultationRequestId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists('joinCall', 'consultationRequestId', consultationRequestId)
      const localVarPath = `/consultation-request/{consultationRequestId}/call`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Joins a Call for the Consultation Request with share code
     * @param {string} consultationShareCode The share code of the consultation request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinConsultationRequestWithShareCode: async (
      consultationShareCode: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationShareCode' is not null or undefined
      assertParamExists(
        'joinConsultationRequestWithShareCode',
        'consultationShareCode',
        consultationShareCode
      )
      const localVarPath =
        `/consultation-request/join-with-share-code/{consultationShareCode}`.replace(
          `{${'consultationShareCode'}}`,
          encodeURIComponent(String(consultationShareCode))
        )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Adds a report for the Consultation Request.
     * @param {string} consultationRequestId
     * @param {ConsultationRequestReportDTO} consultationRequestReportDTO
     * @param {boolean} [finalize] True if the consultation report is final and can\&#39;t be overwritten anymore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putConsultationRequestReport: async (
      consultationRequestId: string,
      consultationRequestReportDTO: ConsultationRequestReportDTO,
      finalize?: boolean,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists(
        'putConsultationRequestReport',
        'consultationRequestId',
        consultationRequestId
      )
      // verify required parameter 'consultationRequestReportDTO' is not null or undefined
      assertParamExists(
        'putConsultationRequestReport',
        'consultationRequestReportDTO',
        consultationRequestReportDTO
      )
      const localVarPath = `/consultation-request/{consultationRequestId}/report`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (finalize !== undefined) {
        localVarQueryParameter['finalize'] = finalize
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        consultationRequestReportDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ConsultationRequestApi - functional programming interface
 * @export
 */
export const ConsultationRequestApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ConsultationRequestApiAxiosParamCreator(configuration)
  return {
    /**
     * Accepts a Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async acceptConsultationRequest(
      consultationRequestId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.acceptConsultationRequest(
        consultationRequestId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.acceptConsultationRequest']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Creates a Consultation Request object
     * @param {ConsultationRequestCreationDTO} consultationRequestCreationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createConsultationRequest(
      consultationRequestCreationDTO: ConsultationRequestCreationDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createConsultationRequest(
        consultationRequestCreationDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.createConsultationRequest']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Declines a consultation request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async declineConsultationRequest(
      consultationRequestId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.declineConsultationRequest(
        consultationRequestId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.declineConsultationRequest']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Deletes a Consultation Request object
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteConsultationRequest(
      consultationRequestId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConsultationRequest(
        consultationRequestId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.deleteConsultationRequest']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Ends a Call for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async endCall(
      consultationRequestId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.endCall(
        consultationRequestId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.endCall']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a report for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConsultationRequestReport(
      consultationRequestId: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetConsultationRequestReportResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConsultationRequestReport(
        consultationRequestId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.getConsultationRequestReport']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a share code for the Consultation Request.
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConsultationRequestShareCode(
      consultationRequestId: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<GetConsultationRequestShareCodeResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConsultationRequestShareCode(
        consultationRequestId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.getConsultationRequestShareCode']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a list of consultation requests incoming to the authenticated user
     * @param {Array<UrgencyDTO>} urgency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIncomingConsultationRequests(
      urgency: Array<UrgencyDTO>,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsultationRequestsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIncomingConsultationRequests(
        urgency,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.getIncomingConsultationRequests']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a list of consultation requests going out from the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOutgoingConsultationRequests(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConsultationRequestsResponse>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getOutgoingConsultationRequests(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.getOutgoingConsultationRequests']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Starts a call or retrieves call access for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async joinCall(
      consultationRequestId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallAccessDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.joinCall(
        consultationRequestId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.joinCall']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Joins a Call for the Consultation Request with share code
     * @param {string} consultationShareCode The share code of the consultation request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async joinConsultationRequestWithShareCode(
      consultationShareCode: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CallAccessDTO>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.joinConsultationRequestWithShareCode(
          consultationShareCode,
          options
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.joinConsultationRequestWithShareCode']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Adds a report for the Consultation Request.
     * @param {string} consultationRequestId
     * @param {ConsultationRequestReportDTO} consultationRequestReportDTO
     * @param {boolean} [finalize] True if the consultation report is final and can\&#39;t be overwritten anymore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putConsultationRequestReport(
      consultationRequestId: string,
      consultationRequestReportDTO: ConsultationRequestReportDTO,
      finalize?: boolean,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putConsultationRequestReport(
        consultationRequestId,
        consultationRequestReportDTO,
        finalize,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ConsultationRequestApi.putConsultationRequestReport']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * ConsultationRequestApi - factory interface
 * @export
 */
export const ConsultationRequestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ConsultationRequestApiFp(configuration)
  return {
    /**
     * Accepts a Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    acceptConsultationRequest(consultationRequestId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .acceptConsultationRequest(consultationRequestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Creates a Consultation Request object
     * @param {ConsultationRequestCreationDTO} consultationRequestCreationDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConsultationRequest(
      consultationRequestCreationDTO: ConsultationRequestCreationDTO,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .createConsultationRequest(consultationRequestCreationDTO, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Declines a consultation request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    declineConsultationRequest(consultationRequestId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .declineConsultationRequest(consultationRequestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Deletes a Consultation Request object
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteConsultationRequest(consultationRequestId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteConsultationRequest(consultationRequestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Ends a Call for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endCall(consultationRequestId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .endCall(consultationRequestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a report for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsultationRequestReport(
      consultationRequestId: string,
      options?: any
    ): AxiosPromise<GetConsultationRequestReportResponse> {
      return localVarFp
        .getConsultationRequestReport(consultationRequestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a share code for the Consultation Request.
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsultationRequestShareCode(
      consultationRequestId: string,
      options?: any
    ): AxiosPromise<GetConsultationRequestShareCodeResponse> {
      return localVarFp
        .getConsultationRequestShareCode(consultationRequestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a list of consultation requests incoming to the authenticated user
     * @param {Array<UrgencyDTO>} urgency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIncomingConsultationRequests(
      urgency: Array<UrgencyDTO>,
      options?: any
    ): AxiosPromise<GetConsultationRequestsResponse> {
      return localVarFp
        .getIncomingConsultationRequests(urgency, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a list of consultation requests going out from the authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOutgoingConsultationRequests(options?: any): AxiosPromise<GetConsultationRequestsResponse> {
      return localVarFp
        .getOutgoingConsultationRequests(options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Starts a call or retrieves call access for the Consultation Request
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinCall(consultationRequestId: string, options?: any): AxiosPromise<CallAccessDTO> {
      return localVarFp
        .joinCall(consultationRequestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Joins a Call for the Consultation Request with share code
     * @param {string} consultationShareCode The share code of the consultation request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinConsultationRequestWithShareCode(
      consultationShareCode: string,
      options?: any
    ): AxiosPromise<CallAccessDTO> {
      return localVarFp
        .joinConsultationRequestWithShareCode(consultationShareCode, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Adds a report for the Consultation Request.
     * @param {string} consultationRequestId
     * @param {ConsultationRequestReportDTO} consultationRequestReportDTO
     * @param {boolean} [finalize] True if the consultation report is final and can\&#39;t be overwritten anymore
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putConsultationRequestReport(
      consultationRequestId: string,
      consultationRequestReportDTO: ConsultationRequestReportDTO,
      finalize?: boolean,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putConsultationRequestReport(
          consultationRequestId,
          consultationRequestReportDTO,
          finalize,
          options
        )
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * ConsultationRequestApi - object-oriented interface
 * @export
 * @class ConsultationRequestApi
 * @extends {BaseAPI}
 */
export class ConsultationRequestApi extends BaseAPI {
  /**
   * Accepts a Consultation Request
   * @param {string} consultationRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public acceptConsultationRequest(consultationRequestId: string, options?: RawAxiosRequestConfig) {
    return ConsultationRequestApiFp(this.configuration)
      .acceptConsultationRequest(consultationRequestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Creates a Consultation Request object
   * @param {ConsultationRequestCreationDTO} consultationRequestCreationDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public createConsultationRequest(
    consultationRequestCreationDTO: ConsultationRequestCreationDTO,
    options?: RawAxiosRequestConfig
  ) {
    return ConsultationRequestApiFp(this.configuration)
      .createConsultationRequest(consultationRequestCreationDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Declines a consultation request
   * @param {string} consultationRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public declineConsultationRequest(
    consultationRequestId: string,
    options?: RawAxiosRequestConfig
  ) {
    return ConsultationRequestApiFp(this.configuration)
      .declineConsultationRequest(consultationRequestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Deletes a Consultation Request object
   * @param {string} consultationRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public deleteConsultationRequest(consultationRequestId: string, options?: RawAxiosRequestConfig) {
    return ConsultationRequestApiFp(this.configuration)
      .deleteConsultationRequest(consultationRequestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Ends a Call for the Consultation Request
   * @param {string} consultationRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public endCall(consultationRequestId: string, options?: RawAxiosRequestConfig) {
    return ConsultationRequestApiFp(this.configuration)
      .endCall(consultationRequestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a report for the Consultation Request
   * @param {string} consultationRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public getConsultationRequestReport(
    consultationRequestId: string,
    options?: RawAxiosRequestConfig
  ) {
    return ConsultationRequestApiFp(this.configuration)
      .getConsultationRequestReport(consultationRequestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a share code for the Consultation Request.
   * @param {string} consultationRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public getConsultationRequestShareCode(
    consultationRequestId: string,
    options?: RawAxiosRequestConfig
  ) {
    return ConsultationRequestApiFp(this.configuration)
      .getConsultationRequestShareCode(consultationRequestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a list of consultation requests incoming to the authenticated user
   * @param {Array<UrgencyDTO>} urgency
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public getIncomingConsultationRequests(
    urgency: Array<UrgencyDTO>,
    options?: RawAxiosRequestConfig
  ) {
    return ConsultationRequestApiFp(this.configuration)
      .getIncomingConsultationRequests(urgency, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a list of consultation requests going out from the authenticated user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public getOutgoingConsultationRequests(options?: RawAxiosRequestConfig) {
    return ConsultationRequestApiFp(this.configuration)
      .getOutgoingConsultationRequests(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Starts a call or retrieves call access for the Consultation Request
   * @param {string} consultationRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public joinCall(consultationRequestId: string, options?: RawAxiosRequestConfig) {
    return ConsultationRequestApiFp(this.configuration)
      .joinCall(consultationRequestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Joins a Call for the Consultation Request with share code
   * @param {string} consultationShareCode The share code of the consultation request
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public joinConsultationRequestWithShareCode(
    consultationShareCode: string,
    options?: RawAxiosRequestConfig
  ) {
    return ConsultationRequestApiFp(this.configuration)
      .joinConsultationRequestWithShareCode(consultationShareCode, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Adds a report for the Consultation Request.
   * @param {string} consultationRequestId
   * @param {ConsultationRequestReportDTO} consultationRequestReportDTO
   * @param {boolean} [finalize] True if the consultation report is final and can\&#39;t be overwritten anymore
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsultationRequestApi
   */
  public putConsultationRequestReport(
    consultationRequestId: string,
    consultationRequestReportDTO: ConsultationRequestReportDTO,
    finalize?: boolean,
    options?: RawAxiosRequestConfig
  ) {
    return ConsultationRequestApiFp(this.configuration)
      .putConsultationRequestReport(
        consultationRequestId,
        consultationRequestReportDTO,
        finalize,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Deletes a file if the user is the same as uploader
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile: async (
      fileId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileId' is not null or undefined
      assertParamExists('deleteFile', 'fileId', fileId)
      const localVarPath = `/file/{fileId}`.replace(
        `{${'fileId'}}`,
        encodeURIComponent(String(fileId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Completes the file upload process
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finalizeFile: async (
      fileId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileId' is not null or undefined
      assertParamExists('finalizeFile', 'fileId', fileId)
      const localVarPath = `/file/{fileId}`.replace(
        `{${'fileId'}}`,
        encodeURIComponent(String(fileId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a pre-signed URL to download the file
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUrl: async (
      fileId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileId' is not null or undefined
      assertParamExists('getDownloadUrl', 'fileId', fileId)
      const localVarPath = `/file/{fileId}`.replace(
        `{${'fileId'}}`,
        encodeURIComponent(String(fileId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Uploads a file for a Case.
     * @param {string} caseId ID of the case where the file should be uploaded
     * @param {FileUploadRequestDTO} fileUploadRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile: async (
      caseId: string,
      fileUploadRequestDTO: FileUploadRequestDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'caseId' is not null or undefined
      assertParamExists('uploadFile', 'caseId', caseId)
      // verify required parameter 'fileUploadRequestDTO' is not null or undefined
      assertParamExists('uploadFile', 'fileUploadRequestDTO', fileUploadRequestDTO)
      const localVarPath = `/case/{caseId}/file`.replace(
        `{${'caseId'}}`,
        encodeURIComponent(String(caseId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        fileUploadRequestDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
  return {
    /**
     * Deletes a file if the user is the same as uploader
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFile(
      fileId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(fileId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FileApi.deleteFile']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Completes the file upload process
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async finalizeFile(
      fileId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.finalizeFile(fileId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FileApi.finalizeFile']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a pre-signed URL to download the file
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDownloadUrl(
      fileId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadFileResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadUrl(fileId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FileApi.getDownloadUrl']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Uploads a file for a Case.
     * @param {string} caseId ID of the case where the file should be uploaded
     * @param {FileUploadRequestDTO} fileUploadRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFile(
      caseId: string,
      fileUploadRequestDTO: FileUploadRequestDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(
        caseId,
        fileUploadRequestDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FileApi.uploadFile']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FileApiFp(configuration)
  return {
    /**
     * Deletes a file if the user is the same as uploader
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFile(fileId: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteFile(fileId, options).then((request) => request(axios, basePath))
    },
    /**
     * Completes the file upload process
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    finalizeFile(fileId: string, options?: any): AxiosPromise<void> {
      return localVarFp.finalizeFile(fileId, options).then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a pre-signed URL to download the file
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDownloadUrl(fileId: string, options?: any): AxiosPromise<DownloadFileResponse> {
      return localVarFp.getDownloadUrl(fileId, options).then((request) => request(axios, basePath))
    },
    /**
     * Uploads a file for a Case.
     * @param {string} caseId ID of the case where the file should be uploaded
     * @param {FileUploadRequestDTO} fileUploadRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFile(
      caseId: string,
      fileUploadRequestDTO: FileUploadRequestDTO,
      options?: any
    ): AxiosPromise<UploadFileResponse> {
      return localVarFp
        .uploadFile(caseId, fileUploadRequestDTO, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
  /**
   * Deletes a file if the user is the same as uploader
   * @param {string} fileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public deleteFile(fileId: string, options?: RawAxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .deleteFile(fileId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Completes the file upload process
   * @param {string} fileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public finalizeFile(fileId: string, options?: RawAxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .finalizeFile(fileId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a pre-signed URL to download the file
   * @param {string} fileId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public getDownloadUrl(fileId: string, options?: RawAxiosRequestConfig) {
    return FileApiFp(this.configuration)
      .getDownloadUrl(fileId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Uploads a file for a Case.
   * @param {string} caseId ID of the case where the file should be uploaded
   * @param {FileUploadRequestDTO} fileUploadRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileApi
   */
  public uploadFile(
    caseId: string,
    fileUploadRequestDTO: FileUploadRequestDTO,
    options?: RawAxiosRequestConfig
  ) {
    return FileApiFp(this.configuration)
      .uploadFile(caseId, fileUploadRequestDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * FiltersApi - axios parameter creator
 * @export
 */
export const FiltersApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Deletes filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteVitalSignsFilters: async (
      encodedUserName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('deleteVitalSignsFilters', 'encodedUserName', encodedUserName)
      const localVarPath = `/user-settings/{encodedUserName}/filters/vital-signs`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a list of filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVitalSignsFilters: async (
      encodedUserName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('getVitalSignsFilters', 'encodedUserName', encodedUserName)
      const localVarPath = `/user-settings/{encodedUserName}/filters/vital-signs`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Updates filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {VitalSignsFiltersDTO} vitalSignsFiltersDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postVitalSignsFilters: async (
      encodedUserName: string,
      vitalSignsFiltersDTO: VitalSignsFiltersDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('postVitalSignsFilters', 'encodedUserName', encodedUserName)
      // verify required parameter 'vitalSignsFiltersDTO' is not null or undefined
      assertParamExists('postVitalSignsFilters', 'vitalSignsFiltersDTO', vitalSignsFiltersDTO)
      const localVarPath = `/user-settings/{encodedUserName}/filters/vital-signs`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        vitalSignsFiltersDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * FiltersApi - functional programming interface
 * @export
 */
export const FiltersApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FiltersApiAxiosParamCreator(configuration)
  return {
    /**
     * Deletes filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteVitalSignsFilters(
      encodedUserName: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVitalSignsFilters(
        encodedUserName,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FiltersApi.deleteVitalSignsFilters']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a list of filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVitalSignsFilters(
      encodedUserName: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VitalSignsFiltersDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVitalSignsFilters(
        encodedUserName,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FiltersApi.getVitalSignsFilters']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Updates filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {VitalSignsFiltersDTO} vitalSignsFiltersDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postVitalSignsFilters(
      encodedUserName: string,
      vitalSignsFiltersDTO: VitalSignsFiltersDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VitalSignsFiltersDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postVitalSignsFilters(
        encodedUserName,
        vitalSignsFiltersDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['FiltersApi.postVitalSignsFilters']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * FiltersApi - factory interface
 * @export
 */
export const FiltersApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FiltersApiFp(configuration)
  return {
    /**
     * Deletes filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteVitalSignsFilters(encodedUserName: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .deleteVitalSignsFilters(encodedUserName, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a list of filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVitalSignsFilters(
      encodedUserName: string,
      options?: any
    ): AxiosPromise<VitalSignsFiltersDTO> {
      return localVarFp
        .getVitalSignsFilters(encodedUserName, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Updates filters for Vital Signs
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {VitalSignsFiltersDTO} vitalSignsFiltersDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postVitalSignsFilters(
      encodedUserName: string,
      vitalSignsFiltersDTO: VitalSignsFiltersDTO,
      options?: any
    ): AxiosPromise<VitalSignsFiltersDTO> {
      return localVarFp
        .postVitalSignsFilters(encodedUserName, vitalSignsFiltersDTO, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * FiltersApi - object-oriented interface
 * @export
 * @class FiltersApi
 * @extends {BaseAPI}
 */
export class FiltersApi extends BaseAPI {
  /**
   * Deletes filters for Vital Signs
   * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiltersApi
   */
  public deleteVitalSignsFilters(encodedUserName: string, options?: RawAxiosRequestConfig) {
    return FiltersApiFp(this.configuration)
      .deleteVitalSignsFilters(encodedUserName, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a list of filters for Vital Signs
   * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiltersApi
   */
  public getVitalSignsFilters(encodedUserName: string, options?: RawAxiosRequestConfig) {
    return FiltersApiFp(this.configuration)
      .getVitalSignsFilters(encodedUserName, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Updates filters for Vital Signs
   * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
   * @param {VitalSignsFiltersDTO} vitalSignsFiltersDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FiltersApi
   */
  public postVitalSignsFilters(
    encodedUserName: string,
    vitalSignsFiltersDTO: VitalSignsFiltersDTO,
    options?: RawAxiosRequestConfig
  ) {
    return FiltersApiFp(this.configuration)
      .postVitalSignsFilters(encodedUserName, vitalSignsFiltersDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * HospitalSettingsApi - axios parameter creator
 * @export
 */
export const HospitalSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Updates Escalation Steps for the Hospital Settings.
     * @param {string} hospitalId
     * @param {UrgencyDTO} urgency The urgency for which the escalation steps should be set
     * @param {Array<EscalationStepDTO>} escalationStepDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createHospitalSettingsEscalationSteps: async (
      hospitalId: string,
      urgency: UrgencyDTO,
      escalationStepDTO: Array<EscalationStepDTO>,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'hospitalId' is not null or undefined
      assertParamExists('createHospitalSettingsEscalationSteps', 'hospitalId', hospitalId)
      // verify required parameter 'urgency' is not null or undefined
      assertParamExists('createHospitalSettingsEscalationSteps', 'urgency', urgency)
      // verify required parameter 'escalationStepDTO' is not null or undefined
      assertParamExists(
        'createHospitalSettingsEscalationSteps',
        'escalationStepDTO',
        escalationStepDTO
      )
      const localVarPath = `/hospital-settings/{hospitalId}/escalation-steps/{urgency}`
        .replace(`{${'hospitalId'}}`, encodeURIComponent(String(hospitalId)))
        .replace(`{${'urgency'}}`, encodeURIComponent(String(urgency)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        escalationStepDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Updates Escalation Extension for the Hospital Settings
     * @param {string} hospitalId
     * @param {EscalationExtensionDTO} escalationExtensionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateHospitalSettingsEscalationExtension: async (
      hospitalId: string,
      escalationExtensionDTO: EscalationExtensionDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'hospitalId' is not null or undefined
      assertParamExists('updateHospitalSettingsEscalationExtension', 'hospitalId', hospitalId)
      // verify required parameter 'escalationExtensionDTO' is not null or undefined
      assertParamExists(
        'updateHospitalSettingsEscalationExtension',
        'escalationExtensionDTO',
        escalationExtensionDTO
      )
      const localVarPath = `/hospital-settings/{hospitalId}/escalation-duration-extension`.replace(
        `{${'hospitalId'}}`,
        encodeURIComponent(String(hospitalId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        escalationExtensionDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * HospitalSettingsApi - functional programming interface
 * @export
 */
export const HospitalSettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HospitalSettingsApiAxiosParamCreator(configuration)
  return {
    /**
     * Updates Escalation Steps for the Hospital Settings.
     * @param {string} hospitalId
     * @param {UrgencyDTO} urgency The urgency for which the escalation steps should be set
     * @param {Array<EscalationStepDTO>} escalationStepDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createHospitalSettingsEscalationSteps(
      hospitalId: string,
      urgency: UrgencyDTO,
      escalationStepDTO: Array<EscalationStepDTO>,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.createHospitalSettingsEscalationSteps(
          hospitalId,
          urgency,
          escalationStepDTO,
          options
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['HospitalSettingsApi.createHospitalSettingsEscalationSteps']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Updates Escalation Extension for the Hospital Settings
     * @param {string} hospitalId
     * @param {EscalationExtensionDTO} escalationExtensionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateHospitalSettingsEscalationExtension(
      hospitalId: string,
      escalationExtensionDTO: EscalationExtensionDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.updateHospitalSettingsEscalationExtension(
          hospitalId,
          escalationExtensionDTO,
          options
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['HospitalSettingsApi.updateHospitalSettingsEscalationExtension']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * HospitalSettingsApi - factory interface
 * @export
 */
export const HospitalSettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HospitalSettingsApiFp(configuration)
  return {
    /**
     * Updates Escalation Steps for the Hospital Settings.
     * @param {string} hospitalId
     * @param {UrgencyDTO} urgency The urgency for which the escalation steps should be set
     * @param {Array<EscalationStepDTO>} escalationStepDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createHospitalSettingsEscalationSteps(
      hospitalId: string,
      urgency: UrgencyDTO,
      escalationStepDTO: Array<EscalationStepDTO>,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .createHospitalSettingsEscalationSteps(hospitalId, urgency, escalationStepDTO, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Updates Escalation Extension for the Hospital Settings
     * @param {string} hospitalId
     * @param {EscalationExtensionDTO} escalationExtensionDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateHospitalSettingsEscalationExtension(
      hospitalId: string,
      escalationExtensionDTO: EscalationExtensionDTO,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateHospitalSettingsEscalationExtension(hospitalId, escalationExtensionDTO, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * HospitalSettingsApi - object-oriented interface
 * @export
 * @class HospitalSettingsApi
 * @extends {BaseAPI}
 */
export class HospitalSettingsApi extends BaseAPI {
  /**
   * Updates Escalation Steps for the Hospital Settings.
   * @param {string} hospitalId
   * @param {UrgencyDTO} urgency The urgency for which the escalation steps should be set
   * @param {Array<EscalationStepDTO>} escalationStepDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HospitalSettingsApi
   */
  public createHospitalSettingsEscalationSteps(
    hospitalId: string,
    urgency: UrgencyDTO,
    escalationStepDTO: Array<EscalationStepDTO>,
    options?: RawAxiosRequestConfig
  ) {
    return HospitalSettingsApiFp(this.configuration)
      .createHospitalSettingsEscalationSteps(hospitalId, urgency, escalationStepDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Updates Escalation Extension for the Hospital Settings
   * @param {string} hospitalId
   * @param {EscalationExtensionDTO} escalationExtensionDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HospitalSettingsApi
   */
  public updateHospitalSettingsEscalationExtension(
    hospitalId: string,
    escalationExtensionDTO: EscalationExtensionDTO,
    options?: RawAxiosRequestConfig
  ) {
    return HospitalSettingsApiFp(this.configuration)
      .updateHospitalSettingsEscalationExtension(hospitalId, escalationExtensionDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * MedicalFieldApi - axios parameter creator
 * @export
 */
export const MedicalFieldApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieves all Medical Fields of a Hospital
     * @param {string} [hospitalId] ID of the hospital of which medical fields are searched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMedicalFields: async (
      hospitalId?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/medical-field`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (hospitalId !== undefined) {
        localVarQueryParameter['hospitalId'] = hospitalId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * MedicalFieldApi - functional programming interface
 * @export
 */
export const MedicalFieldApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MedicalFieldApiAxiosParamCreator(configuration)
  return {
    /**
     * Retrieves all Medical Fields of a Hospital
     * @param {string} [hospitalId] ID of the hospital of which medical fields are searched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMedicalFields(
      hospitalId?: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMedicalFieldsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMedicalFields(
        hospitalId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['MedicalFieldApi.getMedicalFields']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * MedicalFieldApi - factory interface
 * @export
 */
export const MedicalFieldApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = MedicalFieldApiFp(configuration)
  return {
    /**
     * Retrieves all Medical Fields of a Hospital
     * @param {string} [hospitalId] ID of the hospital of which medical fields are searched
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMedicalFields(hospitalId?: string, options?: any): AxiosPromise<GetMedicalFieldsResponse> {
      return localVarFp
        .getMedicalFields(hospitalId, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * MedicalFieldApi - object-oriented interface
 * @export
 * @class MedicalFieldApi
 * @extends {BaseAPI}
 */
export class MedicalFieldApi extends BaseAPI {
  /**
   * Retrieves all Medical Fields of a Hospital
   * @param {string} [hospitalId] ID of the hospital of which medical fields are searched
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MedicalFieldApi
   */
  public getMedicalFields(hospitalId?: string, options?: RawAxiosRequestConfig) {
    return MedicalFieldApiFp(this.configuration)
      .getMedicalFields(hospitalId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieves a list of Notifications of the current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/notification`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Marks the given and all older notifications as read
     * @param {string} notificationId The ID of the last read notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markNotificationsStatusAsRead: async (
      notificationId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'notificationId' is not null or undefined
      assertParamExists('markNotificationsStatusAsRead', 'notificationId', notificationId)
      const localVarPath = `/notification/{notificationId}/mark-read-including-older`.replace(
        `{${'notificationId'}}`,
        encodeURIComponent(String(notificationId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
  return {
    /**
     * Retrieves a list of Notifications of the current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotifications(
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNotificationsResponseDTO>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['NotificationApi.getNotifications']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Marks the given and all older notifications as read
     * @param {string} notificationId The ID of the last read notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async markNotificationsStatusAsRead(
      notificationId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationsStatusAsRead(
        notificationId,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['NotificationApi.markNotificationsStatusAsRead']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = NotificationApiFp(configuration)
  return {
    /**
     * Retrieves a list of Notifications of the current User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotifications(options?: any): AxiosPromise<GetNotificationsResponseDTO> {
      return localVarFp.getNotifications(options).then((request) => request(axios, basePath))
    },
    /**
     * Marks the given and all older notifications as read
     * @param {string} notificationId The ID of the last read notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    markNotificationsStatusAsRead(notificationId: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .markNotificationsStatusAsRead(notificationId, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
  /**
   * Retrieves a list of Notifications of the current User
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public getNotifications(options?: RawAxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .getNotifications(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Marks the given and all older notifications as read
   * @param {string} notificationId The ID of the last read notification
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof NotificationApi
   */
  public markNotificationsStatusAsRead(notificationId: string, options?: RawAxiosRequestConfig) {
    return NotificationApiFp(this.configuration)
      .markNotificationsStatusAsRead(notificationId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * RoutingSupervisorApiApi - axios parameter creator
 * @export
 */
export const RoutingSupervisorApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieves doctors available for assignment to a Consultation Request.
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDoctorsAvailableForConsultationRequestAssignment: async (
      consultationRequestId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists(
        'getDoctorsAvailableForConsultationRequestAssignment',
        'consultationRequestId',
        consultationRequestId
      )
      const localVarPath = `/routing-supervisor/available-doctors/{consultationRequestId}`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Assigns doctor to a Consultation Request.
     * @param {string} consultationRequestId
     * @param {AssignDoctorToConsultationRequestDTO} assignDoctorToConsultationRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAssignDoctorToConsultationRequest: async (
      consultationRequestId: string,
      assignDoctorToConsultationRequestDTO: AssignDoctorToConsultationRequestDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultationRequestId' is not null or undefined
      assertParamExists(
        'putAssignDoctorToConsultationRequest',
        'consultationRequestId',
        consultationRequestId
      )
      // verify required parameter 'assignDoctorToConsultationRequestDTO' is not null or undefined
      assertParamExists(
        'putAssignDoctorToConsultationRequest',
        'assignDoctorToConsultationRequestDTO',
        assignDoctorToConsultationRequestDTO
      )
      const localVarPath = `/routing-supervisor/assign-doctor/{consultationRequestId}`.replace(
        `{${'consultationRequestId'}}`,
        encodeURIComponent(String(consultationRequestId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        assignDoctorToConsultationRequestDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * RoutingSupervisorApiApi - functional programming interface
 * @export
 */
export const RoutingSupervisorApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = RoutingSupervisorApiApiAxiosParamCreator(configuration)
  return {
    /**
     * Retrieves doctors available for assignment to a Consultation Request.
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDoctorsAvailableForConsultationRequestAssignment(
      consultationRequestId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserSettings>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getDoctorsAvailableForConsultationRequestAssignment(
          consultationRequestId,
          options
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap[
          'RoutingSupervisorApiApi.getDoctorsAvailableForConsultationRequestAssignment'
        ]?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Assigns doctor to a Consultation Request.
     * @param {string} consultationRequestId
     * @param {AssignDoctorToConsultationRequestDTO} assignDoctorToConsultationRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putAssignDoctorToConsultationRequest(
      consultationRequestId: string,
      assignDoctorToConsultationRequestDTO: AssignDoctorToConsultationRequestDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.putAssignDoctorToConsultationRequest(
          consultationRequestId,
          assignDoctorToConsultationRequestDTO,
          options
        )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['RoutingSupervisorApiApi.putAssignDoctorToConsultationRequest']?.[
          localVarOperationServerIndex
        ]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * RoutingSupervisorApiApi - factory interface
 * @export
 */
export const RoutingSupervisorApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = RoutingSupervisorApiApiFp(configuration)
  return {
    /**
     * Retrieves doctors available for assignment to a Consultation Request.
     * @param {string} consultationRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDoctorsAvailableForConsultationRequestAssignment(
      consultationRequestId: string,
      options?: any
    ): AxiosPromise<Array<UserSettings>> {
      return localVarFp
        .getDoctorsAvailableForConsultationRequestAssignment(consultationRequestId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Assigns doctor to a Consultation Request.
     * @param {string} consultationRequestId
     * @param {AssignDoctorToConsultationRequestDTO} assignDoctorToConsultationRequestDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putAssignDoctorToConsultationRequest(
      consultationRequestId: string,
      assignDoctorToConsultationRequestDTO: AssignDoctorToConsultationRequestDTO,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .putAssignDoctorToConsultationRequest(
          consultationRequestId,
          assignDoctorToConsultationRequestDTO,
          options
        )
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * RoutingSupervisorApiApi - object-oriented interface
 * @export
 * @class RoutingSupervisorApiApi
 * @extends {BaseAPI}
 */
export class RoutingSupervisorApiApi extends BaseAPI {
  /**
   * Retrieves doctors available for assignment to a Consultation Request.
   * @param {string} consultationRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoutingSupervisorApiApi
   */
  public getDoctorsAvailableForConsultationRequestAssignment(
    consultationRequestId: string,
    options?: RawAxiosRequestConfig
  ) {
    return RoutingSupervisorApiApiFp(this.configuration)
      .getDoctorsAvailableForConsultationRequestAssignment(consultationRequestId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Assigns doctor to a Consultation Request.
   * @param {string} consultationRequestId
   * @param {AssignDoctorToConsultationRequestDTO} assignDoctorToConsultationRequestDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof RoutingSupervisorApiApi
   */
  public putAssignDoctorToConsultationRequest(
    consultationRequestId: string,
    assignDoctorToConsultationRequestDTO: AssignDoctorToConsultationRequestDTO,
    options?: RawAxiosRequestConfig
  ) {
    return RoutingSupervisorApiApiFp(this.configuration)
      .putAssignDoctorToConsultationRequest(
        consultationRequestId,
        assignDoctorToConsultationRequestDTO,
        options
      )
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ShiftApi - axios parameter creator
 * @export
 */
export const ShiftApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {ShiftDTO} shiftDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAndBeginShift: async (
      shiftDTO: ShiftDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'shiftDTO' is not null or undefined
      assertParamExists('createAndBeginShift', 'shiftDTO', shiftDTO)
      const localVarPath = `/shift`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        shiftDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * ShiftApi - functional programming interface
 * @export
 */
export const ShiftApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ShiftApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {ShiftDTO} shiftDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createAndBeginShift(
      shiftDTO: ShiftDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createAndBeginShift(
        shiftDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['ShiftApi.createAndBeginShift']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * ShiftApi - factory interface
 * @export
 */
export const ShiftApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ShiftApiFp(configuration)
  return {
    /**
     *
     * @param {ShiftDTO} shiftDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createAndBeginShift(shiftDTO: ShiftDTO, options?: any): AxiosPromise<void> {
      return localVarFp
        .createAndBeginShift(shiftDTO, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * ShiftApi - object-oriented interface
 * @export
 * @class ShiftApi
 * @extends {BaseAPI}
 */
export class ShiftApi extends BaseAPI {
  /**
   *
   * @param {ShiftDTO} shiftDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ShiftApi
   */
  public createAndBeginShift(shiftDTO: ShiftDTO, options?: RawAxiosRequestConfig) {
    return ShiftApiFp(this.configuration)
      .createAndBeginShift(shiftDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Extends current shift of the user to given end time
     * @param {string} encodedUserName Base64 encoded username of the user who has logged in
     * @param {ShiftExtendDTO} shiftExtendDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendShift: async (
      encodedUserName: string,
      shiftExtendDTO: ShiftExtendDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('extendShift', 'encodedUserName', encodedUserName)
      // verify required parameter 'shiftExtendDTO' is not null or undefined
      assertParamExists('extendShift', 'shiftExtendDTO', shiftExtendDTO)
      const localVarPath = `/user/{encodedUserName}/extend-shift`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        shiftExtendDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user whose shift must be handed over to another user
     * @param {ShiftHandoverDTO} [shiftHandoverDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handoverShift: async (
      encodedUserName: string,
      shiftHandoverDTO?: ShiftHandoverDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('handoverShift', 'encodedUserName', encodedUserName)
      const localVarPath = `/user/{encodedUserName}/handover-shift`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        shiftHandoverDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user who has logged in
     * @param {LoginDTO} loginDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      encodedUserName: string,
      loginDTO: LoginDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('login', 'encodedUserName', encodedUserName)
      // verify required parameter 'loginDTO' is not null or undefined
      assertParamExists('login', 'loginDTO', loginDTO)
      const localVarPath = `/user/{encodedUserName}/login`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        loginDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user who wants to logout
     * @param {LogoutDTO} logoutDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logoutDevice: async (
      encodedUserName: string,
      logoutDTO: LogoutDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('logoutDevice', 'encodedUserName', encodedUserName)
      // verify required parameter 'logoutDTO' is not null or undefined
      assertParamExists('logoutDevice', 'logoutDTO', logoutDTO)
      const localVarPath = `/user/{encodedUserName}/logout-device`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        logoutDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Searches users and filters them if a relevant filter has been given
     * @param {SearchUsersFilterDTO} eligibleFor Applies a filter on the users\&#39; list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUsers: async (
      eligibleFor: SearchUsersFilterDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'eligibleFor' is not null or undefined
      assertParamExists('searchUsers', 'eligibleFor', eligibleFor)
      const localVarPath = `/user/search`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (eligibleFor !== undefined) {
        localVarQueryParameter['eligible-for'] = eligibleFor
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
  return {
    /**
     * Extends current shift of the user to given end time
     * @param {string} encodedUserName Base64 encoded username of the user who has logged in
     * @param {ShiftExtendDTO} shiftExtendDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extendShift(
      encodedUserName: string,
      shiftExtendDTO: ShiftExtendDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.extendShift(
        encodedUserName,
        shiftExtendDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.extendShift']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user whose shift must be handed over to another user
     * @param {ShiftHandoverDTO} [shiftHandoverDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handoverShift(
      encodedUserName: string,
      shiftHandoverDTO?: ShiftHandoverDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handoverShift(
        encodedUserName,
        shiftHandoverDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.handoverShift']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user who has logged in
     * @param {LoginDTO} loginDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      encodedUserName: string,
      loginDTO: LoginDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        encodedUserName,
        loginDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.login']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user who wants to logout
     * @param {LogoutDTO} logoutDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logoutDevice(
      encodedUserName: string,
      logoutDTO: LogoutDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logoutDevice(
        encodedUserName,
        logoutDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.logoutDevice']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Searches users and filters them if a relevant filter has been given
     * @param {SearchUsersFilterDTO} eligibleFor Applies a filter on the users\&#39; list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchUsers(
      eligibleFor: SearchUsersFilterDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchUsersResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(eligibleFor, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserApi.searchUsers']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserApiFp(configuration)
  return {
    /**
     * Extends current shift of the user to given end time
     * @param {string} encodedUserName Base64 encoded username of the user who has logged in
     * @param {ShiftExtendDTO} shiftExtendDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendShift(
      encodedUserName: string,
      shiftExtendDTO: ShiftExtendDTO,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .extendShift(encodedUserName, shiftExtendDTO, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user whose shift must be handed over to another user
     * @param {ShiftHandoverDTO} [shiftHandoverDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handoverShift(
      encodedUserName: string,
      shiftHandoverDTO?: ShiftHandoverDTO,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .handoverShift(encodedUserName, shiftHandoverDTO, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user who has logged in
     * @param {LoginDTO} loginDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(encodedUserName: string, loginDTO: LoginDTO, options?: any): AxiosPromise<void> {
      return localVarFp
        .login(encodedUserName, loginDTO, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} encodedUserName Base64 encoded username of the user who wants to logout
     * @param {LogoutDTO} logoutDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logoutDevice(encodedUserName: string, logoutDTO: LogoutDTO, options?: any): AxiosPromise<void> {
      return localVarFp
        .logoutDevice(encodedUserName, logoutDTO, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Searches users and filters them if a relevant filter has been given
     * @param {SearchUsersFilterDTO} eligibleFor Applies a filter on the users\&#39; list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchUsers(
      eligibleFor: SearchUsersFilterDTO,
      options?: any
    ): AxiosPromise<SearchUsersResponse> {
      return localVarFp
        .searchUsers(eligibleFor, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   * Extends current shift of the user to given end time
   * @param {string} encodedUserName Base64 encoded username of the user who has logged in
   * @param {ShiftExtendDTO} shiftExtendDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public extendShift(
    encodedUserName: string,
    shiftExtendDTO: ShiftExtendDTO,
    options?: RawAxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .extendShift(encodedUserName, shiftExtendDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} encodedUserName Base64 encoded username of the user whose shift must be handed over to another user
   * @param {ShiftHandoverDTO} [shiftHandoverDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public handoverShift(
    encodedUserName: string,
    shiftHandoverDTO?: ShiftHandoverDTO,
    options?: RawAxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .handoverShift(encodedUserName, shiftHandoverDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} encodedUserName Base64 encoded username of the user who has logged in
   * @param {LoginDTO} loginDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public login(encodedUserName: string, loginDTO: LoginDTO, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .login(encodedUserName, loginDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} encodedUserName Base64 encoded username of the user who wants to logout
   * @param {LogoutDTO} logoutDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public logoutDevice(
    encodedUserName: string,
    logoutDTO: LogoutDTO,
    options?: RawAxiosRequestConfig
  ) {
    return UserApiFp(this.configuration)
      .logoutDevice(encodedUserName, logoutDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Searches users and filters them if a relevant filter has been given
   * @param {SearchUsersFilterDTO} eligibleFor Applies a filter on the users\&#39; list
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public searchUsers(eligibleFor: SearchUsersFilterDTO, options?: RawAxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .searchUsers(eligibleFor, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserSettingsApi - axios parameter creator
 * @export
 */
export const UserSettingsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieves a list of Hospital and Ward objects assigned to the User
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their assigned hospitals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignedHospitals: async (
      encodedUserName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('getAssignedHospitals', 'encodedUserName', encodedUserName)
      const localVarPath = `/user-settings/{encodedUserName}/assigned-hospitals`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a User Settings object
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSettings: async (
      encodedUserName: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('getUserSettings', 'encodedUserName', encodedUserName)
      const localVarPath = `/user-settings/{encodedUserName}`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Updates a User Settings object
     * @param {string} encodedUserName Base64 encoded username of the user to partially update their settings
     * @param {UserSettingsUpdateDTO} userSettingsUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchUserSettings: async (
      encodedUserName: string,
      userSettingsUpdateDTO: UserSettingsUpdateDTO,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'encodedUserName' is not null or undefined
      assertParamExists('patchUserSettings', 'encodedUserName', encodedUserName)
      // verify required parameter 'userSettingsUpdateDTO' is not null or undefined
      assertParamExists('patchUserSettings', 'userSettingsUpdateDTO', userSettingsUpdateDTO)
      const localVarPath = `/user-settings/{encodedUserName}`.replace(
        `{${'encodedUserName'}}`,
        encodeURIComponent(String(encodedUserName))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        userSettingsUpdateDTO,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * UserSettingsApi - functional programming interface
 * @export
 */
export const UserSettingsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserSettingsApiAxiosParamCreator(configuration)
  return {
    /**
     * Retrieves a list of Hospital and Ward objects assigned to the User
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their assigned hospitals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAssignedHospitals(
      encodedUserName: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAssignedHospitalsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignedHospitals(
        encodedUserName,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserSettingsApi.getAssignedHospitals']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a User Settings object
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserSettings(
      encodedUserName: string,
      options?: RawAxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserSettingsResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserSettings(
        encodedUserName,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserSettingsApi.getUserSettings']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Updates a User Settings object
     * @param {string} encodedUserName Base64 encoded username of the user to partially update their settings
     * @param {UserSettingsUpdateDTO} userSettingsUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async patchUserSettings(
      encodedUserName: string,
      userSettingsUpdateDTO: UserSettingsUpdateDTO,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.patchUserSettings(
        encodedUserName,
        userSettingsUpdateDTO,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['UserSettingsApi.patchUserSettings']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * UserSettingsApi - factory interface
 * @export
 */
export const UserSettingsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = UserSettingsApiFp(configuration)
  return {
    /**
     * Retrieves a list of Hospital and Ward objects assigned to the User
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their assigned hospitals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAssignedHospitals(
      encodedUserName: string,
      options?: any
    ): AxiosPromise<GetAssignedHospitalsResponse> {
      return localVarFp
        .getAssignedHospitals(encodedUserName, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a User Settings object
     * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserSettings(encodedUserName: string, options?: any): AxiosPromise<GetUserSettingsResponse> {
      return localVarFp
        .getUserSettings(encodedUserName, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Updates a User Settings object
     * @param {string} encodedUserName Base64 encoded username of the user to partially update their settings
     * @param {UserSettingsUpdateDTO} userSettingsUpdateDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    patchUserSettings(
      encodedUserName: string,
      userSettingsUpdateDTO: UserSettingsUpdateDTO,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .patchUserSettings(encodedUserName, userSettingsUpdateDTO, options)
        .then((request) => request(axios, basePath))
    }
  }
}

/**
 * UserSettingsApi - object-oriented interface
 * @export
 * @class UserSettingsApi
 * @extends {BaseAPI}
 */
export class UserSettingsApi extends BaseAPI {
  /**
   * Retrieves a list of Hospital and Ward objects assigned to the User
   * @param {string} encodedUserName Base64 encoded username of the user to retrieve their assigned hospitals
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public getAssignedHospitals(encodedUserName: string, options?: RawAxiosRequestConfig) {
    return UserSettingsApiFp(this.configuration)
      .getAssignedHospitals(encodedUserName, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a User Settings object
   * @param {string} encodedUserName Base64 encoded username of the user to retrieve their settings
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public getUserSettings(encodedUserName: string, options?: RawAxiosRequestConfig) {
    return UserSettingsApiFp(this.configuration)
      .getUserSettings(encodedUserName, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Updates a User Settings object
   * @param {string} encodedUserName Base64 encoded username of the user to partially update their settings
   * @param {UserSettingsUpdateDTO} userSettingsUpdateDTO
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserSettingsApi
   */
  public patchUserSettings(
    encodedUserName: string,
    userSettingsUpdateDTO: UserSettingsUpdateDTO,
    options?: RawAxiosRequestConfig
  ) {
    return UserSettingsApiFp(this.configuration)
      .patchUserSettings(encodedUserName, userSettingsUpdateDTO, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * VitalSignsApi - axios parameter creator
 * @export
 */
export const VitalSignsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieves a list of historical Vital Signs for given period of time
     * @param {string} caseId
     * @param {VitalSignTypeKey} type
     * @param {VitalSignsHistoricalPeriod} period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoricalVitalSigns: async (
      caseId: string,
      type: VitalSignTypeKey,
      period: VitalSignsHistoricalPeriod,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'caseId' is not null or undefined
      assertParamExists('getHistoricalVitalSigns', 'caseId', caseId)
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getHistoricalVitalSigns', 'type', type)
      // verify required parameter 'period' is not null or undefined
      assertParamExists('getHistoricalVitalSigns', 'period', period)
      const localVarPath = `/vital-signs/{caseId}/historical`.replace(
        `{${'caseId'}}`,
        encodeURIComponent(String(caseId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (type !== undefined) {
        localVarQueryParameter['type'] = type
      }

      if (period !== undefined) {
        localVarQueryParameter['period'] = period
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a list of Vital Signs for given Case
     * @param {string} caseId
     * @param {string} [startTime] Oldest date time of the vital signs measurement that should be returned
     * @param {string} [endTime] The latest date time of the vital signs measurements that should be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVitalSigns: async (
      caseId: string,
      startTime?: string,
      endTime?: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'caseId' is not null or undefined
      assertParamExists('getVitalSigns', 'caseId', caseId)
      const localVarPath = `/vital-signs/{caseId}`.replace(
        `{${'caseId'}}`,
        encodeURIComponent(String(caseId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      if (startTime !== undefined) {
        localVarQueryParameter['startTime'] =
          (startTime as any) instanceof Date ? (startTime as any).toISOString() : startTime
      }

      if (endTime !== undefined) {
        localVarQueryParameter['endTime'] =
          (endTime as any) instanceof Date ? (endTime as any).toISOString() : endTime
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    },
    /**
     * Retrieves a stream of Server-Sent Events (SSE) with Waveform values of the Vital Signs
     * @param {string} caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWaveforms: async (
      caseId: string,
      options: RawAxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'caseId' is not null or undefined
      assertParamExists('getWaveforms', 'caseId', caseId)
      const localVarPath = `/waveforms/{caseId}`.replace(
        `{${'caseId'}}`,
        encodeURIComponent(String(caseId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      }
    }
  }
}

/**
 * VitalSignsApi - functional programming interface
 * @export
 */
export const VitalSignsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VitalSignsApiAxiosParamCreator(configuration)
  return {
    /**
     * Retrieves a list of historical Vital Signs for given period of time
     * @param {string} caseId
     * @param {VitalSignTypeKey} type
     * @param {VitalSignsHistoricalPeriod} period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHistoricalVitalSigns(
      caseId: string,
      type: VitalSignTypeKey,
      period: VitalSignsHistoricalPeriod,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VitalSignsWithTypeDTO>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHistoricalVitalSigns(
        caseId,
        type,
        period,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['VitalSignsApi.getHistoricalVitalSigns']?.[localVarOperationServerIndex]
          ?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a list of Vital Signs for given Case
     * @param {string} caseId
     * @param {string} [startTime] Oldest date time of the vital signs measurement that should be returned
     * @param {string} [endTime] The latest date time of the vital signs measurements that should be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVitalSigns(
      caseId: string,
      startTime?: string,
      endTime?: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetVitalSignsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVitalSigns(
        caseId,
        startTime,
        endTime,
        options
      )
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['VitalSignsApi.getVitalSigns']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    },
    /**
     * Retrieves a stream of Server-Sent Events (SSE) with Waveform values of the Vital Signs
     * @param {string} caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWaveforms(
      caseId: string,
      options?: RawAxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SseEmitter>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWaveforms(caseId, options)
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0
      const localVarOperationServerBasePath =
        operationServerMap['VitalSignsApi.getWaveforms']?.[localVarOperationServerIndex]?.url
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, localVarOperationServerBasePath || basePath)
    }
  }
}

/**
 * VitalSignsApi - factory interface
 * @export
 */
export const VitalSignsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VitalSignsApiFp(configuration)
  return {
    /**
     * Retrieves a list of historical Vital Signs for given period of time
     * @param {string} caseId
     * @param {VitalSignTypeKey} type
     * @param {VitalSignsHistoricalPeriod} period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHistoricalVitalSigns(
      caseId: string,
      type: VitalSignTypeKey,
      period: VitalSignsHistoricalPeriod,
      options?: any
    ): AxiosPromise<VitalSignsWithTypeDTO> {
      return localVarFp
        .getHistoricalVitalSigns(caseId, type, period, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a list of Vital Signs for given Case
     * @param {string} caseId
     * @param {string} [startTime] Oldest date time of the vital signs measurement that should be returned
     * @param {string} [endTime] The latest date time of the vital signs measurements that should be returned
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVitalSigns(
      caseId: string,
      startTime?: string,
      endTime?: string,
      options?: any
    ): AxiosPromise<GetVitalSignsResponse> {
      return localVarFp
        .getVitalSigns(caseId, startTime, endTime, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Retrieves a stream of Server-Sent Events (SSE) with Waveform values of the Vital Signs
     * @param {string} caseId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWaveforms(caseId: string, options?: any): AxiosPromise<SseEmitter> {
      return localVarFp.getWaveforms(caseId, options).then((request) => request(axios, basePath))
    }
  }
}

/**
 * VitalSignsApi - object-oriented interface
 * @export
 * @class VitalSignsApi
 * @extends {BaseAPI}
 */
export class VitalSignsApi extends BaseAPI {
  /**
   * Retrieves a list of historical Vital Signs for given period of time
   * @param {string} caseId
   * @param {VitalSignTypeKey} type
   * @param {VitalSignsHistoricalPeriod} period
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VitalSignsApi
   */
  public getHistoricalVitalSigns(
    caseId: string,
    type: VitalSignTypeKey,
    period: VitalSignsHistoricalPeriod,
    options?: RawAxiosRequestConfig
  ) {
    return VitalSignsApiFp(this.configuration)
      .getHistoricalVitalSigns(caseId, type, period, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a list of Vital Signs for given Case
   * @param {string} caseId
   * @param {string} [startTime] Oldest date time of the vital signs measurement that should be returned
   * @param {string} [endTime] The latest date time of the vital signs measurements that should be returned
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VitalSignsApi
   */
  public getVitalSigns(
    caseId: string,
    startTime?: string,
    endTime?: string,
    options?: RawAxiosRequestConfig
  ) {
    return VitalSignsApiFp(this.configuration)
      .getVitalSigns(caseId, startTime, endTime, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Retrieves a stream of Server-Sent Events (SSE) with Waveform values of the Vital Signs
   * @param {string} caseId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VitalSignsApi
   */
  public getWaveforms(caseId: string, options?: RawAxiosRequestConfig) {
    return VitalSignsApiFp(this.configuration)
      .getWaveforms(caseId, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
