<template>
  <ConsultationPanel />
</template>

<script setup lang="ts">
import ConsultationPanel from '@/components/consultationrequest/ConsultationPanel.vue'

import { useCaseStore } from '@/stores/case'
import { onMounted } from 'vue'

const caseStore = useCaseStore()

onMounted(caseStore.refreshCases)
</script>
