<template>
  <span data-testid="patient-title">{{ displayTitle }}</span>
</template>

<script setup lang="ts">
import { type PatientDTO } from '@/generated'
import { computed } from 'vue'
import { translateGenderDTO } from '@/util/translations'
import { useI18n } from 'vue-i18n'
import { differenceInYears, parseISO } from 'date-fns'
import { UTCDate } from '@date-fns/utc'

const { t } = useI18n()
const props = defineProps<{
  patient: PatientDTO
}>()

const displayTitle = computed<string>(() => {
  if (props.patient.dateOfBirth) {
    const age = Math.trunc(
      differenceInYears(new Date(), new UTCDate(parseISO(props.patient.dateOfBirth)))
    )

    return `${props.patient.lastName}, ${props.patient.firstName} (${translateGenderDTO(props.patient.gender, t)}, ${age})`
  }

  return `${props.patient.lastName}, ${props.patient.firstName} (${translateGenderDTO(props.patient.gender, t)})`
})
</script>
