<template>
  <VCard class="overflow-x-auto">
    <div class="flex flex-col gap-4 bg-white rounded-md px-4 py-2 w-full min-w-[750px]">
      <div class="flex flex-row justify-between">
        <div
          class="flex flex-row flex-wrap gap-2 text-primary-text justify-self-start py-2 text-h5 text-truncate"
        >
          <PatientTitle :patient="caze.patient" />
          <PatientDetails :caze="caze" class="text-body-2" previewMode />
        </div>

        <div class="flex flex-row items-center gap-2">
          <VBtn :active="false" class="opacity-100" color="primary" variant="text" @click.prevent>
            <template #prepend>
              <VIcon icon="fullscreen" class="material-icons-round" />
            </template>
            {{ t('Vital data') }}

            <VitalSignsPreviewModal
              :show="showVitalSignsPreviewModal.show"
              :caze="showVitalSignsPreviewModal.caze"
              :vital-signs="showVitalSignsPreviewModal.vitalSigns"
              :laboratory="showVitalSignsPreviewModal.laboratory"
              :organ-support="showVitalSignsPreviewModal.organSupport"
              @update:show="showVitalSignsPreviewModal.show = $event"
            />
          </VBtn>

          <VBtn
            :active="false"
            class="opacity-100"
            color="primary"
            variant="text"
            :disabled="hasGodModeShift"
            @click="onCreateConsultation"
          >
            {{ t('Create consultation') }}
          </VBtn>

          <VBtn
            :active="false"
            class="opacity-100"
            color="secondary"
            variant="text"
            :disabled="disableEmergencyButton || hasGodModeShift"
            @click="onEmergency"
          >
            {{ t('Emergency') }}
          </VBtn>
        </div>
      </div>

      <div class="flex flex-col gap-2">
        <div class="gap-2 justify-between grid grid-cols-5 xl:grid-cols-10">
          <!-- Vital Signs -->
          <div
            v-for="(vital, index) in vitalSignsCards"
            :key="index"
            class="bg-[#F3F4F7] flex flex-col items-center justify-center rounded-md p-2 w-full xl:w-[100px]"
          >
            <span class="text-caption text-[#206683]">{{ t(vital.label) }}</span>
            <span class="text-subtitle-2 font-bold text-[#17485D]">
              {{ vital.value }}
            </span>
          </div>

          <!-- Organ Support Indicators -->
          <div
            v-for="support in organSupportList"
            :key="support.key"
            class="bg-[#F5F5F5] flex flex-col items-center justify-center rounded-md p-2 w-full xl:w-[100px]"
          >
            <span class="text-caption text-[#206683]">{{ t(support.label) }}</span>
            <span class="text-subtitle-2 font-bold text-[#17485D]">
              <VIcon
                size="16"
                :icon="support.value ? 'check' : 'close'"
                :class="support.value ? 'text-success' : 'text-error'"
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </VCard>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { CaseDTO, VitalSignsWithTypeDTO } from '@/generated'
import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
import { findLatestValueByKey } from './VitalInfoTable.utils'
import PatientTitle from '@/components/patient/components/atoms/PatientTitle.vue'
import { ConsultationRequestService } from '@/services/telehealthApi/ConsultationRequestService'
import { useCallStore } from '@/stores/call'
import { NoticeType } from '@/stores/models/Notice'
import { useNoticeStore } from '@/stores/notice'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router/routes'
import { useI18n } from 'vue-i18n'
import PatientDetails from '@/components/patient/components/atoms/PatientDetails.vue'
import VitalSignsPreviewModal from './VitalSignsPreviewModal.vue'

const props = defineProps<{
  caze: CaseDTO
  vitalSigns: VitalSignsWithTypeDTO[]
  laboratory: VitalSignsWithTypeDTO[]
}>()

const organSupportList: OrganSupport[] = [
  { key: 'vasopressors', label: 'Vasopressors', value: true },
  { key: 'ventilation', label: 'Ventilation', value: true },
  { key: 'dialysis', label: 'Dialysis', value: true },
  { key: 'ecmo', label: 'ECMO', value: false },
  { key: 'impella', label: 'Impella', value: false }
] as const

export interface OrganSupport {
  key: string
  label: string
  value: boolean
}

const { t } = useI18n()
const { hasGodModeShift } = storeToRefs(useUserStore())
const noticeStore = useNoticeStore()
const callStore = useCallStore()
const router = useRouter()

const createEmergencyInProgress = ref<boolean>(false)
const showVitalSignsPreviewModal = ref<{
  show: boolean
  caze: CaseDTO
  vitalSigns: VitalSignsWithTypeDTO[]
  laboratory: VitalSignsWithTypeDTO[]
  organSupport: OrganSupport[]
}>({
  show: false,
  caze: props.caze,
  vitalSigns: props.vitalSigns,
  laboratory: props.laboratory,
  organSupport: organSupportList
})
const disableEmergencyButton = computed<boolean>(() => !!props.caze.emergencyUnavailable)

const NON_INV_BP = computed(() => {
  const sbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_S_BP).value
  const dbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_D_BP).value
  const mbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_M_BP).value
  return `${sbp} | ${dbp} | ${mbp}`
})

const HEART_RATE = computed(
  () => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.HEART_RATE).value
)
const SPO2 = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.SPO2).value)
const RR = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.RR).value)
const TEMP = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.TEMP).value)

const vitalSignsCards = computed(() => [
  { label: 'RR  inv.', value: NON_INV_BP.value },
  { label: 'HR (1/min)', value: HEART_RATE.value },
  { label: 'SpO2 %', value: SPO2.value },
  { label: 'RR (1/min)', value: RR.value },
  { label: 'Temp °C', value: TEMP.value }
])

async function onEmergency() {
  try {
    createEmergencyInProgress.value = true
    const createdRequestId = await ConsultationRequestService.createEmergencyRequest(props.caze.id)
    await callStore.joinCall(createdRequestId)
  } catch (error) {
    console.error((error as Error).message)
    noticeStore.add({
      type: NoticeType.ERROR,
      message: 'Failed to submit consultation request'
    })
  } finally {
    createEmergencyInProgress.value = false
  }
}

async function onCreateConsultation() {
  try {
    await router.push({
      name: routeNames.CREATE_CONSULTATION,
      query: { caseId: props.caze.id }
    })
  } catch (error) {
    console.error((error as Error).message)
    noticeStore.add({
      type: NoticeType.ERROR,
      message: 'Failed to navigate to consultation request'
    })
  }
}
</script>
