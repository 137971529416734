<template>
  <VBtn
    color="primary"
    class="button-medium"
    variant="outlined"
    size="medium"
    prepend-icon="filter_list"
    data-testid="btn-activator"
    @click.prevent
  >
    {{ t('Filters') }}
    <VitalSignsPatientFilterDialog />
  </VBtn>
</template>

<script setup lang="ts">
import VitalSignsPatientFilterDialog from '@/components/patient/filter/VitalSignsPatientFilterDialog.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
</script>
