<template>
  <VContainer class="flex flex-col gap-4">
    <EmptyConsultations v-if="sortedConsultationRequests.length === 0" />

    <ConsultationCard
      v-else
      v-for="item in sortedConsultationRequests"
      :key="item.id"
      :consultation="item"
      :show-action-buttons="showActionButtons"
    />
  </VContainer>
</template>

<script setup lang="ts">
import ConsultationCard from '@/components/consultationrequest/ConsultationCard.vue'
import type { ConsultationRequestDTO } from '@/generated'
import { compareConsultationRequests } from '@/util/compareConsultationRequests'
import { computed } from 'vue'
import EmptyConsultations from './EmptyConsultations.vue'

const props = withDefaults(
  defineProps<{
    consultationRequests: ConsultationRequestDTO[]
    showActionButtons?: boolean
  }>(),
  {
    showActionButtons: true
  }
)

const sortedConsultationRequests = computed<ConsultationRequestDTO[]>(() =>
  [...props.consultationRequests].sort(compareConsultationRequests)
)
</script>
