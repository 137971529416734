<template>
  <div
    class="text-primary-text p-3 text-body-1 text-truncate cursor-pointer"
    @click="showVitalSignsPreviewModal.show = true"
  >
    <PatientTitle :patient="caze.patient" />

    <VitalSignsPreviewModal
      :show="showVitalSignsPreviewModal.show"
      :caze="showVitalSignsPreviewModal.caze"
      :vital-signs="showVitalSignsPreviewModal.vitalSigns"
      :laboratory="showVitalSignsPreviewModal.laboratory"
      :organ-support="showVitalSignsPreviewModal.organSupport"
      @update:show="showVitalSignsPreviewModal.show = $event"
    />
  </div>
  <div class="text-primary-text justify-self-center p-3 text-body-1">{{ NON_INV_BP }}</div>
  <div class="text-primary-text justify-self-center p-3 text-body-1">{{ HEART_RATE }}</div>
  <div class="text-primary-text justify-self-center p-3 text-body-1">{{ SPO2 }}</div>
  <div class="text-primary-text justify-self-center p-3 text-body-1">{{ RR }}</div>
  <div class="text-primary-text justify-self-center p-3 text-body-1">{{ TEMP }}</div>
  <div class="justify-self-center p-3">
    <VIcon
      size="16"
      :icon="organSupport.vasopressors ? 'check' : 'close'"
      :class="organSupport.vasopressors ? 'text-success' : 'text-error'"
    />
  </div>
  <div class="justify-self-center p-3">
    <VIcon
      size="16"
      :icon="organSupport.ventilation ? 'check' : 'close'"
      :class="organSupport.ventilation ? 'text-success' : 'text-error'"
    />
  </div>
  <div class="justify-self-center p-3">
    <VIcon
      size="16"
      :icon="organSupport.dialysis ? 'check' : 'close'"
      :class="organSupport.dialysis ? 'text-success' : 'text-error'"
    />
  </div>
  <div class="justify-self-center p-3">
    <VIcon
      size="16"
      :icon="organSupport.ecmo ? 'check' : 'close'"
      :class="organSupport.ecmo ? 'text-success' : 'text-error'"
    />
  </div>
  <div class="justify-self-center p-3">
    <VIcon
      size="16"
      :icon="organSupport.impella ? 'check' : 'close'"
      :class="organSupport.impella ? 'text-success' : 'text-error'"
    />
  </div>
  <div class="flex items-center justify-center gap-3 ml-[15px]">
    <VBtn
      :active="false"
      class="opacity-100 !min-w-[28px] !min-h-[28px] !w-[28px] !h-[28px]"
      color="primary"
      variant="plain"
      @click.prevent
    >
      <VIcon icon="fullscreen" class="material-icons-round" />

      <VitalSignsPreviewModal
        :show="showVitalSignsPreviewModal.show"
        :caze="showVitalSignsPreviewModal.caze"
        :vital-signs="showVitalSignsPreviewModal.vitalSigns"
        :laboratory="showVitalSignsPreviewModal.laboratory"
        :organ-support="showVitalSignsPreviewModal.organSupport"
        @update:show="showVitalSignsPreviewModal.show = $event"
      />
    </VBtn>

    <VBtn
      :active="false"
      class="!min-w-[28px] !min-h-[28px] !w-[28px] !h-[28px] !p-0 [&::after]:hidden !opacity-100"
      color="primary"
      variant="plain"
      :disabled="hasGodModeShift"
      @click="onCreateConsultation"
    >
      <img
        src="/calendar-add.svg"
        alt="Create consultation"
        class="place-self-center w-[20px] h-[20px]"
      />
    </VBtn>

    <VBtn
      :active="false"
      class="!min-w-[28px] !min-h-[28px] !w-[28px] !h-[28px] !p-0 [&::after]:hidden !opacity-100"
      color="secondary"
      variant="plain"
      :disabled="disableEmergencyButton || hasGodModeShift"
      @click="onEmergency"
    >
      <VIcon color="secondary" size="20" icon="emergency" class="material-icons-round" />
    </VBtn>
  </div>

  <DividerLine class="col-span-full" />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { CaseDTO, VitalSignsWithTypeDTO } from '@/generated'

import { VitalSignsKeys } from '@/components/patient/models/VitalSignsKeys'
import { findLatestValueByKey } from './VitalInfoTable.utils'
import PatientTitle from '@/components/patient/components/atoms/PatientTitle.vue'
import { ConsultationRequestService } from '@/services/telehealthApi/ConsultationRequestService'
import { useCallStore } from '@/stores/call'
import { NoticeType } from '@/stores/models/Notice'
import { useNoticeStore } from '@/stores/notice'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'
import { routeNames } from '@/router/routes'
import DividerLine from '@/atoms/DividerLine.vue'
import type { OrganSupport } from './ExpandedPatientCard.vue'
import VitalSignsPreviewModal from './VitalSignsPreviewModal.vue'
const props = defineProps<{
  caze: CaseDTO
  vitalSigns: VitalSignsWithTypeDTO[]
  laboratory: VitalSignsWithTypeDTO[]
}>()

const organSupportList: OrganSupport[] = [
  { key: 'vasopressors', label: 'Vasopressors', value: true },
  { key: 'ventilation', label: 'Ventilation', value: true },
  { key: 'dialysis', label: 'Dialysis', value: true },
  { key: 'ecmo', label: 'ECMO', value: false },
  { key: 'impella', label: 'Impella', value: false }
] as const

const { hasGodModeShift } = storeToRefs(useUserStore())
const noticeStore = useNoticeStore()
const callStore = useCallStore()
const router = useRouter()

const showVitalSignsPreviewModal = ref<{
  show: boolean
  caze: CaseDTO
  vitalSigns: VitalSignsWithTypeDTO[]
  laboratory: VitalSignsWithTypeDTO[]
  organSupport: OrganSupport[]
}>({
  show: false,
  caze: props.caze,
  vitalSigns: props.vitalSigns,
  laboratory: props.laboratory,
  organSupport: organSupportList
})

const createEmergencyInProgress = ref<boolean>(false)
const disableEmergencyButton = computed<boolean>(() => !!props.caze.emergencyUnavailable)

const NON_INV_BP = computed(() => {
  const sbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_S_BP).value
  const dbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_D_BP).value
  const mbp = findLatestValueByKey(props.vitalSigns, VitalSignsKeys.NON_INV_M_BP).value
  return `${sbp} | ${dbp} | ${mbp}`
})

const HEART_RATE = computed(
  () => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.HEART_RATE).value
)
const SPO2 = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.SPO2).value)
const RR = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.RR).value)
const TEMP = computed(() => findLatestValueByKey(props.vitalSigns, VitalSignsKeys.TEMP).value)

// TODO: Replace with actual organ support data from backend
const organSupport = {
  vasopressors: true,
  ventilation: true,
  dialysis: false,
  ecmo: false,
  impella: true
}

async function onEmergency() {
  try {
    createEmergencyInProgress.value = true
    const createdRequestId = await ConsultationRequestService.createEmergencyRequest(props.caze.id)
    await callStore.joinCall(createdRequestId)
  } catch (error) {
    console.error((error as Error).message)
    noticeStore.add({
      type: NoticeType.ERROR,
      message: 'Failed to submit consultation request'
    })
  } finally {
    createEmergencyInProgress.value = false
  }
}

async function onCreateConsultation() {
  try {
    await router.push({
      name: routeNames.CREATE_CONSULTATION,
      query: { caseId: props.caze.id }
    })
  } catch (error) {
    console.error((error as Error).message)
    noticeStore.add({
      type: NoticeType.ERROR,
      message: 'Failed to navigate to consultation request'
    })
  }
}
</script>
