import { format, isToday, isSameMinute } from 'date-fns'

import type { VitalSignItemDTO, VitalSignsWithTypeDTO } from '@/generated/api'

import type { VitalSignsKeys } from '../../models/VitalSignsKeys'

export function findLatestValueByKey(vitalSigns: VitalSignsWithTypeDTO[], key: VitalSignsKeys) {
  const temp = vitalSigns.find((el) => el.vitalSignType.typeKey === key)

  const latestItem = getLatestVitalSignItem(temp?.items || [])

  return {
    value: latestItem?.numericValue ?? '---',
    measurementDate: latestItem?.measurementDate ?? '---'
  }
}

function getLatestVitalSignItem(vitalSigns: VitalSignItemDTO[]): VitalSignItemDTO | undefined {
  if (vitalSigns.length === 0) {
    return undefined
  }

  return vitalSigns.reduce((latest, current) => {
    return new Date(current.measurementDate) > new Date(latest.measurementDate) ? current : latest
  })
}

export function findLatestTimeValueByKey(
  vitalSigns: VitalSignsWithTypeDTO[],
  key: VitalSignsKeys
): string {
  const temp = vitalSigns.find((el) => el.vitalSignType.typeKey === key)

  const value = getLatestVitalSignItem(temp?.items || [])?.measurementDate
  if (value === undefined) {
    return '---'
  }

  return formatTimestamp(value)
}

export function formatTimestamp(timestamp: string) {
  const date = new Date(timestamp)
  const now = new Date()

  if (isSameMinute(date, now)) {
    return 'Now'
  }

  if (isToday(date)) {
    return format(date, 'HH:mm')
  }

  return format(date, 'dd.MM.yyyy, HH:mm')
}
