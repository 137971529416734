import { defineStore } from 'pinia'
import type { UserProfile } from '@/stores/models/UserProfile'
import { computed, type Ref, ref } from 'vue'
import type { languages } from '@/i18n/i18n'
import { type HospitalDTO, ShiftTypeDTO, UserRightsEnum, type WardDTO } from '@/generated'
import type { FirebaseOptions } from '@/global/FirebaseOptions'
import { useLocalStorage } from '@vueuse/core'

export const useUserStore = defineStore('user', () => {
  const user: Ref<UserProfile | undefined> = ref(undefined)
  const currentRoleInStorage = useLocalStorage<UserRightsEnum | undefined>('currentRole', undefined)

  const getTitle = computed(() => user.value!.title ?? '')
  const getFullName = computed(() =>
    [user.value!.firstName, user.value!.lastName].filter((elm) => elm !== undefined).join(' ')
  )
  const getDisplayName = computed(() => {
    const displayName = [user.value!.title, user.value!.firstName, user.value!.lastName]
      .filter((elm) => elm !== undefined)
      .join(' ')

    return displayName.length !== 0 ? displayName : user.value?.userName
  })
  const hasSelectedShift = computed<boolean>(() => !!user.value!.currentShift)
  const hasSelectedRole = computed<boolean>(() => !!user.value!.currentRole)
  const hadSelectedShift = ref(false)

  const isIncognito = computed<boolean>(() => user.value?.currentShift === ShiftTypeDTO.NO_SHIFT)

  const hasGodModeShift = computed<boolean>(
    () => user.value?.currentShift === ShiftTypeDTO.GOD_MODE
  )

  const hasChiefPhysicianShift = computed<boolean>(
    () => user.value?.currentShift === ShiftTypeDTO.CHIEF_PHYSICIAN
  )

  const hasDoctorRole = computed<boolean>(() => {
    const doctorRoles: UserRightsEnum[] = [
      UserRightsEnum.HOSPITAL_PHYSICIAN,
      UserRightsEnum.TCC_PHYSICIAN,
      UserRightsEnum.GOD_MODE,
      UserRightsEnum.CHIEF_PHYSICIAN
    ]
    return user.value?.userRights.some((right) => doctorRoles.includes(right)) ?? false
  })

  const isTheSameAsUser = (userName: string): boolean => {
    return user.value?.userName === userName
  }

  const notificationToken: Ref<string | undefined> = ref(undefined)
  const firebaseOptions: Ref<FirebaseOptions | undefined> = ref(undefined)

  const notificationTokenLoadingState: Ref<'initial' | 'loading' | 'success' | 'error'> =
    ref('initial')

  function setUser(newUser: UserProfile | undefined) {
    user.value = newUser
    if (newUser && currentRoleInStorage.value) {
      newUser.currentRole = currentRoleInStorage.value
    }
  }

  function setLanguage(language: languages) {
    user.value!.language = language
  }

  function setCurrentShift(newShift: ShiftTypeDTO | undefined) {
    user.value!.currentShift = newShift
  }

  function setWards(newWards: WardDTO[] | undefined) {
    user.value!.wards = newWards
  }

  function setHospitals(newHospitals: HospitalDTO[] | undefined) {
    user.value!.hospitals = newHospitals
  }

  function setMedicalFields(newMedicalFields: string[] | undefined) {
    user.value!.medicalFields = newMedicalFields
  }

  function setNotificationToken(token: string): void {
    notificationToken.value = token
  }

  function clearNotificationToken(): void {
    notificationToken.value = undefined
  }

  function setFirebaseOptions(options: FirebaseOptions): void {
    firebaseOptions.value = options
  }

  function setNotificationTokenLoadingState(
    state: 'initial' | 'loading' | 'success' | 'error'
  ): void {
    notificationTokenLoadingState.value = state
  }

  function setUserRights(userRights: UserRightsEnum[]): void {
    user.value!.userRights = userRights
  }

  function setCurrentRole(currentRole?: UserRightsEnum): void {
    user.value!.currentRole = currentRole
    currentRoleInStorage.value = currentRole
  }

  function setHadSelectedShift(newValue: boolean): void {
    hadSelectedShift.value = newValue
  }

  return {
    user,
    getTitle,
    getFullName,
    getDisplayName,
    hasSelectedShift,
    hasSelectedRole,
    isIncognito,
    isTheSameAsUser,
    hasGodModeShift,
    hasChiefPhysicianShift,
    notificationToken,
    firebaseOptions,
    setUser,
    setLanguage,
    setCurrentShift,
    setHospitals,
    setWards,
    setMedicalFields,
    setNotificationToken,
    clearNotificationToken,
    setFirebaseOptions,
    notificationTokenLoadingState,
    setNotificationTokenLoadingState,
    setUserRights,
    setCurrentRole,
    hasDoctorRole,
    hadSelectedShift,
    setHadSelectedShift
  }
})
